const middleware = {}

middleware['auth'] = require('../middleware/auth.js');
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['can'] = require('../middleware/can.js');
middleware['can'] = middleware['can'].default || middleware['can']

middleware['guest'] = require('../middleware/guest.js');
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['maintenance'] = require('../middleware/maintenance.js');
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['theme'] = require('../middleware/theme.js');
middleware['theme'] = middleware['theme'].default || middleware['theme']

export default middleware
