export default {
  name: 'AppLink',
  functional: true,
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  },
  render(h, { props, data, children, parent }) {
    function make() {
      if (!props.to) {
        return h('span');
      }

      if (typeof props.to === 'object' && props.to.path) {
        return [
          'nuxt-link',
          {
            ...data,
            props
          }
        ];
      }

      const isHash = props.to.indexOf('#') === 0;
      if (props.to.match(/^(http(s)?|ftp):\/\//) || isHash) {
        return [
          'a',
          {
            ...data,
            attrs: {
              href: props.to,
              target: '_blank',
              rel: 'noopener'
            },
            on: isHash ? { click: e => e.preventDefault() } : {}
          }
        ];
      }

      const locale = parent.$i18n.locale;
      const linkProps = { ...props };
      if (locale !== 'en') {
        linkProps.to = `/${locale}${props.to}`;
      }

      return [
        'nuxt-link',
        {
          ...data,
          props: linkProps
        }
      ];
    }

    return h(...make(), children);
  }
};
