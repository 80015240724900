export function pick(obj, keys) {
  return keys.reduce((prev, key) => {
    if (obj[key] !== undefined) {
      prev[key] = obj[key];
    }

    return prev;
  }, {});
}

export function ucFirst(value) {
  if (!value) return '';

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  if (!selected) return;

  document.getSelection().removeAllRanges();
  document.getSelection().addRange(selected);
}
