<template lang="pug">
div
  main#main
    nuxt

</template>

<script>
export default {};
</script>
