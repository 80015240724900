<template lang="pug">
div

  main#main
    nuxt

</template>

<script>
export default {
  head() {
    return this.$nuxt.$nuxtI18nSeo();
  },
  beforeRouteLeave(_, __, next) {
    this.$store.commit('SET_ALTERNATE_LOCALE', { en: '', ar: '' });
    next();
  }
};
</script>

<style lang="stylus" src="~/assets/css/app.styl"></style>
