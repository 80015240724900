<template lang="pug">
.overlay-body
  Search
</template>

<script>
import Search from '../Search';

export default {
  name: 'TheSearchOverlay',
  components: {
    Search
  }
};
</script>
