<template lang="pug">
ValidationProvider(:vid="vid || undefined" :name="name || label" :rules="rules" ref="provider" v-slot="{ errors }" slim)
  .form
    label.form-label(v-if="label") {{ label }}
    textarea.input.is-textarea(v-if="type === 'textarea'" ref="input" v-on="listeners" :rows="rows" @input="onInput" :placeholder="placeholder" :disabled="disabled" :class="{ 'has-error': !!errors[0] }") {{ value }}
    input.input(v-else ref="input" :type="type" :value="value" v-on="listeners" @input="onInput" :placeholder="placeholder" :disabled="disabled" :class="{ 'has-error': !!errors[0] }")
    transition(name="validation")
      p(v-if="errors[0]").form-error {{ errors[0] }}
</template>

<script>
import { ValidationProvider } from 'vee-validate/dist/vee-validate.minimal';

export default {
  name: 'TextInput',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: null,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    rows: {
      type: Number,
      default: 6
    },
    placeholder: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    vid: {
      type: String,
      default: undefined
    }
  },
  computed: {
    listeners() {
      const on = { ...this.$listeners };
      // exclude input events as we will handle them manually.
      delete on.input;

      return on;
    }
  },
  watch: {
    value(val) {
      if (val !== this.$refs.input.value) {
        this.$refs.input.value = val;
        this.$refs.provider.validate(val);
      }
    }
  },
  mounted() {
    if (this.value) {
      this.$refs.provider.syncValue(this.value);
    }
    if (this.autofocus) {
      this.activate();
    }
  },
  methods: {
    activate() {
      this.$refs.input.focus();
    },
    onInput(e) {
      this.$refs.provider.validate(e);
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style lang="stylus">
.validation-enter-active, .validation-leave-active
  transition: opacity 0.5s

.validation-enter, .validation-leave-active
  opacity: 0
</style>
