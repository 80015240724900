<template lang="pug">
.baseSidebar
  ul.baseNav
    li: AppLink(to="/brand/system/introduction" :title="$t('introduction')") {{$t('introduction')}}
    li: AppLink(to="/brand/system/gettingStarted" :title="$t('gettingStarted')") {{$t('gettingStarted')}}
    li.is-parent #[a(href="#" , :title="$t('guidelines')"  @click.prevent="menuToggle") {{$t('guidelines')}} #[span.baseNav-icon] ]
      ul
        li: AppLink(to="/brand/system/guidelines/accessibility" :title="$t('accessibility')") {{$t('accessibility')}}
        li: AppLink(to="/brand/system/guidelines/colors" :title="$t('colors')") {{$t('colors')}}
        li: AppLink(to="/brand/system/guidelines/content" :title="$t('content')") {{$t('content')}}
        li: AppLink(to="/brand/system/guidelines/typography" :title="$t('typography')") {{$t('typography')}}
        li: AppLink(to="/brand/system/guidelines/grid" :title="$t('gridPage')") {{$t('gridPage')}}
        li: AppLink(to="/brand/system/guidelines/logo" :title="$t('logo')") {{$t('logo')}}

    li.is-parent #[a(href="#" , :title="$t('visuals')"  @click.prevent="menuToggle") {{$t('visuals')}} #[span.baseNav-icon] ]
      ul
        li: AppLink(to="/brand/system/visuals/illustrations" :title="$t('illustrations')") {{$t('illustrations')}}
        li: AppLink(to="/brand/system/visuals/graphics" :title="$t('graphics')") {{$t('graphics')}}
        li: AppLink(to="/brand/system/visuals/system-icons" :title="$t('systemIcons')") {{$t('systemIcons')}}
        li: AppLink(to="/brand/system/visuals/spot-icons" :title="$t('spotIcons')") {{$t('spotIcons')}}
    li.is-parent #[a(href="#" , :title="$t('components')"  @click.prevent="menuToggle") {{$t('components')}} #[span.baseNav-icon] ]
      ul
        li: AppLink(to="/brand/system/components/grid" :title="$t('grid')") {{$t('grid')}}
        li: AppLink(to="/brand/system/components/button" :title="$t('button')") {{$t('button')}}
        li: AppLink(to="/brand/system/components/buttons" :title="$t('buttons')") {{$t('buttons')}}
        li: AppLink(to="/brand/system/components/input" :title="$t('input')") {{$t('input')}}
        li: AppLink(to="/brand/system/components/inputGroup" :title="$t('inputGroup')") {{$t('inputGroup')}}
        li: AppLink(to="/brand/system/components/checkbox" :title="$t('checkbox')") {{$t('checkbox')}}
        li: AppLink(to="/brand/system/components/radio" :title="$t('radio')") {{$t('radio')}}
        li: AppLink(to="/brand/system/components/switcher" :title="$t('switcher')") {{$t('switcher')}}
        li: AppLink(to="/brand/system/components/select" :title="$t('select')") {{$t('select')}}
        li: AppLink(to="/brand/system/components/label" :title="$t('label')") {{$t('label')}}
        li: AppLink(to="/brand/system/components/table" :title="$t('table')") {{$t('table')}}
        li: AppLink(to="/brand/system/components/textarea" :title="$t('textarea')") {{$t('textarea')}}
        li: AppLink(to="/brand/system/components/tooltip" :title="$t('tooltip')") {{$t('tooltip')}}
        li: AppLink(to="/brand/system/components/pagination" :title="$t('pagination')") {{$t('pagination')}}



    //- li: AppLink(to="/brand/system/resources") Resources
    //- li: AppLink(to="/brand/system/new") What's New

</template>

<style lang="stylus">
.baseSidebar
  height: 100%

.baseLogo
  font-size: unitRes(24, 48)
  margin-top: unitRes(8, 16)

  > a
    display: block
    font-size: 0

  svg
    width: unitRes(120, 240)
    height: unitRes(30, 57)

.baseNav
  list-style-type: none
  padding: 0
  margin: 0

  a
    font-weight: $fontWeight-super
    display: flex
    justify-content: space-between
    align-items: center

    &:hover, &.is-active
      color: $clPrimary

  ul
    list-style-type: none

  >li
    overflow: hidden
    font-size: unitRes(16, 36)
    margin-bottom: unitRes(16, 32)

  li
    >ul
      max-height: 0px
      transition: 160ms
      z-index: -1
      position: relative
      font-size: unitRes(14, 24)

      >li
        transition: 160ms

        a
          font-weight: $fontWeight-normal
          justify-content: flex-start

          &:before
            content: ''
            width: unitRes(8, 16)
            height: unitRes(2, 4)
            background: $clBlack
            display: inline-flex
            margin-right: unitRes(8, 16)

    &.is-open
      >ul
        max-height: 850px
        opacity: 1
        z-index: 2
        overflow: hidden
        padding: unitRes(8, 16) 0 0 0

        >li
          margin-bottom: unitRes(8, 16)

      .baseNav-icon
        &:before
          transform: rotate(90deg)
          opacity: 0

.baseNav-icon
  display: flex
  justify-content: center
  align-items: center
  float: right
  width: unitRes(16, 32)
  height: unitRes(16, 32)
  position: relative
  z-index: -1
  margin-right: unitRes(0, 80)

  &:after
    content: ''
    width: unitRes(16, 32)
    height: unitRes(3, 6)
    background: $clBlack
    display: inline-block

  &:before
    content: ''
    height: unitRes(16, 32)
    width: unitRes(3, 6)
    background: $clBlack
    display: inline-block
    position: absolute
    left: calc(50%)
    top: 0px
    transition: 160ms
    transform: translateX(-50%)
</style>

<script>
import AppIcon from '~/components/AppIcon';

export default {
  name: 'TheSidebar',
  components: {
    AppIcon
  },
  methods: {
    menuToggle: function(event) {
      if (event) {
        event.target.parentElement.classList.toggle('is-open');
      }
    }
  }
};
</script>

<i18n>
{
  "en": {
    "introduction":"Introduction",
    "gettingStarted":"Getting Started",
    "guidelines":"Guidelines",
    "accessibility":"Accessibility",
    "colors":"Colors",
    "content":"Content",
    "gridPage":"Grid",
    "visuals":"Visuals",
    "graphics":"Graphics",
    "typography":"Typography",
    "systemIcons":"System icons",
    "spotIcons":"Spot icons",
    "components":"Components",
    "grid":"Grid",
    "button":"Button",
    "buttons":"Buttons",
    "input":"Input",
    "inputGroup":"Input group",
    "checkbox":"Checkbox",
    "radio":"Radio",
    "switcher":"Switcher",
    "select":"Select",
    "label":"Label",
    "table":"Table",
    "textarea":"Textarea",
    "tooltip":"Tooltip",
    "pagination":"Pagination",
    "illustrations":"Illustrations",
    "logo":"Logo"
  },
  "ar": {
    "introduction":"المقدمة",
    "gettingStarted":"ابدأ الآن",
    "guidelines":"الإرشادات",
    "accessibility":"إمكانية الوصول",
    "colors":"الألوان",
    "content":"المحتوى",
    "gridPage":"الشبكية",
    "visuals":"المرئيات",
    "graphics":"الجرافيك",
    "typography":"التايبوجرافي",
    "systemIcons":"نظام الأيقونات",
    "spotIcons":"أيقونات سبوت",
    "components":"المكونات",
    "grid":"grid",
    "button":"Button",
    "buttons":"Buttons",
    "input":"Input",
    "inputGroup":"Input group",
    "checkbox":"Checkbox",
    "radio":"Radio",
    "switcher":"Switcher",
    "select":"Select",
    "label":"Label",
    "table":"Table",
    "textarea":"Textarea",
    "tooltip":"Tooltip",
    "pagination":"Pagination",
    "illustrations":"الرسوم التوضيحية",
    "logo":"الشعار"
  }
}
</i18n>
