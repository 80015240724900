export default {
  name: 'UpdateMessage',
  functional: true,
  render: (h, { parent, listeners }) => {
    const link = h(
      'a',
      {
        class: 'button-link is-tiny',
        attrs: {
          href: '#',
          id: 'update-app-btn'
        },
        on: {
          click: e => {
            e.preventDefault();
            document.querySelector('#update-app-btn').classList.add('is-loading');
            listeners.accept();
          }
        }
      },
      parent.$t('reload')
    );

    return h(
      'div',
      {
        class: 'UpdateMessage'
      },
      [h('p', [parent.$t('updateAvailable'), link])]
    );
  }
};
