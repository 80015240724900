<template lang="pug">

.app(:class="themeClasses")
  TheHeader

  .app-body
    .grid.u-mh-md
      .column.is-ultrawide-3.is-widescreen-3.is-desktop-4
        TheSidebar
      .column.is-ultrawide-9.is-widescreen-9.is-desktop-8
        .baseMain
          nuxt

  TheFooter

  TransitionGroup.alerts-group.is-bottom.is-right(name="alerts" tag="div")
    Alert(v-for="alert in alerts" :key="alert.id" :id="alert.id" :message="alert.message" :type="alert.type")

  Alert(v-if="globalAlert" :id="globalAlert.id" :global="true" :message="globalAlert.message" :type="globalAlert.type")

</template>

<style lang="stylus">
@import '~@baianat/base.framework/src/stylus/elements/_index'
@import '~@baianat/base.framework/src/stylus/components/_index'
@import '~@baianat/base.framework/src/stylus/helper/_index'
@import '~assets/css/app.styl'

.button
  &.is-code
    position: absolute
    right: unitRes(8, 16)
    top: unitRes(8, 16)
    margin: 0

.baseMain
  .input
    height: auto
    padding: 0.5em 0.75em

.app.is-base-home
  .app-body
    >.grid
      >.column
        &:first-child
          display: none

        &:last-child
          flex: 0 0 100%
          min-width: 100%
</style>

<script>
import TheSidebar from '~/components/TheSidebar';
import TheHeader from '~/components/TheHeader';
import TheFooter from '~/components/TheFooter';
import { mapGetters, mapActions } from 'vuex';
import Alert from '~/components/Alert';

export default {
  head() {
    const seo = this.$nuxt.$nuxtI18nSeo();
    const siteName = { hid: 'og:site_name', property: 'og:site_name', content: this.$t('siteName') };
    if (seo.meta) {
      seo.meta.push(siteName);
    } else {
      seo.meta = [siteName];
    }

    return seo;
  },
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    Alert
  },
  computed: {
    ...mapGetters({
      alerts: 'alert/messages',
      theme: 'theme',
      globalAlert: 'alert/globalAlert'
    }),
    themeClasses() {
      return this.theme
        .toLowerCase()
        .split(' ')
        .map(theme => `is-${theme.replace('is-', '')}`);
    }
  },
  mounted() {
    this.checkCookiesConsent();
  },
  methods: {
    ...mapActions({
      alert: 'alert/create'
    }),
    checkCookiesConsent() {
      const consent = document.cookie.replace(/(?:(?:^|.*;\s*)cookieConsent\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (!consent) {
        this.alert({
          message: 'This website uses cookies to ensure you get the best experience on our website.',
          timeout: 0,
          global: true,
          type: 'default',
          onDismiss: () => {
            const d = new Date();
            d.setTime(d.getTime() + 90 * 24 * 60 * 60 * 1000);
            const expires = `expires=${d.toUTCString()}`;
            document.cookie = `cookieConsent=true; ${expires}; path=/;`;
          }
        });
      }
    }
  }
};
</script>
