import Vue from 'vue';
import { fetcher } from '~/utils/fetcher';

const makeClient = async ({ store, app }) => {
  const [{ onError }, { ApolloLink, execute, makePromise }, { RetryLink }, { CachedLink }] = await Promise.all([
    import('apollo-link-error'),
    import('apollo-link'),
    import('apollo-link-retry'),
    import('~/utils/cache-link')
  ]);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
      );
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });
  const authLink = new ApolloLink((operation, forward) => {
    let headers = {};
    if (store.getters['auth/token']) {
      headers['Authorization-Token'] = `bearer ${store.getters['auth/token']}`;
    }

    if (app.i18n.locale) {
      headers['Content-Language'] = app.i18n.locale;
    }

    operation.setContext({ headers });

    return forward(operation);
  });

  const uri = process.env.API_URL || store.state.env.API_URL;
  const link = ApolloLink.from([
    errorLink,
    authLink,
    new RetryLink(),
    new CachedLink({ uri, fetch: fetcher, storeName: 'api' })
  ]);

  return {
    async query({ query, variables = {} } = {}) {
      const res = await makePromise(execute(link, { query, variables }));
      // report errors only if the app is online.
      if (res.errors && process.browser && window.navigator.onLine) {
        throw new Error(res.errors[0].message);
      }

      return res;
    },
    mutate({ mutation, variables = {} } = {}) {
      return makePromise(execute(link, { query: mutation, variables })).then(response => {
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        return response;
      });
    }
  };
};

export default ({ app, store }) => {
  const apollo = makeClient({ app, store });
  const client = {
    query(...args) {
      return apollo.then(client => client.query(...args));
    },
    mutate(...args) {
      return apollo.then(client => client.mutate(...args));
    }
  };

  Vue.prototype.$api = client;
  app.$api = client;
  Vue.$api = client;
};
