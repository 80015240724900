<template lang="pug">
  .alert(:class="classes" @hover="cancelTimeout(id)" )
    .container(v-if="global")
      p {{ message }}
      a(href="#" v-if='dismiss' class="alert-dismiss" @click.prevent="remove(id)" ) #[AppIcon(name="close")]
    template(v-else)
      p {{ message }}
      a(href="#" v-if='dismiss' class="alert-dismiss" @click.prevent="remove(id)" ) #[AppIcon(name="close")]
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppAlert',
  props: {
    id: {
      type: null,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    global: {
      type: Boolean,
      default: false
    },
    dismiss: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'info',
      validator: value => {
        return ['info', 'error', 'success', 'warning', 'default'].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    classes() {
      return {
        'is-dismissible': this.dismiss,
        'is-global': this.global,
        'is-danger': this.type === 'error',
        'is-info': this.type === 'info',
        'is-warning': this.type === 'warning',
        'is-success': this.type === 'success'
      };
    }
  },
  methods: {
    ...mapActions({
      remove: 'alert/dismiss',
      cancelTimeout: 'alert/cancelTimeout'
    })
  }
};
</script>

<style lang="stylus">
.alert
  padding: 20px
  background: $clBlack
  color: $clWhite
  margin-bottom: 20px
  position: relative

  p
    font-size: $size-small

  &.is-info
    background: $clInfo

  &.is-success
    background: $clSuccess

  &.is-danger
    background: $clDanger

  &.is-warning
    background: $clWarning

  &.is-global
    margin-bottom: 0
    text-align: center
    padding: 10px
    bottom: 0
    font-size: 12px
    position: fixed
    right: 0
    left: 0
    z-index: 88
    padding-right: 30px
    border-radius: 0

  &.is-dismissible
    padding-right: 50px

.alert-dismiss
  position: absolute
  right: 20px
  top: 50%
  color: $clWhite
  font-size: 16px
  transform: translateY(-50%)

  svg
    fill: $clWhite
    width: 1em
    height: 1em
    display: block

  &:hover
    svg
      fill: $clBlack

.alerts-group
  display: flex
  flex-direction: column

  &.is-top
    top: 0

  &.is-right
    right: 0

  &.is-bottom
    bottom: 0

  &.is-left
    left: 0

  .alert
    max-width: 300px

    &:not(:last-child)
      margin-bottom: 10px
</style>
