import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ded381d = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _b4e7604a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _a4d373c4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5a53541a = () => interopDefault(import('../pages/account/activity.vue' /* webpackChunkName: "pages/account/activity" */))
const _4477bd8f = () => interopDefault(import('../pages/account/delete.vue' /* webpackChunkName: "pages/account/delete" */))
const _6ed5a16e = () => interopDefault(import('../pages/account/edit.vue' /* webpackChunkName: "pages/account/edit" */))
const _6c95c2f1 = () => interopDefault(import('../pages/account/security/email.vue' /* webpackChunkName: "pages/account/security/email" */))
const _9e45087a = () => interopDefault(import('../pages/account/security/phone.vue' /* webpackChunkName: "pages/account/security/phone" */))
const _105c51c5 = () => interopDefault(import('../pages/afkar.vue' /* webpackChunkName: "pages/afkar" */))
const _78dc0ac2 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _48703068 = () => interopDefault(import('../pages/backstage/index.vue' /* webpackChunkName: "pages/backstage/index" */))
const _4d0c1fe5 = () => interopDefault(import('../pages/banks.vue' /* webpackChunkName: "pages/banks" */))
const _2074a764 = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _3051da32 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _33bf1cc2 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _38c7184a = () => interopDefault(import('../pages/books/index.vue' /* webpackChunkName: "pages/books/index" */))
const _f0cb7c96 = () => interopDefault(import('../pages/brand.vue' /* webpackChunkName: "pages/brand" */))
const _488c2710 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _581da111 = () => interopDefault(import('../pages/brand/downloads.vue' /* webpackChunkName: "pages/brand/downloads" */))
const _d026260e = () => interopDefault(import('../pages/brand/system.vue' /* webpackChunkName: "pages/brand/system" */))
const _856fd688 = () => interopDefault(import('../pages/brand/system/index.vue' /* webpackChunkName: "pages/brand/system/index" */))
const _6a1a42a3 = () => interopDefault(import('../pages/brand/system/gettingStarted.vue' /* webpackChunkName: "pages/brand/system/gettingStarted" */))
const _a9a2c9c0 = () => interopDefault(import('../pages/brand/system/introduction.vue' /* webpackChunkName: "pages/brand/system/introduction" */))
const _02f29cb5 = () => interopDefault(import('../pages/brand/system/components/button.vue' /* webpackChunkName: "pages/brand/system/components/button" */))
const _5f0c9b0e = () => interopDefault(import('../pages/brand/system/components/buttons.vue' /* webpackChunkName: "pages/brand/system/components/buttons" */))
const _96dcdbf4 = () => interopDefault(import('../pages/brand/system/components/checkbox.vue' /* webpackChunkName: "pages/brand/system/components/checkbox" */))
const _a0a457f4 = () => interopDefault(import('../pages/brand/system/components/content.vue' /* webpackChunkName: "pages/brand/system/components/content" */))
const _7708d589 = () => interopDefault(import('../pages/brand/system/components/grid.vue' /* webpackChunkName: "pages/brand/system/components/grid" */))
const _f3c47fd2 = () => interopDefault(import('../pages/brand/system/components/input.vue' /* webpackChunkName: "pages/brand/system/components/input" */))
const _1153c778 = () => interopDefault(import('../pages/brand/system/components/InputGroup.vue' /* webpackChunkName: "pages/brand/system/components/InputGroup" */))
const _07b37b7e = () => interopDefault(import('../pages/brand/system/components/label.vue' /* webpackChunkName: "pages/brand/system/components/label" */))
const _3e1c1ada = () => interopDefault(import('../pages/brand/system/components/navbar.vue' /* webpackChunkName: "pages/brand/system/components/navbar" */))
const _0a8568c6 = () => interopDefault(import('../pages/brand/system/components/pagination.vue' /* webpackChunkName: "pages/brand/system/components/pagination" */))
const _32ea0870 = () => interopDefault(import('../pages/brand/system/components/radio.vue' /* webpackChunkName: "pages/brand/system/components/radio" */))
const _ea409502 = () => interopDefault(import('../pages/brand/system/components/select.vue' /* webpackChunkName: "pages/brand/system/components/select" */))
const _7fba7e24 = () => interopDefault(import('../pages/brand/system/components/switcher.vue' /* webpackChunkName: "pages/brand/system/components/switcher" */))
const _ac4dfbca = () => interopDefault(import('../pages/brand/system/components/table.vue' /* webpackChunkName: "pages/brand/system/components/table" */))
const _c01ccc06 = () => interopDefault(import('../pages/brand/system/components/textarea.vue' /* webpackChunkName: "pages/brand/system/components/textarea" */))
const _0e54cae0 = () => interopDefault(import('../pages/brand/system/components/tooltip.vue' /* webpackChunkName: "pages/brand/system/components/tooltip" */))
const _25cf2070 = () => interopDefault(import('../pages/brand/system/guidelines/accessibility.vue' /* webpackChunkName: "pages/brand/system/guidelines/accessibility" */))
const _1bb30134 = () => interopDefault(import('../pages/brand/system/guidelines/colors.vue' /* webpackChunkName: "pages/brand/system/guidelines/colors" */))
const _63ceb21a = () => interopDefault(import('../pages/brand/system/guidelines/content.vue' /* webpackChunkName: "pages/brand/system/guidelines/content" */))
const _2df7b1bc = () => interopDefault(import('../pages/brand/system/guidelines/grid.vue' /* webpackChunkName: "pages/brand/system/guidelines/grid" */))
const _d8479cbe = () => interopDefault(import('../pages/brand/system/guidelines/logo.vue' /* webpackChunkName: "pages/brand/system/guidelines/logo" */))
const _2e1760a5 = () => interopDefault(import('../pages/brand/system/guidelines/typography.vue' /* webpackChunkName: "pages/brand/system/guidelines/typography" */))
const _7d05816d = () => interopDefault(import('../pages/brand/system/visuals/graphics.vue' /* webpackChunkName: "pages/brand/system/visuals/graphics" */))
const _d7d24a9e = () => interopDefault(import('../pages/brand/system/visuals/illustrations.vue' /* webpackChunkName: "pages/brand/system/visuals/illustrations" */))
const _16ba00f1 = () => interopDefault(import('../pages/brand/system/visuals/spot-icons.vue' /* webpackChunkName: "pages/brand/system/visuals/spot-icons" */))
const _2467fb5e = () => interopDefault(import('../pages/brand/system/visuals/system-icons.vue' /* webpackChunkName: "pages/brand/system/visuals/system-icons" */))
const _4689b3fc = () => interopDefault(import('../pages/brand/artifacts/_slug.vue' /* webpackChunkName: "pages/brand/artifacts/_slug" */))
const _6f59960e = () => interopDefault(import('../pages/brands.vue' /* webpackChunkName: "pages/brands" */))
const _b0964bde = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _432ac919 = () => interopDefault(import('../pages/brands/afad.vue' /* webpackChunkName: "pages/brands/afad" */))
const _5fba024e = () => interopDefault(import('../pages/brands/alsfwa.vue' /* webpackChunkName: "pages/brands/alsfwa" */))
const _6a1bad87 = () => interopDefault(import('../pages/brands/azda.vue' /* webpackChunkName: "pages/brands/azda" */))
const _7db04c42 = () => interopDefault(import('../pages/brands/basb.vue' /* webpackChunkName: "pages/brands/basb" */))
const _ac9dcf82 = () => interopDefault(import('../pages/brands/blexar.vue' /* webpackChunkName: "pages/brands/blexar" */))
const _bbaf171e = () => interopDefault(import('../pages/brands/cojora.vue' /* webpackChunkName: "pages/brands/cojora" */))
const _7ac9ad19 = () => interopDefault(import('../pages/brands/creavs.vue' /* webpackChunkName: "pages/brands/creavs" */))
const _0a5a2dc8 = () => interopDefault(import('../pages/brands/fwasl.vue' /* webpackChunkName: "pages/brands/fwasl" */))
const _25b2ffd6 = () => interopDefault(import('../pages/brands/halx.vue' /* webpackChunkName: "pages/brands/halx" */))
const _1326f8e2 = () => interopDefault(import('../pages/brands/hostox.vue' /* webpackChunkName: "pages/brands/hostox" */))
const _2a68e830 = () => interopDefault(import('../pages/brands/hwanm.vue' /* webpackChunkName: "pages/brands/hwanm" */))
const _0e629b83 = () => interopDefault(import('../pages/brands/mojz.vue' /* webpackChunkName: "pages/brands/mojz" */))
const _c191be6c = () => interopDefault(import('../pages/brands/narx.vue' /* webpackChunkName: "pages/brands/narx" */))
const _bfb29f4a = () => interopDefault(import('../pages/brands/nasj.vue' /* webpackChunkName: "pages/brands/nasj" */))
const _4b40755c = () => interopDefault(import('../pages/brands/nllo.vue' /* webpackChunkName: "pages/brands/nllo" */))
const _2ec87ffa = () => interopDefault(import('../pages/brands/obka.vue' /* webpackChunkName: "pages/brands/obka" */))
const _6c9a915d = () => interopDefault(import('../pages/brands/oraj.vue' /* webpackChunkName: "pages/brands/oraj" */))
const _7237eec3 = () => interopDefault(import('../pages/brands/ords.vue' /* webpackChunkName: "pages/brands/ords" */))
const _6071ffd5 = () => interopDefault(import('../pages/brands/rurs.vue' /* webpackChunkName: "pages/brands/rurs" */))
const _2e4a3df6 = () => interopDefault(import('../pages/brands/sbont.vue' /* webpackChunkName: "pages/brands/sbont" */))
const _6e273640 = () => interopDefault(import('../pages/brands/sellesa.vue' /* webpackChunkName: "pages/brands/sellesa" */))
const _ab70f53c = () => interopDefault(import('../pages/brands/shbkat.vue' /* webpackChunkName: "pages/brands/shbkat" */))
const _73a0f5e6 = () => interopDefault(import('../pages/brands/tsro.vue' /* webpackChunkName: "pages/brands/tsro" */))
const _385ba377 = () => interopDefault(import('../pages/brands/twzef.vue' /* webpackChunkName: "pages/brands/twzef" */))
const _16b9c444 = () => interopDefault(import('../pages/brands/usdy.vue' /* webpackChunkName: "pages/brands/usdy" */))
const _2a029b16 = () => interopDefault(import('../pages/brands/uxema.vue' /* webpackChunkName: "pages/brands/uxema" */))
const _7db99378 = () => interopDefault(import('../pages/capabilities.vue' /* webpackChunkName: "pages/capabilities" */))
const _775f1f96 = () => interopDefault(import('../pages/care/index.vue' /* webpackChunkName: "pages/care/index" */))
const _405b2a63 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _c2adcab4 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _730788d4 = () => interopDefault(import('../pages/careers/archive.vue' /* webpackChunkName: "pages/careers/archive" */))
const _e44748d8 = () => interopDefault(import('../pages/careers/journey.vue' /* webpackChunkName: "pages/careers/journey" */))
const _936f85f6 = () => interopDefault(import('../pages/careers/opportunities.vue' /* webpackChunkName: "pages/careers/opportunities" */))
const _17a928ce = () => interopDefault(import('../pages/careers/perks.vue' /* webpackChunkName: "pages/careers/perks" */))
const _c61b0d44 = () => interopDefault(import('../pages/careers/_slug.vue' /* webpackChunkName: "pages/careers/_slug" */))
const _cc7510bc = () => interopDefault(import('../pages/cc.vue' /* webpackChunkName: "pages/cc" */))
const _050e3ca5 = () => interopDefault(import('../pages/cc/index.vue' /* webpackChunkName: "pages/cc/index" */))
const _d78b4ab4 = () => interopDefault(import('../pages/cc/invoices/index.vue' /* webpackChunkName: "pages/cc/invoices/index" */))
const _368a673a = () => interopDefault(import('../pages/cc/projects/index.vue' /* webpackChunkName: "pages/cc/projects/index" */))
const _1dbc4136 = () => interopDefault(import('../pages/cc/support/index.vue' /* webpackChunkName: "pages/cc/support/index" */))
const _69b04bf3 = () => interopDefault(import('../pages/cc/support/new.vue' /* webpackChunkName: "pages/cc/support/new" */))
const _2bcace8e = () => interopDefault(import('../pages/cc/invoices/_id.vue' /* webpackChunkName: "pages/cc/invoices/_id" */))
const _34d3c5f2 = () => interopDefault(import('../pages/cc/projects/_slug.vue' /* webpackChunkName: "pages/cc/projects/_slug" */))
const _212983c6 = () => interopDefault(import('../pages/cc/support/_slug.vue' /* webpackChunkName: "pages/cc/support/_slug" */))
const _e7295724 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _745bb84e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2297645f = () => interopDefault(import('../pages/culture/index.vue' /* webpackChunkName: "pages/culture/index" */))
const _58900fdf = () => interopDefault(import('../pages/disk.vue' /* webpackChunkName: "pages/disk" */))
const _5819e2db = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _932ccdc4 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _2ddf891e = () => interopDefault(import('../pages/events/hello.vue' /* webpackChunkName: "pages/events/hello" */))
const _64746020 = () => interopDefault(import('../pages/events/meetups/index.vue' /* webpackChunkName: "pages/events/meetups/index" */))
const _3ec3777e = () => interopDefault(import('../pages/events/sprint.vue' /* webpackChunkName: "pages/events/sprint" */))
const _fc618954 = () => interopDefault(import('../pages/events/talks/index.vue' /* webpackChunkName: "pages/events/talks/index" */))
const _3f9ec1bd = () => interopDefault(import('../pages/events/meetups/android.vue' /* webpackChunkName: "pages/events/meetups/android" */))
const _0a6a52bc = () => interopDefault(import('../pages/events/meetups/big-data-and-analytics.vue' /* webpackChunkName: "pages/events/meetups/big-data-and-analytics" */))
const _7ef5e69d = () => interopDefault(import('../pages/events/meetups/branding.vue' /* webpackChunkName: "pages/events/meetups/branding" */))
const _6c481e3c = () => interopDefault(import('../pages/events/meetups/business.vue' /* webpackChunkName: "pages/events/meetups/business" */))
const _0fe3aa2d = () => interopDefault(import('../pages/events/meetups/design-thinking.vue' /* webpackChunkName: "pages/events/meetups/design-thinking" */))
const _6fba2b7f = () => interopDefault(import('../pages/events/meetups/digital-marketing.vue' /* webpackChunkName: "pages/events/meetups/digital-marketing" */))
const _97c0a2b6 = () => interopDefault(import('../pages/events/meetups/guidelines.vue' /* webpackChunkName: "pages/events/meetups/guidelines" */))
const _502aa14a = () => interopDefault(import('../pages/events/meetups/ios.vue' /* webpackChunkName: "pages/events/meetups/ios" */))
const _6210fd2a = () => interopDefault(import('../pages/events/meetups/js.vue' /* webpackChunkName: "pages/events/meetups/js" */))
const _568e9004 = () => interopDefault(import('../pages/events/meetups/product-design.vue' /* webpackChunkName: "pages/events/meetups/product-design" */))
const _55a3efa6 = () => interopDefault(import('../pages/events/meetups/social-media.vue' /* webpackChunkName: "pages/events/meetups/social-media" */))
const _8d841202 = () => interopDefault(import('../pages/events/meetups/type-design.vue' /* webpackChunkName: "pages/events/meetups/type-design" */))
const _29f2a90c = () => interopDefault(import('../pages/events/meetups/user-experience.vue' /* webpackChunkName: "pages/events/meetups/user-experience" */))
const _4c307c28 = () => interopDefault(import('../pages/events/talks/business.vue' /* webpackChunkName: "pages/events/talks/business" */))
const _5e53bb99 = () => interopDefault(import('../pages/events/talks/code.vue' /* webpackChunkName: "pages/events/talks/code" */))
const _e7232c2c = () => interopDefault(import('../pages/events/talks/design.vue' /* webpackChunkName: "pages/events/talks/design" */))
const _69a16c38 = () => interopDefault(import('../pages/events/talks/technology.vue' /* webpackChunkName: "pages/events/talks/technology" */))
const _19c955c7 = () => interopDefault(import('../pages/experiments/index.vue' /* webpackChunkName: "pages/experiments/index" */))
const _1b961d41 = () => interopDefault(import('../pages/explore.vue' /* webpackChunkName: "pages/explore" */))
const _66b294c7 = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _b8df9d1a = () => interopDefault(import('../pages/follow.vue' /* webpackChunkName: "pages/follow" */))
const _0354ef26 = () => interopDefault(import('../pages/freebies/index.vue' /* webpackChunkName: "pages/freebies/index" */))
const _0a1b2401 = () => interopDefault(import('../pages/highlights.vue' /* webpackChunkName: "pages/highlights" */))
const _35c5c7a1 = () => interopDefault(import('../pages/homer.vue' /* webpackChunkName: "pages/homer" */))
const _684591d2 = () => interopDefault(import('../pages/homer/confirm.vue' /* webpackChunkName: "pages/homer/confirm" */))
const _564de3e0 = () => interopDefault(import('../pages/hubs.vue' /* webpackChunkName: "pages/hubs" */))
const _84193dea = () => interopDefault(import('../pages/impact/index.vue' /* webpackChunkName: "pages/impact/index" */))
const _60cfe641 = () => interopDefault(import('../pages/industries/index.vue' /* webpackChunkName: "pages/industries/index" */))
const _d4dcc398 = () => interopDefault(import('../pages/Infringement.vue' /* webpackChunkName: "pages/Infringement" */))
const _8bf4fa40 = () => interopDefault(import('../pages/insights/index.vue' /* webpackChunkName: "pages/insights/index" */))
const _50746654 = () => interopDefault(import('../pages/internships/index.vue' /* webpackChunkName: "pages/internships/index" */))
const _e5b44706 = () => interopDefault(import('../pages/knowledgebase.vue' /* webpackChunkName: "pages/knowledgebase" */))
const _20885140 = () => interopDefault(import('../pages/knowledgebase/index.vue' /* webpackChunkName: "pages/knowledgebase/index" */))
const _77093ee4 = () => interopDefault(import('../pages/knowledgebase/_category/index.vue' /* webpackChunkName: "pages/knowledgebase/_category/index" */))
const _7a768174 = () => interopDefault(import('../pages/knowledgebase/_category/_slug.vue' /* webpackChunkName: "pages/knowledgebase/_category/_slug" */))
const _1192a6cb = () => interopDefault(import('../pages/labs/index.vue' /* webpackChunkName: "pages/labs/index" */))
const _54833216 = () => interopDefault(import('../pages/learn/index.vue' /* webpackChunkName: "pages/learn/index" */))
const _337e6fe7 = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _7f89a5ac = () => interopDefault(import('../pages/legal/index.vue' /* webpackChunkName: "pages/legal/index" */))
const _705b8b84 = () => interopDefault(import('../pages/legal/Copyrights.vue' /* webpackChunkName: "pages/legal/Copyrights" */))
const _0f54b6ff = () => interopDefault(import('../pages/legal/DMCA.vue' /* webpackChunkName: "pages/legal/DMCA" */))
const _c7ea8122 = () => interopDefault(import('../pages/legal/privacy-policy.vue' /* webpackChunkName: "pages/legal/privacy-policy" */))
const _45b5e25d = () => interopDefault(import('../pages/legal/privacy-updates.vue' /* webpackChunkName: "pages/legal/privacy-updates" */))
const _2b987980 = () => interopDefault(import('../pages/legal/recruitment-privacy-policy.vue' /* webpackChunkName: "pages/legal/recruitment-privacy-policy" */))
const _63e9a3c6 = () => interopDefault(import('../pages/legal/terms-of-service.vue' /* webpackChunkName: "pages/legal/terms-of-service" */))
const _8c9d5f26 = () => interopDefault(import('../pages/lock.vue' /* webpackChunkName: "pages/lock" */))
const _500d2497 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _05a7a1f8 = () => interopDefault(import('../pages/now.vue' /* webpackChunkName: "pages/now" */))
const _0ce2f2ea = () => interopDefault(import('../pages/opensource/index.vue' /* webpackChunkName: "pages/opensource/index" */))
const _135b0f96 = () => interopDefault(import('../pages/ordering.vue' /* webpackChunkName: "pages/ordering" */))
const _ff66471e = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _5fa48a34 = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _5dede8ec = () => interopDefault(import('../pages/press/_slug.vue' /* webpackChunkName: "pages/press/_slug" */))
const _1bc2d474 = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _67c618e5 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _e0fe5d14 = () => interopDefault(import('../pages/report.vue' /* webpackChunkName: "pages/report" */))
const _af3a12d4 = () => interopDefault(import('../pages/resources/index.vue' /* webpackChunkName: "pages/resources/index" */))
const _b59d365a = () => interopDefault(import('../pages/scholarships.vue' /* webpackChunkName: "pages/scholarships" */))
const _1becf02c = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2fd458ba = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _6141ec4b = () => interopDefault(import('../pages/solutions/index.vue' /* webpackChunkName: "pages/solutions/index" */))
const _b5040f06 = () => interopDefault(import('../pages/soon.vue' /* webpackChunkName: "pages/soon" */))
const _6698fbe7 = () => interopDefault(import('../pages/teams/index.vue' /* webpackChunkName: "pages/teams/index" */))
const _3cbb91cc = () => interopDefault(import('../pages/tools/index.vue' /* webpackChunkName: "pages/tools/index" */))
const _8d6e992c = () => interopDefault(import('../pages/typefaces.vue' /* webpackChunkName: "pages/typefaces" */))
const _6bde873b = () => interopDefault(import('../pages/wave.vue' /* webpackChunkName: "pages/wave" */))
const _2ae020ea = () => interopDefault(import('../pages/webinars.vue' /* webpackChunkName: "pages/webinars" */))
const _b58d28d4 = () => interopDefault(import('../pages/work/index.vue' /* webpackChunkName: "pages/work/index" */))
const _0edac7a0 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _1ead1440 = () => interopDefault(import('../pages/works/_.vue' /* webpackChunkName: "pages/works/_" */))
const _0795507a = () => interopDefault(import('../pages/workshops.vue' /* webpackChunkName: "pages/workshops" */))
const _6278af43 = () => interopDefault(import('../pages/academy/courses.vue' /* webpackChunkName: "pages/academy/courses" */))
const _e280e774 = () => interopDefault(import('../pages/academy/lessons.vue' /* webpackChunkName: "pages/academy/lessons" */))
const _03e22a74 = () => interopDefault(import('../pages/academy/podcasts.vue' /* webpackChunkName: "pages/academy/podcasts" */))
const _63bfd73e = () => interopDefault(import('../pages/care/infringement.vue' /* webpackChunkName: "pages/care/infringement" */))
const _d43b0646 = () => interopDefault(import('../pages/care/live-chat.vue' /* webpackChunkName: "pages/care/live-chat" */))
const _6cb379a9 = () => interopDefault(import('../pages/company/about.vue' /* webpackChunkName: "pages/company/about" */))
const _5cf5f132 = () => interopDefault(import('../pages/company/branches/index.vue' /* webpackChunkName: "pages/company/branches/index" */))
const _3780f77c = () => interopDefault(import('../pages/company/franchise/index.vue' /* webpackChunkName: "pages/company/franchise/index" */))
const _62ddde9a = () => interopDefault(import('../pages/company/history/index.vue' /* webpackChunkName: "pages/company/history/index" */))
const _9000fd82 = () => interopDefault(import('../pages/company/partners.vue' /* webpackChunkName: "pages/company/partners" */))
const _22805b11 = () => interopDefault(import('../pages/company/story.vue' /* webpackChunkName: "pages/company/story" */))
const _0745b275 = () => interopDefault(import('../pages/company/years/index.vue' /* webpackChunkName: "pages/company/years/index" */))
const _1889342b = () => interopDefault(import('../pages/culture/approach.vue' /* webpackChunkName: "pages/culture/approach" */))
const _7b2f0ba6 = () => interopDefault(import('../pages/culture/manifesto.vue' /* webpackChunkName: "pages/culture/manifesto" */))
const _09731063 = () => interopDefault(import('../pages/culture/standards.vue' /* webpackChunkName: "pages/culture/standards" */))
const _fd7a2936 = () => interopDefault(import('../pages/culture/values.vue' /* webpackChunkName: "pages/culture/values" */))
const _d34b7620 = () => interopDefault(import('../pages/freebies/apple-watch.vue' /* webpackChunkName: "pages/freebies/apple-watch" */))
const _663318ca = () => interopDefault(import('../pages/freebies/blobs.vue' /* webpackChunkName: "pages/freebies/blobs" */))
const _60a8250f = () => interopDefault(import('../pages/freebies/large.vue' /* webpackChunkName: "pages/freebies/large" */))
const _5982df5a = () => interopDefault(import('../pages/freebies/lino.vue' /* webpackChunkName: "pages/freebies/lino" */))
const _97e521a6 = () => interopDefault(import('../pages/freebies/stark.vue' /* webpackChunkName: "pages/freebies/stark" */))
const _286c9829 = () => interopDefault(import('../pages/freebies/thousands.vue' /* webpackChunkName: "pages/freebies/thousands" */))
const _839867bc = () => interopDefault(import('../pages/impact/community.vue' /* webpackChunkName: "pages/impact/community" */))
const _b634b5e8 = () => interopDefault(import('../pages/impact/environment.vue' /* webpackChunkName: "pages/impact/environment" */))
const _2f391b63 = () => interopDefault(import('../pages/impact/initiatives/index.vue' /* webpackChunkName: "pages/impact/initiatives/index" */))
const _23109b26 = () => interopDefault(import('../pages/impact/people.vue' /* webpackChunkName: "pages/impact/people" */))
const _204db190 = () => interopDefault(import('../pages/impact/reports.vue' /* webpackChunkName: "pages/impact/reports" */))
const _bde01fb6 = () => interopDefault(import('../pages/impact/responsibility.vue' /* webpackChunkName: "pages/impact/responsibility" */))
const _fa8c6868 = () => interopDefault(import('../pages/impact/stories.vue' /* webpackChunkName: "pages/impact/stories" */))
const _6f8298ef = () => interopDefault(import('../pages/impact/volunteering.vue' /* webpackChunkName: "pages/impact/volunteering" */))
const _1cfc8e47 = () => interopDefault(import('../pages/industries/charity.vue' /* webpackChunkName: "pages/industries/charity" */))
const _07a8492f = () => interopDefault(import('../pages/industries/ecommerce.vue' /* webpackChunkName: "pages/industries/ecommerce" */))
const _5db50697 = () => interopDefault(import('../pages/industries/education.vue' /* webpackChunkName: "pages/industries/education" */))
const _5bdcaf89 = () => interopDefault(import('../pages/industries/finance.vue' /* webpackChunkName: "pages/industries/finance" */))
const _4cc7453f = () => interopDefault(import('../pages/industries/food.vue' /* webpackChunkName: "pages/industries/food" */))
const _9de87c3e = () => interopDefault(import('../pages/industries/furniture.vue' /* webpackChunkName: "pages/industries/furniture" */))
const _416c92dc = () => interopDefault(import('../pages/industries/government.vue' /* webpackChunkName: "pages/industries/government" */))
const _734b2e0e = () => interopDefault(import('../pages/industries/healthcare.vue' /* webpackChunkName: "pages/industries/healthcare" */))
const _6df545ad = () => interopDefault(import('../pages/industries/manufacturing.vue' /* webpackChunkName: "pages/industries/manufacturing" */))
const _ed66fd98 = () => interopDefault(import('../pages/industries/news.vue' /* webpackChunkName: "pages/industries/news" */))
const _38d59bec = () => interopDefault(import('../pages/industries/real-estate.vue' /* webpackChunkName: "pages/industries/real-estate" */))
const _e7a10d1c = () => interopDefault(import('../pages/internships/apply.vue' /* webpackChunkName: "pages/internships/apply" */))
const _b34de0c6 = () => interopDefault(import('../pages/opensource/align.vue' /* webpackChunkName: "pages/opensource/align" */))
const _4570e204 = () => interopDefault(import('../pages/opensource/board.vue' /* webpackChunkName: "pages/opensource/board" */))
const _daacfc4e = () => interopDefault(import('../pages/opensource/color-fns.vue' /* webpackChunkName: "pages/opensource/color-fns" */))
const _618d5126 = () => interopDefault(import('../pages/opensource/hooper.vue' /* webpackChunkName: "pages/opensource/hooper" */))
const _d2dc58ba = () => interopDefault(import('../pages/opensource/leaps.vue' /* webpackChunkName: "pages/opensource/leaps" */))
const _bdb87ab4 = () => interopDefault(import('../pages/opensource/tugger.vue' /* webpackChunkName: "pages/opensource/tugger" */))
const _2ad39b28 = () => interopDefault(import('../pages/opensource/verte.vue' /* webpackChunkName: "pages/opensource/verte" */))
const _9cd5aa2e = () => interopDefault(import('../pages/opensource/vuse.vue' /* webpackChunkName: "pages/opensource/vuse" */))
const _70d5e1e4 = () => interopDefault(import('../pages/programs/ambassadors.vue' /* webpackChunkName: "pages/programs/ambassadors" */))
const _23c5b0f7 = () => interopDefault(import('../pages/programs/seeders.vue' /* webpackChunkName: "pages/programs/seeders" */))
const _ad1acc74 = () => interopDefault(import('../pages/services/3d-production.vue' /* webpackChunkName: "pages/services/3d-production" */))
const _33fd0b96 = () => interopDefault(import('../pages/services/advertising.vue' /* webpackChunkName: "pages/services/advertising" */))
const _22a84e56 = () => interopDefault(import('../pages/services/animation.vue' /* webpackChunkName: "pages/services/animation" */))
const _9390da2e = () => interopDefault(import('../pages/services/app-design.vue' /* webpackChunkName: "pages/services/app-design" */))
const _6403016a = () => interopDefault(import('../pages/services/app-marketing.vue' /* webpackChunkName: "pages/services/app-marketing" */))
const _19506634 = () => interopDefault(import('../pages/services/art-direction.vue' /* webpackChunkName: "pages/services/art-direction" */))
const _f8ac8ffa = () => interopDefault(import('../pages/services/artificial-intelligence.vue' /* webpackChunkName: "pages/services/artificial-intelligence" */))
const _3c23f599 = () => interopDefault(import('../pages/services/big-data-and-analytics.vue' /* webpackChunkName: "pages/services/big-data-and-analytics" */))
const _19e1864a = () => interopDefault(import('../pages/services/brand-activation.vue' /* webpackChunkName: "pages/services/brand-activation" */))
const _1f77df8a = () => interopDefault(import('../pages/services/brand-consulting.vue' /* webpackChunkName: "pages/services/brand-consulting" */))
const _70e72db8 = () => interopDefault(import('../pages/services/brand-identity-design.vue' /* webpackChunkName: "pages/services/brand-identity-design" */))
const _2bc1d588 = () => interopDefault(import('../pages/services/brand-management.vue' /* webpackChunkName: "pages/services/brand-management" */))
const _ff91bf8c = () => interopDefault(import('../pages/services/branding.vue' /* webpackChunkName: "pages/services/branding" */))
const _69c5ab02 = () => interopDefault(import('../pages/services/business.vue' /* webpackChunkName: "pages/services/business" */))
const _32fa9b34 = () => interopDefault(import('../pages/services/business-consulting.vue' /* webpackChunkName: "pages/services/business-consulting" */))
const _185bd972 = () => interopDefault(import('../pages/services/business-model-innovation.vue' /* webpackChunkName: "pages/services/business-model-innovation" */))
const _f293bef2 = () => interopDefault(import('../pages/services/calligraphy.vue' /* webpackChunkName: "pages/services/calligraphy" */))
const _10e4501c = () => interopDefault(import('../pages/services/content-creation.vue' /* webpackChunkName: "pages/services/content-creation" */))
const _6e3b4ffc = () => interopDefault(import('../pages/services/conversion-rate-optimization.vue' /* webpackChunkName: "pages/services/conversion-rate-optimization" */))
const _220a4986 = () => interopDefault(import('../pages/services/design.vue' /* webpackChunkName: "pages/services/design" */))
const _0c0b9a14 = () => interopDefault(import('../pages/services/design-systems.vue' /* webpackChunkName: "pages/services/design-systems" */))
const _5e5c5ccc = () => interopDefault(import('../pages/services/development.vue' /* webpackChunkName: "pages/services/development" */))
const _73a5d87c = () => interopDefault(import('../pages/services/devops.vue' /* webpackChunkName: "pages/services/devops" */))
const _19b6de43 = () => interopDefault(import('../pages/services/editorial-design.vue' /* webpackChunkName: "pages/services/editorial-design" */))
const _7b9d6f7b = () => interopDefault(import('../pages/services/extended-reality.vue' /* webpackChunkName: "pages/services/extended-reality" */))
const _68ce5c42 = () => interopDefault(import('../pages/services/graphic-design.vue' /* webpackChunkName: "pages/services/graphic-design" */))
const _a3846274 = () => interopDefault(import('../pages/services/growth.vue' /* webpackChunkName: "pages/services/growth" */))
const _61814ce3 = () => interopDefault(import('../pages/services/industrial-design.vue' /* webpackChunkName: "pages/services/industrial-design" */))
const _796b4a23 = () => interopDefault(import('../pages/services/interaction.vue' /* webpackChunkName: "pages/services/interaction" */))
const _a84a28e8 = () => interopDefault(import('../pages/services/interior-design.vue' /* webpackChunkName: "pages/services/interior-design" */))
const _2ce188ee = () => interopDefault(import('../pages/services/internet-of-things.vue' /* webpackChunkName: "pages/services/internet-of-things" */))
const _075d8531 = () => interopDefault(import('../pages/services/logo-design.vue' /* webpackChunkName: "pages/services/logo-design" */))
const _5b3c6677 = () => interopDefault(import('../pages/services/marketing.vue' /* webpackChunkName: "pages/services/marketing" */))
const _7477c596 = () => interopDefault(import('../pages/services/motion.vue' /* webpackChunkName: "pages/services/motion" */))
const _75e075f2 = () => interopDefault(import('../pages/services/naming.vue' /* webpackChunkName: "pages/services/naming" */))
const _7e9960ae = () => interopDefault(import('../pages/services/organization-design.vue' /* webpackChunkName: "pages/services/organization-design" */))
const _6b450fa4 = () => interopDefault(import('../pages/services/photography.vue' /* webpackChunkName: "pages/services/photography" */))
const _54c4f04a = () => interopDefault(import('../pages/services/product-design.vue' /* webpackChunkName: "pages/services/product-design" */))
const _0d0f330c = () => interopDefault(import('../pages/services/progressive-web-apps.vue' /* webpackChunkName: "pages/services/progressive-web-apps" */))
const _7b270c2d = () => interopDefault(import('../pages/services/rebranding.vue' /* webpackChunkName: "pages/services/rebranding" */))
const _adeb4b08 = () => interopDefault(import('../pages/services/robotics-and-automation.vue' /* webpackChunkName: "pages/services/robotics-and-automation" */))
const _2d66361c = () => interopDefault(import('../pages/services/search-engine-optimization.vue' /* webpackChunkName: "pages/services/search-engine-optimization" */))
const _236321ff = () => interopDefault(import('../pages/services/security.vue' /* webpackChunkName: "pages/services/security" */))
const _0b75da7a = () => interopDefault(import('../pages/services/social-media.vue' /* webpackChunkName: "pages/services/social-media" */))
const _39c207ea = () => interopDefault(import('../pages/services/technology.vue' /* webpackChunkName: "pages/services/technology" */))
const _04facbb0 = () => interopDefault(import('../pages/services/technology-consulting.vue' /* webpackChunkName: "pages/services/technology-consulting" */))
const _2227a3a1 = () => interopDefault(import('../pages/services/testing.vue' /* webpackChunkName: "pages/services/testing" */))
const _82e5077c = () => interopDefault(import('../pages/services/type-design.vue' /* webpackChunkName: "pages/services/type-design" */))
const _055f1bc6 = () => interopDefault(import('../pages/services/usability.vue' /* webpackChunkName: "pages/services/usability" */))
const _06bbd73d = () => interopDefault(import('../pages/services/user-experience.vue' /* webpackChunkName: "pages/services/user-experience" */))
const _39912bb6 = () => interopDefault(import('../pages/services/user-experience-consulting.vue' /* webpackChunkName: "pages/services/user-experience-consulting" */))
const _679a5094 = () => interopDefault(import('../pages/services/user-interface.vue' /* webpackChunkName: "pages/services/user-interface" */))
const _1455c737 = () => interopDefault(import('../pages/services/videography.vue' /* webpackChunkName: "pages/services/videography" */))
const _73ee93d6 = () => interopDefault(import('../pages/services/web-design.vue' /* webpackChunkName: "pages/services/web-design" */))
const _6dd994a7 = () => interopDefault(import('../pages/solutions/android-apps.vue' /* webpackChunkName: "pages/solutions/android-apps" */))
const _6276ac80 = () => interopDefault(import('../pages/solutions/brand.vue' /* webpackChunkName: "pages/solutions/brand" */))
const _1fecebb2 = () => interopDefault(import('../pages/solutions/campaign.vue' /* webpackChunkName: "pages/solutions/campaign" */))
const _d8b794fc = () => interopDefault(import('../pages/solutions/cms.vue' /* webpackChunkName: "pages/solutions/cms" */))
const _c85038d2 = () => interopDefault(import('../pages/solutions/crm.vue' /* webpackChunkName: "pages/solutions/crm" */))
const _90f064cc = () => interopDefault(import('../pages/solutions/desktop-apps.vue' /* webpackChunkName: "pages/solutions/desktop-apps" */))
const _05cea09c = () => interopDefault(import('../pages/solutions/erp.vue' /* webpackChunkName: "pages/solutions/erp" */))
const _463d071c = () => interopDefault(import('../pages/solutions/illustrations.vue' /* webpackChunkName: "pages/solutions/illustrations" */))
const _9f1b076e = () => interopDefault(import('../pages/solutions/ios-apps.vue' /* webpackChunkName: "pages/solutions/ios-apps" */))
const _70432507 = () => interopDefault(import('../pages/solutions/landing-pages.vue' /* webpackChunkName: "pages/solutions/landing-pages" */))
const _270fad70 = () => interopDefault(import('../pages/solutions/magento.vue' /* webpackChunkName: "pages/solutions/magento" */))
const _1d0299f6 = () => interopDefault(import('../pages/solutions/mobile-apps.vue' /* webpackChunkName: "pages/solutions/mobile-apps" */))
const _400f3ee8 = () => interopDefault(import('../pages/solutions/odoo.vue' /* webpackChunkName: "pages/solutions/odoo" */))
const _7699b881 = () => interopDefault(import('../pages/solutions/opencart.vue' /* webpackChunkName: "pages/solutions/opencart" */))
const _347d81fc = () => interopDefault(import('../pages/solutions/packaging.vue' /* webpackChunkName: "pages/solutions/packaging" */))
const _46bee90d = () => interopDefault(import('../pages/solutions/pos.vue' /* webpackChunkName: "pages/solutions/pos" */))
const _a31f74b0 = () => interopDefault(import('../pages/solutions/product.vue' /* webpackChunkName: "pages/solutions/product" */))
const _6dc230d2 = () => interopDefault(import('../pages/solutions/saas.vue' /* webpackChunkName: "pages/solutions/saas" */))
const _1385c64c = () => interopDefault(import('../pages/solutions/store.vue' /* webpackChunkName: "pages/solutions/store" */))
const _7042430a = () => interopDefault(import('../pages/solutions/strategy.vue' /* webpackChunkName: "pages/solutions/strategy" */))
const _58700c63 = () => interopDefault(import('../pages/solutions/technology.vue' /* webpackChunkName: "pages/solutions/technology" */))
const _3f13a62e = () => interopDefault(import('../pages/solutions/typeface.vue' /* webpackChunkName: "pages/solutions/typeface" */))
const _3e524918 = () => interopDefault(import('../pages/solutions/video.vue' /* webpackChunkName: "pages/solutions/video" */))
const _125a08e2 = () => interopDefault(import('../pages/solutions/web-apps.vue' /* webpackChunkName: "pages/solutions/web-apps" */))
const _1af711aa = () => interopDefault(import('../pages/solutions/woocommerce.vue' /* webpackChunkName: "pages/solutions/woocommerce" */))
const _e5141a1c = () => interopDefault(import('../pages/solutions/wordpress.vue' /* webpackChunkName: "pages/solutions/wordpress" */))
const _3cae5f79 = () => interopDefault(import('../pages/teams/advertising.vue' /* webpackChunkName: "pages/teams/advertising" */))
const _3dbc3d14 = () => interopDefault(import('../pages/teams/branding.vue' /* webpackChunkName: "pages/teams/branding" */))
const _2c07ebbb = () => interopDefault(import('../pages/teams/business.vue' /* webpackChunkName: "pages/teams/business" */))
const _95ed7be0 = () => interopDefault(import('../pages/teams/development.vue' /* webpackChunkName: "pages/teams/development" */))
const _5fcfa132 = () => interopDefault(import('../pages/teams/interaction.vue' /* webpackChunkName: "pages/teams/interaction" */))
const _57a96c17 = () => interopDefault(import('../pages/teams/product-design.vue' /* webpackChunkName: "pages/teams/product-design" */))
const _97434372 = () => interopDefault(import('../pages/teams/technology.vue' /* webpackChunkName: "pages/teams/technology" */))
const _153a6186 = () => interopDefault(import('../pages/teams/type-design.vue' /* webpackChunkName: "pages/teams/type-design" */))
const _cf09376e = () => interopDefault(import('../pages/tools/abjad/index.vue' /* webpackChunkName: "pages/tools/abjad/index" */))
const _108017c8 = () => interopDefault(import('../pages/tools/atlas/index.vue' /* webpackChunkName: "pages/tools/atlas/index" */))
const _110776e9 = () => interopDefault(import('../pages/tools/colors/index.vue' /* webpackChunkName: "pages/tools/colors/index" */))
const _3e9a31f8 = () => interopDefault(import('../pages/tools/contrast.vue' /* webpackChunkName: "pages/tools/contrast" */))
const _79f84312 = () => interopDefault(import('../pages/tools/doodles/index.vue' /* webpackChunkName: "pages/tools/doodles/index" */))
const _cf4a8b8c = () => interopDefault(import('../pages/tools/draw.vue' /* webpackChunkName: "pages/tools/draw" */))
const _35db4979 = () => interopDefault(import('../pages/tools/egyptos.vue' /* webpackChunkName: "pages/tools/egyptos" */))
const _ad076018 = () => interopDefault(import('../pages/tools/folklore.vue' /* webpackChunkName: "pages/tools/folklore" */))
const _8b897d32 = () => interopDefault(import('../pages/tools/grids.vue' /* webpackChunkName: "pages/tools/grids" */))
const _5ff775e8 = () => interopDefault(import('../pages/tools/nostalgia.vue' /* webpackChunkName: "pages/tools/nostalgia" */))
const _6c492e30 = () => interopDefault(import('../pages/tools/quotes/index.vue' /* webpackChunkName: "pages/tools/quotes/index" */))
const _a0648584 = () => interopDefault(import('../pages/tools/ratios.vue' /* webpackChunkName: "pages/tools/ratios" */))
const _2faa7add = () => interopDefault(import('../pages/company/branches/cairo.vue' /* webpackChunkName: "pages/company/branches/cairo" */))
const _142618a0 = () => interopDefault(import('../pages/company/branches/damietta.vue' /* webpackChunkName: "pages/company/branches/damietta" */))
const _e2e2c38e = () => interopDefault(import('../pages/company/franchise/request.vue' /* webpackChunkName: "pages/company/franchise/request" */))
const _6c825ee8 = () => interopDefault(import('../pages/company/franchise/request-brochure.vue' /* webpackChunkName: "pages/company/franchise/request-brochure" */))
const _6444a90f = () => interopDefault(import('../pages/company/years/2015.vue' /* webpackChunkName: "pages/company/years/2015" */))
const _6452c090 = () => interopDefault(import('../pages/company/years/2016.vue' /* webpackChunkName: "pages/company/years/2016" */))
const _6460d811 = () => interopDefault(import('../pages/company/years/2017.vue' /* webpackChunkName: "pages/company/years/2017" */))
const _646eef92 = () => interopDefault(import('../pages/company/years/2018.vue' /* webpackChunkName: "pages/company/years/2018" */))
const _cc005838 = () => interopDefault(import('../pages/impact/initiatives/classroom.vue' /* webpackChunkName: "pages/impact/initiatives/classroom" */))
const _3da0826a = () => interopDefault(import('../pages/impact/initiatives/cool-off.vue' /* webpackChunkName: "pages/impact/initiatives/cool-off" */))
const _2820802e = () => interopDefault(import('../pages/impact/initiatives/empower.vue' /* webpackChunkName: "pages/impact/initiatives/empower" */))
const _46d4ae3e = () => interopDefault(import('../pages/impact/initiatives/human.vue' /* webpackChunkName: "pages/impact/initiatives/human" */))
const _3edd9b59 = () => interopDefault(import('../pages/impact/initiatives/thrive.vue' /* webpackChunkName: "pages/impact/initiatives/thrive" */))
const _6c5e0039 = () => interopDefault(import('../pages/impact/initiatives/together.vue' /* webpackChunkName: "pages/impact/initiatives/together" */))
const _8e86bb0c = () => interopDefault(import('../pages/impact/initiatives/zero-plastic.vue' /* webpackChunkName: "pages/impact/initiatives/zero-plastic" */))
const _44d60cdc = () => interopDefault(import('../pages/tools/abjad/word-counter.vue' /* webpackChunkName: "pages/tools/abjad/word-counter" */))
const _07ce6398 = () => interopDefault(import('../pages/tools/atlas/glossary.vue' /* webpackChunkName: "pages/tools/atlas/glossary" */))
const _494c455a = () => interopDefault(import('../pages/tools/colors/gradients.vue' /* webpackChunkName: "pages/tools/colors/gradients" */))
const _e8800b42 = () => interopDefault(import('../pages/tools/colors/names.vue' /* webpackChunkName: "pages/tools/colors/names" */))
const _932b31de = () => interopDefault(import('../pages/tools/colors/palettes.vue' /* webpackChunkName: "pages/tools/colors/palettes" */))
const _1050c658 = () => interopDefault(import('../pages/tools/colors/palettes/index.vue' /* webpackChunkName: "pages/tools/colors/palettes/index" */))
const _7ada81cf = () => interopDefault(import('../pages/tools/colors/ranges.vue' /* webpackChunkName: "pages/tools/colors/ranges" */))
const _3f16041b = () => interopDefault(import('../pages/tools/doodles/landmarks/_slug.vue' /* webpackChunkName: "pages/tools/doodles/landmarks/_slug" */))
const _b69a3b70 = () => interopDefault(import('../pages/tools/doodles/logos/_slug.vue' /* webpackChunkName: "pages/tools/doodles/logos/_slug" */))
const _49894f89 = () => interopDefault(import('../pages/tools/doodles/patterns/_slug.vue' /* webpackChunkName: "pages/tools/doodles/patterns/_slug" */))
const _05f09226 = () => interopDefault(import('../pages/tools/doodles/posters/_slug.vue' /* webpackChunkName: "pages/tools/doodles/posters/_slug" */))
const _50cd121b = () => interopDefault(import('../pages/tools/models/_slug.vue' /* webpackChunkName: "pages/tools/models/_slug" */))
const _4a2f5453 = () => interopDefault(import('../pages/academy/_lab.vue' /* webpackChunkName: "pages/academy/_lab" */))
const _70d21096 = () => interopDefault(import('../pages/academy/_lab/index.vue' /* webpackChunkName: "pages/academy/_lab/index" */))
const _6f1b6f4e = () => interopDefault(import('../pages/academy/_lab/_slug.vue' /* webpackChunkName: "pages/academy/_lab/_slug" */))
const _8f623cd0 = () => interopDefault(import('../pages/insights/_slug.vue' /* webpackChunkName: "pages/insights/_slug" */))
const _6b768cc8 = () => interopDefault(import('../pages/moments/_slug.vue' /* webpackChunkName: "pages/moments/_slug" */))
const _b8fa6b64 = () => interopDefault(import('../pages/work/_slug.vue' /* webpackChunkName: "pages/work/_slug" */))
const _c1f3f530 = () => interopDefault(import('../pages/backstage/_slug/_concept.vue' /* webpackChunkName: "pages/backstage/_slug/_concept" */))
const _5e4bb768 = () => interopDefault(import('../pages/projects/_.vue' /* webpackChunkName: "pages/projects/_" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _16561634 = () => interopDefault(import('../pages/books/-category.vue' /* webpackChunkName: "" */))
const _7a5a81f2 = () => interopDefault(import('../pages/books/-chapter.vue' /* webpackChunkName: "" */))
const _26efb73e = () => interopDefault(import('../pages/books/-slug.vue' /* webpackChunkName: "" */))
const _4291e19b = () => interopDefault(import('../pages/articles/-category.vue' /* webpackChunkName: "" */))
const _fc708970 = () => interopDefault(import('../pages/articles/-slug.vue' /* webpackChunkName: "" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
      path: "/academy",
      component: _3ded381d,
      name: "academy___en"
    }, {
      path: "/ar/academy",
      component: _3ded381d,
      name: "academy___ar"
    }, {
      path: "/account",
      component: _b4e7604a,
      children: [{
        path: "",
        component: _a4d373c4,
        name: "account___en"
      }, {
        path: "activity",
        component: _5a53541a,
        name: "account-activity___en"
      }, {
        path: "delete",
        component: _4477bd8f,
        name: "account-delete___en"
      }, {
        path: "edit",
        component: _6ed5a16e,
        name: "account-edit___en"
      }, {
        path: "security/email",
        component: _6c95c2f1,
        name: "account-security-email___en"
      }, {
        path: "security/phone",
        component: _9e45087a,
        name: "account-security-phone___en"
      }]
    }, {
      path: "/ar/account",
      component: _b4e7604a,
      children: [{
        path: "",
        component: _a4d373c4,
        name: "account___ar"
      }, {
        path: "activity",
        component: _5a53541a,
        name: "account-activity___ar"
      }, {
        path: "delete",
        component: _4477bd8f,
        name: "account-delete___ar"
      }, {
        path: "edit",
        component: _6ed5a16e,
        name: "account-edit___ar"
      }, {
        path: "security/email",
        component: _6c95c2f1,
        name: "account-security-email___ar"
      }, {
        path: "security/phone",
        component: _9e45087a,
        name: "account-security-phone___ar"
      }]
    }, {
      path: "/afkar",
      component: _105c51c5,
      name: "afkar___en"
    }, {
      path: "/ar/afkar",
      component: _105c51c5,
      name: "afkar___ar"
    }, {
      path: "/articles",
      component: _78dc0ac2,
      name: "articles___en"
    }, {
      path: "/ar/articles",
      component: _78dc0ac2,
      name: "articles___ar"
    }, {
      path: "/backstage",
      component: _48703068,
      name: "backstage___en"
    }, {
      path: "/ar/backstage",
      component: _48703068,
      name: "backstage___ar"
    }, {
      path: "/banks",
      component: _4d0c1fe5,
      name: "banks___en"
    }, {
      path: "/ar/banks",
      component: _4d0c1fe5,
      name: "banks___ar"
    }, {
      path: "/blog",
      component: _2074a764,
      children: [{
        path: "",
        component: _3051da32,
        name: "blog___en"
      }, {
        path: ":slug",
        component: _33bf1cc2,
        name: "blog-slug___en"
      }]
    }, {
      path: "/ar/blog",
      component: _2074a764,
      children: [{
        path: "",
        component: _3051da32,
        name: "blog___ar"
      }, {
        path: ":slug",
        component: _33bf1cc2,
        name: "blog-slug___ar"
      }]
    }, {
      path: "/books",
      component: _38c7184a,
      name: "books___en"
    }, {
      path: "/ar/books",
      component: _38c7184a,
      name: "books___ar"
    }, {
      path: "/brand",
      component: _f0cb7c96,
      children: [{
        path: "",
        component: _488c2710,
        name: "brand___en"
      }, {
        path: "downloads",
        component: _581da111,
        name: "brand-downloads___en"
      }, {
        path: "system",
        component: _d026260e,
        children: [{
          path: "",
          component: _856fd688,
          name: "brand-system___en"
        }, {
          path: "gettingStarted",
          component: _6a1a42a3,
          name: "brand-system-gettingStarted___en"
        }, {
          path: "introduction",
          component: _a9a2c9c0,
          name: "brand-system-introduction___en"
        }, {
          path: "components/button",
          component: _02f29cb5,
          name: "brand-system-components-button___en"
        }, {
          path: "components/buttons",
          component: _5f0c9b0e,
          name: "brand-system-components-buttons___en"
        }, {
          path: "components/checkbox",
          component: _96dcdbf4,
          name: "brand-system-components-checkbox___en"
        }, {
          path: "components/content",
          component: _a0a457f4,
          name: "brand-system-components-content___en"
        }, {
          path: "components/grid",
          component: _7708d589,
          name: "brand-system-components-grid___en"
        }, {
          path: "components/input",
          component: _f3c47fd2,
          name: "brand-system-components-input___en"
        }, {
          path: "components/InputGroup",
          component: _1153c778,
          name: "brand-system-components-InputGroup___en"
        }, {
          path: "components/label",
          component: _07b37b7e,
          name: "brand-system-components-label___en"
        }, {
          path: "components/navbar",
          component: _3e1c1ada,
          name: "brand-system-components-navbar___en"
        }, {
          path: "components/pagination",
          component: _0a8568c6,
          name: "brand-system-components-pagination___en"
        }, {
          path: "components/radio",
          component: _32ea0870,
          name: "brand-system-components-radio___en"
        }, {
          path: "components/select",
          component: _ea409502,
          name: "brand-system-components-select___en"
        }, {
          path: "components/switcher",
          component: _7fba7e24,
          name: "brand-system-components-switcher___en"
        }, {
          path: "components/table",
          component: _ac4dfbca,
          name: "brand-system-components-table___en"
        }, {
          path: "components/textarea",
          component: _c01ccc06,
          name: "brand-system-components-textarea___en"
        }, {
          path: "components/tooltip",
          component: _0e54cae0,
          name: "brand-system-components-tooltip___en"
        }, {
          path: "guidelines/accessibility",
          component: _25cf2070,
          name: "brand-system-guidelines-accessibility___en"
        }, {
          path: "guidelines/colors",
          component: _1bb30134,
          name: "brand-system-guidelines-colors___en"
        }, {
          path: "guidelines/content",
          component: _63ceb21a,
          name: "brand-system-guidelines-content___en"
        }, {
          path: "guidelines/grid",
          component: _2df7b1bc,
          name: "brand-system-guidelines-grid___en"
        }, {
          path: "guidelines/logo",
          component: _d8479cbe,
          name: "brand-system-guidelines-logo___en"
        }, {
          path: "guidelines/typography",
          component: _2e1760a5,
          name: "brand-system-guidelines-typography___en"
        }, {
          path: "visuals/graphics",
          component: _7d05816d,
          name: "brand-system-visuals-graphics___en"
        }, {
          path: "visuals/illustrations",
          component: _d7d24a9e,
          name: "brand-system-visuals-illustrations___en"
        }, {
          path: "visuals/spot-icons",
          component: _16ba00f1,
          name: "brand-system-visuals-spot-icons___en"
        }, {
          path: "visuals/system-icons",
          component: _2467fb5e,
          name: "brand-system-visuals-system-icons___en"
        }]
      }, {
        path: "artifacts/:slug?",
        component: _4689b3fc,
        name: "brand-artifacts-slug___en"
      }]
    }, {
      path: "/ar/brand",
      component: _f0cb7c96,
      children: [{
        path: "",
        component: _488c2710,
        name: "brand___ar"
      }, {
        path: "downloads",
        component: _581da111,
        name: "brand-downloads___ar"
      }, {
        path: "system",
        component: _d026260e,
        children: [{
          path: "",
          component: _856fd688,
          name: "brand-system___ar"
        }, {
          path: "gettingStarted",
          component: _6a1a42a3,
          name: "brand-system-gettingStarted___ar"
        }, {
          path: "introduction",
          component: _a9a2c9c0,
          name: "brand-system-introduction___ar"
        }, {
          path: "components/button",
          component: _02f29cb5,
          name: "brand-system-components-button___ar"
        }, {
          path: "components/buttons",
          component: _5f0c9b0e,
          name: "brand-system-components-buttons___ar"
        }, {
          path: "components/checkbox",
          component: _96dcdbf4,
          name: "brand-system-components-checkbox___ar"
        }, {
          path: "components/content",
          component: _a0a457f4,
          name: "brand-system-components-content___ar"
        }, {
          path: "components/grid",
          component: _7708d589,
          name: "brand-system-components-grid___ar"
        }, {
          path: "components/input",
          component: _f3c47fd2,
          name: "brand-system-components-input___ar"
        }, {
          path: "components/InputGroup",
          component: _1153c778,
          name: "brand-system-components-InputGroup___ar"
        }, {
          path: "components/label",
          component: _07b37b7e,
          name: "brand-system-components-label___ar"
        }, {
          path: "components/navbar",
          component: _3e1c1ada,
          name: "brand-system-components-navbar___ar"
        }, {
          path: "components/pagination",
          component: _0a8568c6,
          name: "brand-system-components-pagination___ar"
        }, {
          path: "components/radio",
          component: _32ea0870,
          name: "brand-system-components-radio___ar"
        }, {
          path: "components/select",
          component: _ea409502,
          name: "brand-system-components-select___ar"
        }, {
          path: "components/switcher",
          component: _7fba7e24,
          name: "brand-system-components-switcher___ar"
        }, {
          path: "components/table",
          component: _ac4dfbca,
          name: "brand-system-components-table___ar"
        }, {
          path: "components/textarea",
          component: _c01ccc06,
          name: "brand-system-components-textarea___ar"
        }, {
          path: "components/tooltip",
          component: _0e54cae0,
          name: "brand-system-components-tooltip___ar"
        }, {
          path: "guidelines/accessibility",
          component: _25cf2070,
          name: "brand-system-guidelines-accessibility___ar"
        }, {
          path: "guidelines/colors",
          component: _1bb30134,
          name: "brand-system-guidelines-colors___ar"
        }, {
          path: "guidelines/content",
          component: _63ceb21a,
          name: "brand-system-guidelines-content___ar"
        }, {
          path: "guidelines/grid",
          component: _2df7b1bc,
          name: "brand-system-guidelines-grid___ar"
        }, {
          path: "guidelines/logo",
          component: _d8479cbe,
          name: "brand-system-guidelines-logo___ar"
        }, {
          path: "guidelines/typography",
          component: _2e1760a5,
          name: "brand-system-guidelines-typography___ar"
        }, {
          path: "visuals/graphics",
          component: _7d05816d,
          name: "brand-system-visuals-graphics___ar"
        }, {
          path: "visuals/illustrations",
          component: _d7d24a9e,
          name: "brand-system-visuals-illustrations___ar"
        }, {
          path: "visuals/spot-icons",
          component: _16ba00f1,
          name: "brand-system-visuals-spot-icons___ar"
        }, {
          path: "visuals/system-icons",
          component: _2467fb5e,
          name: "brand-system-visuals-system-icons___ar"
        }]
      }, {
        path: "artifacts/:slug?",
        component: _4689b3fc,
        name: "brand-artifacts-slug___ar"
      }]
    }, {
      path: "/brands",
      component: _6f59960e,
      children: [{
        path: "",
        component: _b0964bde,
        name: "brands___en"
      }, {
        path: "afad",
        component: _432ac919,
        name: "brands-afad___en"
      }, {
        path: "alsfwa",
        component: _5fba024e,
        name: "brands-alsfwa___en"
      }, {
        path: "azda",
        component: _6a1bad87,
        name: "brands-azda___en"
      }, {
        path: "basb",
        component: _7db04c42,
        name: "brands-basb___en"
      }, {
        path: "blexar",
        component: _ac9dcf82,
        name: "brands-blexar___en"
      }, {
        path: "cojora",
        component: _bbaf171e,
        name: "brands-cojora___en"
      }, {
        path: "creavs",
        component: _7ac9ad19,
        name: "brands-creavs___en"
      }, {
        path: "fwasl",
        component: _0a5a2dc8,
        name: "brands-fwasl___en"
      }, {
        path: "halx",
        component: _25b2ffd6,
        name: "brands-halx___en"
      }, {
        path: "hostox",
        component: _1326f8e2,
        name: "brands-hostox___en"
      }, {
        path: "hwanm",
        component: _2a68e830,
        name: "brands-hwanm___en"
      }, {
        path: "mojz",
        component: _0e629b83,
        name: "brands-mojz___en"
      }, {
        path: "narx",
        component: _c191be6c,
        name: "brands-narx___en"
      }, {
        path: "nasj",
        component: _bfb29f4a,
        name: "brands-nasj___en"
      }, {
        path: "nllo",
        component: _4b40755c,
        name: "brands-nllo___en"
      }, {
        path: "obka",
        component: _2ec87ffa,
        name: "brands-obka___en"
      }, {
        path: "oraj",
        component: _6c9a915d,
        name: "brands-oraj___en"
      }, {
        path: "ords",
        component: _7237eec3,
        name: "brands-ords___en"
      }, {
        path: "rurs",
        component: _6071ffd5,
        name: "brands-rurs___en"
      }, {
        path: "sbont",
        component: _2e4a3df6,
        name: "brands-sbont___en"
      }, {
        path: "sellesa",
        component: _6e273640,
        name: "brands-sellesa___en"
      }, {
        path: "shbkat",
        component: _ab70f53c,
        name: "brands-shbkat___en"
      }, {
        path: "tsro",
        component: _73a0f5e6,
        name: "brands-tsro___en"
      }, {
        path: "twzef",
        component: _385ba377,
        name: "brands-twzef___en"
      }, {
        path: "usdy",
        component: _16b9c444,
        name: "brands-usdy___en"
      }, {
        path: "uxema",
        component: _2a029b16,
        name: "brands-uxema___en"
      }]
    }, {
      path: "/ar/brands",
      component: _6f59960e,
      children: [{
        path: "",
        component: _b0964bde,
        name: "brands___ar"
      }, {
        path: "afad",
        component: _432ac919,
        name: "brands-afad___ar"
      }, {
        path: "alsfwa",
        component: _5fba024e,
        name: "brands-alsfwa___ar"
      }, {
        path: "azda",
        component: _6a1bad87,
        name: "brands-azda___ar"
      }, {
        path: "basb",
        component: _7db04c42,
        name: "brands-basb___ar"
      }, {
        path: "blexar",
        component: _ac9dcf82,
        name: "brands-blexar___ar"
      }, {
        path: "cojora",
        component: _bbaf171e,
        name: "brands-cojora___ar"
      }, {
        path: "creavs",
        component: _7ac9ad19,
        name: "brands-creavs___ar"
      }, {
        path: "fwasl",
        component: _0a5a2dc8,
        name: "brands-fwasl___ar"
      }, {
        path: "halx",
        component: _25b2ffd6,
        name: "brands-halx___ar"
      }, {
        path: "hostox",
        component: _1326f8e2,
        name: "brands-hostox___ar"
      }, {
        path: "hwanm",
        component: _2a68e830,
        name: "brands-hwanm___ar"
      }, {
        path: "mojz",
        component: _0e629b83,
        name: "brands-mojz___ar"
      }, {
        path: "narx",
        component: _c191be6c,
        name: "brands-narx___ar"
      }, {
        path: "nasj",
        component: _bfb29f4a,
        name: "brands-nasj___ar"
      }, {
        path: "nllo",
        component: _4b40755c,
        name: "brands-nllo___ar"
      }, {
        path: "obka",
        component: _2ec87ffa,
        name: "brands-obka___ar"
      }, {
        path: "oraj",
        component: _6c9a915d,
        name: "brands-oraj___ar"
      }, {
        path: "ords",
        component: _7237eec3,
        name: "brands-ords___ar"
      }, {
        path: "rurs",
        component: _6071ffd5,
        name: "brands-rurs___ar"
      }, {
        path: "sbont",
        component: _2e4a3df6,
        name: "brands-sbont___ar"
      }, {
        path: "sellesa",
        component: _6e273640,
        name: "brands-sellesa___ar"
      }, {
        path: "shbkat",
        component: _ab70f53c,
        name: "brands-shbkat___ar"
      }, {
        path: "tsro",
        component: _73a0f5e6,
        name: "brands-tsro___ar"
      }, {
        path: "twzef",
        component: _385ba377,
        name: "brands-twzef___ar"
      }, {
        path: "usdy",
        component: _16b9c444,
        name: "brands-usdy___ar"
      }, {
        path: "uxema",
        component: _2a029b16,
        name: "brands-uxema___ar"
      }]
    }, {
      path: "/capabilities",
      component: _7db99378,
      name: "capabilities___en"
    }, {
      path: "/ar/capabilities",
      component: _7db99378,
      name: "capabilities___ar"
    }, {
      path: "/care",
      component: _775f1f96,
      name: "care___en"
    }, {
      path: "/ar/care",
      component: _775f1f96,
      name: "care___ar"
    }, {
      path: "/careers",
      component: _405b2a63,
      children: [{
        path: "",
        component: _c2adcab4,
        name: "careers___en"
      }, {
        path: "archive",
        component: _730788d4,
        name: "careers-archive___en"
      }, {
        path: "journey",
        component: _e44748d8,
        name: "careers-journey___en"
      }, {
        path: "opportunities",
        component: _936f85f6,
        name: "careers-opportunities___en"
      }, {
        path: "perks",
        component: _17a928ce,
        name: "careers-perks___en"
      }, {
        path: ":slug",
        component: _c61b0d44,
        name: "careers-slug___en"
      }]
    }, {
      path: "/ar/careers",
      component: _405b2a63,
      children: [{
        path: "",
        component: _c2adcab4,
        name: "careers___ar"
      }, {
        path: "archive",
        component: _730788d4,
        name: "careers-archive___ar"
      }, {
        path: "journey",
        component: _e44748d8,
        name: "careers-journey___ar"
      }, {
        path: "opportunities",
        component: _936f85f6,
        name: "careers-opportunities___ar"
      }, {
        path: "perks",
        component: _17a928ce,
        name: "careers-perks___ar"
      }, {
        path: ":slug",
        component: _c61b0d44,
        name: "careers-slug___ar"
      }]
    }, {
      path: "/cc",
      component: _cc7510bc,
      children: [{
        path: "",
        component: _050e3ca5,
        name: "cc___en"
      }, {
        path: "invoices",
        component: _d78b4ab4,
        name: "cc-invoices___en"
      }, {
        path: "projects",
        component: _368a673a,
        name: "cc-projects___en"
      }, {
        path: "support",
        component: _1dbc4136,
        name: "cc-support___en"
      }, {
        path: "support/new",
        component: _69b04bf3,
        name: "cc-support-new___en"
      }, {
        path: "invoices/:id",
        component: _2bcace8e,
        name: "cc-invoices-id___en"
      }, {
        path: "projects/:slug",
        component: _34d3c5f2,
        name: "cc-projects-slug___en"
      }, {
        path: "support/:slug",
        component: _212983c6,
        name: "cc-support-slug___en"
      }]
    }, {
      path: "/ar/cc",
      component: _cc7510bc,
      children: [{
        path: "",
        component: _050e3ca5,
        name: "cc___ar"
      }, {
        path: "invoices",
        component: _d78b4ab4,
        name: "cc-invoices___ar"
      }, {
        path: "projects",
        component: _368a673a,
        name: "cc-projects___ar"
      }, {
        path: "support",
        component: _1dbc4136,
        name: "cc-support___ar"
      }, {
        path: "support/new",
        component: _69b04bf3,
        name: "cc-support-new___ar"
      }, {
        path: "invoices/:id",
        component: _2bcace8e,
        name: "cc-invoices-id___ar"
      }, {
        path: "projects/:slug",
        component: _34d3c5f2,
        name: "cc-projects-slug___ar"
      }, {
        path: "support/:slug",
        component: _212983c6,
        name: "cc-support-slug___ar"
      }]
    }, {
      path: "/company",
      component: _e7295724,
      name: "company___en"
    }, {
      path: "/ar/company",
      component: _e7295724,
      name: "company___ar"
    }, {
      path: "/contact",
      component: _745bb84e,
      name: "contact___en"
    }, {
      path: "/ar/contact",
      component: _745bb84e,
      name: "contact___ar"
    }, {
      path: "/culture",
      component: _2297645f,
      name: "culture___en"
    }, {
      path: "/ar/culture",
      component: _2297645f,
      name: "culture___ar"
    }, {
      path: "/disk",
      component: _58900fdf,
      name: "disk___en"
    }, {
      path: "/ar/disk",
      component: _58900fdf,
      name: "disk___ar"
    }, {
      path: "/events",
      component: _5819e2db,
      children: [{
        path: "",
        component: _932ccdc4,
        name: "events___en"
      }, {
        path: "hello",
        component: _2ddf891e,
        name: "events-hello___en"
      }, {
        path: "meetups",
        component: _64746020,
        name: "events-meetups___en"
      }, {
        path: "sprint",
        component: _3ec3777e,
        name: "events-sprint___en"
      }, {
        path: "talks",
        component: _fc618954,
        name: "events-talks___en"
      }, {
        path: "meetups/android",
        component: _3f9ec1bd,
        name: "events-meetups-android___en"
      }, {
        path: "meetups/big-data-and-analytics",
        component: _0a6a52bc,
        name: "events-meetups-big-data-and-analytics___en"
      }, {
        path: "meetups/branding",
        component: _7ef5e69d,
        name: "events-meetups-branding___en"
      }, {
        path: "meetups/business",
        component: _6c481e3c,
        name: "events-meetups-business___en"
      }, {
        path: "meetups/design-thinking",
        component: _0fe3aa2d,
        name: "events-meetups-design-thinking___en"
      }, {
        path: "meetups/digital-marketing",
        component: _6fba2b7f,
        name: "events-meetups-digital-marketing___en"
      }, {
        path: "meetups/guidelines",
        component: _97c0a2b6,
        name: "events-meetups-guidelines___en"
      }, {
        path: "meetups/ios",
        component: _502aa14a,
        name: "events-meetups-ios___en"
      }, {
        path: "meetups/js",
        component: _6210fd2a,
        name: "events-meetups-js___en"
      }, {
        path: "meetups/product-design",
        component: _568e9004,
        name: "events-meetups-product-design___en"
      }, {
        path: "meetups/social-media",
        component: _55a3efa6,
        name: "events-meetups-social-media___en"
      }, {
        path: "meetups/type-design",
        component: _8d841202,
        name: "events-meetups-type-design___en"
      }, {
        path: "meetups/user-experience",
        component: _29f2a90c,
        name: "events-meetups-user-experience___en"
      }, {
        path: "talks/business",
        component: _4c307c28,
        name: "events-talks-business___en"
      }, {
        path: "talks/code",
        component: _5e53bb99,
        name: "events-talks-code___en"
      }, {
        path: "talks/design",
        component: _e7232c2c,
        name: "events-talks-design___en"
      }, {
        path: "talks/technology",
        component: _69a16c38,
        name: "events-talks-technology___en"
      }]
    }, {
      path: "/ar/events",
      component: _5819e2db,
      children: [{
        path: "",
        component: _932ccdc4,
        name: "events___ar"
      }, {
        path: "hello",
        component: _2ddf891e,
        name: "events-hello___ar"
      }, {
        path: "meetups",
        component: _64746020,
        name: "events-meetups___ar"
      }, {
        path: "sprint",
        component: _3ec3777e,
        name: "events-sprint___ar"
      }, {
        path: "talks",
        component: _fc618954,
        name: "events-talks___ar"
      }, {
        path: "meetups/android",
        component: _3f9ec1bd,
        name: "events-meetups-android___ar"
      }, {
        path: "meetups/big-data-and-analytics",
        component: _0a6a52bc,
        name: "events-meetups-big-data-and-analytics___ar"
      }, {
        path: "meetups/branding",
        component: _7ef5e69d,
        name: "events-meetups-branding___ar"
      }, {
        path: "meetups/business",
        component: _6c481e3c,
        name: "events-meetups-business___ar"
      }, {
        path: "meetups/design-thinking",
        component: _0fe3aa2d,
        name: "events-meetups-design-thinking___ar"
      }, {
        path: "meetups/digital-marketing",
        component: _6fba2b7f,
        name: "events-meetups-digital-marketing___ar"
      }, {
        path: "meetups/guidelines",
        component: _97c0a2b6,
        name: "events-meetups-guidelines___ar"
      }, {
        path: "meetups/ios",
        component: _502aa14a,
        name: "events-meetups-ios___ar"
      }, {
        path: "meetups/js",
        component: _6210fd2a,
        name: "events-meetups-js___ar"
      }, {
        path: "meetups/product-design",
        component: _568e9004,
        name: "events-meetups-product-design___ar"
      }, {
        path: "meetups/social-media",
        component: _55a3efa6,
        name: "events-meetups-social-media___ar"
      }, {
        path: "meetups/type-design",
        component: _8d841202,
        name: "events-meetups-type-design___ar"
      }, {
        path: "meetups/user-experience",
        component: _29f2a90c,
        name: "events-meetups-user-experience___ar"
      }, {
        path: "talks/business",
        component: _4c307c28,
        name: "events-talks-business___ar"
      }, {
        path: "talks/code",
        component: _5e53bb99,
        name: "events-talks-code___ar"
      }, {
        path: "talks/design",
        component: _e7232c2c,
        name: "events-talks-design___ar"
      }, {
        path: "talks/technology",
        component: _69a16c38,
        name: "events-talks-technology___ar"
      }]
    }, {
      path: "/experiments",
      component: _19c955c7,
      name: "experiments___en"
    }, {
      path: "/ar/experiments",
      component: _19c955c7,
      name: "experiments___ar"
    }, {
      path: "/explore",
      component: _1b961d41,
      name: "explore___en"
    }, {
      path: "/ar/explore",
      component: _1b961d41,
      name: "explore___ar"
    }, {
      path: "/feedback",
      component: _66b294c7,
      name: "feedback___en"
    }, {
      path: "/ar/feedback",
      component: _66b294c7,
      name: "feedback___ar"
    }, {
      path: "/follow",
      component: _b8df9d1a,
      name: "follow___en"
    }, {
      path: "/ar/follow",
      component: _b8df9d1a,
      name: "follow___ar"
    }, {
      path: "/freebies",
      component: _0354ef26,
      name: "freebies___en"
    }, {
      path: "/ar/freebies",
      component: _0354ef26,
      name: "freebies___ar"
    }, {
      path: "/highlights",
      component: _0a1b2401,
      name: "highlights___en"
    }, {
      path: "/ar/highlights",
      component: _0a1b2401,
      name: "highlights___ar"
    }, {
      path: "/homer",
      component: _35c5c7a1,
      children: [{
        path: "confirm",
        component: _684591d2,
        name: "homer-confirm___en"
      }]
    }, {
      path: "/ar/homer",
      component: _35c5c7a1,
      children: [{
        path: "confirm",
        component: _684591d2,
        name: "homer-confirm___ar"
      }]
    }, {
      path: "/hubs",
      component: _564de3e0,
      name: "hubs___en"
    }, {
      path: "/ar/hubs",
      component: _564de3e0,
      name: "hubs___ar"
    }, {
      path: "/impact",
      component: _84193dea,
      name: "impact___en"
    }, {
      path: "/ar/impact",
      component: _84193dea,
      name: "impact___ar"
    }, {
      path: "/industries",
      component: _60cfe641,
      name: "industries___en"
    }, {
      path: "/ar/industries",
      component: _60cfe641,
      name: "industries___ar"
    }, {
      path: "/Infringement",
      component: _d4dcc398,
      name: "Infringement___en"
    }, {
      path: "/ar/Infringement",
      component: _d4dcc398,
      name: "Infringement___ar"
    }, {
      path: "/insights",
      component: _8bf4fa40,
      name: "insights___en"
    }, {
      path: "/ar/insights",
      component: _8bf4fa40,
      name: "insights___ar"
    }, {
      path: "/internships",
      component: _50746654,
      name: "internships___en"
    }, {
      path: "/ar/internships",
      component: _50746654,
      name: "internships___ar"
    }, {
      path: "/knowledgebase",
      component: _e5b44706,
      children: [{
        path: "",
        component: _20885140,
        name: "knowledgebase___en"
      }, {
        path: ":category",
        component: _77093ee4,
        name: "knowledgebase-category___en"
      }, {
        path: ":category/:slug",
        component: _7a768174,
        name: "knowledgebase-category-slug___en"
      }]
    }, {
      path: "/ar/knowledgebase",
      component: _e5b44706,
      children: [{
        path: "",
        component: _20885140,
        name: "knowledgebase___ar"
      }, {
        path: ":category",
        component: _77093ee4,
        name: "knowledgebase-category___ar"
      }, {
        path: ":category/:slug",
        component: _7a768174,
        name: "knowledgebase-category-slug___ar"
      }]
    }, {
      path: "/labs",
      component: _1192a6cb,
      name: "labs___en"
    }, {
      path: "/ar/labs",
      component: _1192a6cb,
      name: "labs___ar"
    }, {
      path: "/learn",
      component: _54833216,
      name: "learn___en"
    }, {
      path: "/ar/learn",
      component: _54833216,
      name: "learn___ar"
    }, {
      path: "/legal",
      component: _337e6fe7,
      children: [{
        path: "",
        component: _7f89a5ac,
        name: "legal___en"
      }, {
        path: "Copyrights",
        component: _705b8b84,
        name: "legal-Copyrights___en"
      }, {
        path: "DMCA",
        component: _0f54b6ff,
        name: "legal-DMCA___en"
      }, {
        path: "privacy-policy",
        component: _c7ea8122,
        name: "legal-privacy-policy___en"
      }, {
        path: "privacy-updates",
        component: _45b5e25d,
        name: "legal-privacy-updates___en"
      }, {
        path: "recruitment-privacy-policy",
        component: _2b987980,
        name: "legal-recruitment-privacy-policy___en"
      }, {
        path: "terms-of-service",
        component: _63e9a3c6,
        name: "legal-terms-of-service___en"
      }]
    }, {
      path: "/ar/legal",
      component: _337e6fe7,
      children: [{
        path: "",
        component: _7f89a5ac,
        name: "legal___ar"
      }, {
        path: "Copyrights",
        component: _705b8b84,
        name: "legal-Copyrights___ar"
      }, {
        path: "DMCA",
        component: _0f54b6ff,
        name: "legal-DMCA___ar"
      }, {
        path: "privacy-policy",
        component: _c7ea8122,
        name: "legal-privacy-policy___ar"
      }, {
        path: "privacy-updates",
        component: _45b5e25d,
        name: "legal-privacy-updates___ar"
      }, {
        path: "recruitment-privacy-policy",
        component: _2b987980,
        name: "legal-recruitment-privacy-policy___ar"
      }, {
        path: "terms-of-service",
        component: _63e9a3c6,
        name: "legal-terms-of-service___ar"
      }]
    }, {
      path: "/lock",
      component: _8c9d5f26,
      name: "lock___en"
    }, {
      path: "/ar/lock",
      component: _8c9d5f26,
      name: "lock___ar"
    }, {
      path: "/login",
      component: _500d2497,
      name: "login___en"
    }, {
      path: "/ar/login",
      component: _500d2497,
      name: "login___ar"
    }, {
      path: "/now",
      component: _05a7a1f8,
      name: "now___en"
    }, {
      path: "/ar/now",
      component: _05a7a1f8,
      name: "now___ar"
    }, {
      path: "/opensource",
      component: _0ce2f2ea,
      name: "opensource___en"
    }, {
      path: "/ar/opensource",
      component: _0ce2f2ea,
      name: "opensource___ar"
    }, {
      path: "/ordering",
      component: _135b0f96,
      name: "ordering___en"
    }, {
      path: "/ar/ordering",
      component: _135b0f96,
      name: "ordering___ar"
    }, {
      path: "/press",
      component: _ff66471e,
      children: [{
        path: "",
        component: _5fa48a34,
        name: "press___en"
      }, {
        path: ":slug",
        component: _5dede8ec,
        name: "press-slug___en"
      }]
    }, {
      path: "/ar/press",
      component: _ff66471e,
      children: [{
        path: "",
        component: _5fa48a34,
        name: "press___ar"
      }, {
        path: ":slug",
        component: _5dede8ec,
        name: "press-slug___ar"
      }]
    }, {
      path: "/programs",
      component: _1bc2d474,
      name: "programs___en"
    }, {
      path: "/ar/programs",
      component: _1bc2d474,
      name: "programs___ar"
    }, {
      path: "/register",
      component: _67c618e5,
      name: "register___en"
    }, {
      path: "/ar/register",
      component: _67c618e5,
      name: "register___ar"
    }, {
      path: "/report",
      component: _e0fe5d14,
      name: "report___en"
    }, {
      path: "/ar/report",
      component: _e0fe5d14,
      name: "report___ar"
    }, {
      path: "/resources",
      component: _af3a12d4,
      name: "resources___en"
    }, {
      path: "/ar/resources",
      component: _af3a12d4,
      name: "resources___ar"
    }, {
      path: "/scholarships",
      component: _b59d365a,
      name: "scholarships___en"
    }, {
      path: "/ar/scholarships",
      component: _b59d365a,
      name: "scholarships___ar"
    }, {
      path: "/search",
      component: _1becf02c,
      name: "search___en"
    }, {
      path: "/ar/search",
      component: _1becf02c,
      name: "search___ar"
    }, {
      path: "/services",
      component: _2fd458ba,
      name: "services___en"
    }, {
      path: "/ar/services",
      component: _2fd458ba,
      name: "services___ar"
    }, {
      path: "/solutions",
      component: _6141ec4b,
      name: "solutions___en"
    }, {
      path: "/ar/solutions",
      component: _6141ec4b,
      name: "solutions___ar"
    }, {
      path: "/soon",
      component: _b5040f06,
      name: "soon___en"
    }, {
      path: "/ar/soon",
      component: _b5040f06,
      name: "soon___ar"
    }, {
      path: "/teams",
      component: _6698fbe7,
      name: "teams___en"
    }, {
      path: "/ar/teams",
      component: _6698fbe7,
      name: "teams___ar"
    }, {
      path: "/tools",
      component: _3cbb91cc,
      name: "tools___en"
    }, {
      path: "/ar/tools",
      component: _3cbb91cc,
      name: "tools___ar"
    }, {
      path: "/typefaces",
      component: _8d6e992c,
      name: "typefaces___en"
    }, {
      path: "/ar/typefaces",
      component: _8d6e992c,
      name: "typefaces___ar"
    }, {
      path: "/wave",
      component: _6bde873b,
      name: "wave___en"
    }, {
      path: "/ar/wave",
      component: _6bde873b,
      name: "wave___ar"
    }, {
      path: "/webinars",
      component: _2ae020ea,
      name: "webinars___en"
    }, {
      path: "/ar/webinars",
      component: _2ae020ea,
      name: "webinars___ar"
    }, {
      path: "/work",
      component: _b58d28d4,
      name: "work___en"
    }, {
      path: "/ar/work",
      component: _b58d28d4,
      name: "work___ar"
    }, {
      path: "/works",
      component: _0edac7a0,
      children: [{
        path: "*",
        component: _1ead1440,
        name: "works-all___en"
      }]
    }, {
      path: "/ar/works",
      component: _0edac7a0,
      children: [{
        path: "*",
        component: _1ead1440,
        name: "works-all___ar"
      }]
    }, {
      path: "/workshops",
      component: _0795507a,
      name: "workshops___en"
    }, {
      path: "/ar/workshops",
      component: _0795507a,
      name: "workshops___ar"
    }, {
      path: "/academy/courses",
      component: _6278af43,
      name: "academy-courses___en"
    }, {
      path: "/ar/academy/courses",
      component: _6278af43,
      name: "academy-courses___ar"
    }, {
      path: "/academy/lessons",
      component: _e280e774,
      name: "academy-lessons___en"
    }, {
      path: "/ar/academy/lessons",
      component: _e280e774,
      name: "academy-lessons___ar"
    }, {
      path: "/academy/podcasts",
      component: _03e22a74,
      name: "academy-podcasts___en"
    }, {
      path: "/ar/academy/podcasts",
      component: _03e22a74,
      name: "academy-podcasts___ar"
    }, {
      path: "/care/infringement",
      component: _63bfd73e,
      name: "care-infringement___en"
    }, {
      path: "/ar/care/infringement",
      component: _63bfd73e,
      name: "care-infringement___ar"
    }, {
      path: "/care/live-chat",
      component: _d43b0646,
      name: "care-live-chat___en"
    }, {
      path: "/ar/care/live-chat",
      component: _d43b0646,
      name: "care-live-chat___ar"
    }, {
      path: "/company/about",
      component: _6cb379a9,
      name: "company-about___en"
    }, {
      path: "/ar/company/about",
      component: _6cb379a9,
      name: "company-about___ar"
    }, {
      path: "/company/branches",
      component: _5cf5f132,
      name: "company-branches___en"
    }, {
      path: "/ar/company/branches",
      component: _5cf5f132,
      name: "company-branches___ar"
    }, {
      path: "/company/franchise",
      component: _3780f77c,
      name: "company-franchise___en"
    }, {
      path: "/ar/company/franchise",
      component: _3780f77c,
      name: "company-franchise___ar"
    }, {
      path: "/company/history",
      component: _62ddde9a,
      name: "company-history___en"
    }, {
      path: "/ar/company/history",
      component: _62ddde9a,
      name: "company-history___ar"
    }, {
      path: "/company/partners",
      component: _9000fd82,
      name: "company-partners___en"
    }, {
      path: "/ar/company/partners",
      component: _9000fd82,
      name: "company-partners___ar"
    }, {
      path: "/company/story",
      component: _22805b11,
      name: "company-story___en"
    }, {
      path: "/ar/company/story",
      component: _22805b11,
      name: "company-story___ar"
    }, {
      path: "/company/years",
      component: _0745b275,
      name: "company-years___en"
    }, {
      path: "/ar/company/years",
      component: _0745b275,
      name: "company-years___ar"
    }, {
      path: "/culture/approach",
      component: _1889342b,
      name: "culture-approach___en"
    }, {
      path: "/ar/culture/approach",
      component: _1889342b,
      name: "culture-approach___ar"
    }, {
      path: "/culture/manifesto",
      component: _7b2f0ba6,
      name: "culture-manifesto___en"
    }, {
      path: "/ar/culture/manifesto",
      component: _7b2f0ba6,
      name: "culture-manifesto___ar"
    }, {
      path: "/culture/standards",
      component: _09731063,
      name: "culture-standards___en"
    }, {
      path: "/ar/culture/standards",
      component: _09731063,
      name: "culture-standards___ar"
    }, {
      path: "/culture/values",
      component: _fd7a2936,
      name: "culture-values___en"
    }, {
      path: "/ar/culture/values",
      component: _fd7a2936,
      name: "culture-values___ar"
    }, {
      path: "/freebies/apple-watch",
      component: _d34b7620,
      name: "freebies-apple-watch___en"
    }, {
      path: "/ar/freebies/apple-watch",
      component: _d34b7620,
      name: "freebies-apple-watch___ar"
    }, {
      path: "/freebies/blobs",
      component: _663318ca,
      name: "freebies-blobs___en"
    }, {
      path: "/ar/freebies/blobs",
      component: _663318ca,
      name: "freebies-blobs___ar"
    }, {
      path: "/freebies/large",
      component: _60a8250f,
      name: "freebies-large___en"
    }, {
      path: "/ar/freebies/large",
      component: _60a8250f,
      name: "freebies-large___ar"
    }, {
      path: "/freebies/lino",
      component: _5982df5a,
      name: "freebies-lino___en"
    }, {
      path: "/ar/freebies/lino",
      component: _5982df5a,
      name: "freebies-lino___ar"
    }, {
      path: "/freebies/stark",
      component: _97e521a6,
      name: "freebies-stark___en"
    }, {
      path: "/ar/freebies/stark",
      component: _97e521a6,
      name: "freebies-stark___ar"
    }, {
      path: "/freebies/thousands",
      component: _286c9829,
      name: "freebies-thousands___en"
    }, {
      path: "/ar/freebies/thousands",
      component: _286c9829,
      name: "freebies-thousands___ar"
    }, {
      path: "/impact/community",
      component: _839867bc,
      name: "impact-community___en"
    }, {
      path: "/ar/impact/community",
      component: _839867bc,
      name: "impact-community___ar"
    }, {
      path: "/impact/environment",
      component: _b634b5e8,
      name: "impact-environment___en"
    }, {
      path: "/ar/impact/environment",
      component: _b634b5e8,
      name: "impact-environment___ar"
    }, {
      path: "/impact/initiatives",
      component: _2f391b63,
      name: "impact-initiatives___en"
    }, {
      path: "/ar/impact/initiatives",
      component: _2f391b63,
      name: "impact-initiatives___ar"
    }, {
      path: "/impact/people",
      component: _23109b26,
      name: "impact-people___en"
    }, {
      path: "/ar/impact/people",
      component: _23109b26,
      name: "impact-people___ar"
    }, {
      path: "/impact/reports",
      component: _204db190,
      name: "impact-reports___en"
    }, {
      path: "/ar/impact/reports",
      component: _204db190,
      name: "impact-reports___ar"
    }, {
      path: "/impact/responsibility",
      component: _bde01fb6,
      name: "impact-responsibility___en"
    }, {
      path: "/ar/impact/responsibility",
      component: _bde01fb6,
      name: "impact-responsibility___ar"
    }, {
      path: "/impact/stories",
      component: _fa8c6868,
      name: "impact-stories___en"
    }, {
      path: "/ar/impact/stories",
      component: _fa8c6868,
      name: "impact-stories___ar"
    }, {
      path: "/impact/volunteering",
      component: _6f8298ef,
      name: "impact-volunteering___en"
    }, {
      path: "/ar/impact/volunteering",
      component: _6f8298ef,
      name: "impact-volunteering___ar"
    }, {
      path: "/industries/charity",
      component: _1cfc8e47,
      name: "industries-charity___en"
    }, {
      path: "/ar/industries/charity",
      component: _1cfc8e47,
      name: "industries-charity___ar"
    }, {
      path: "/industries/ecommerce",
      component: _07a8492f,
      name: "industries-ecommerce___en"
    }, {
      path: "/ar/industries/ecommerce",
      component: _07a8492f,
      name: "industries-ecommerce___ar"
    }, {
      path: "/industries/education",
      component: _5db50697,
      name: "industries-education___en"
    }, {
      path: "/ar/industries/education",
      component: _5db50697,
      name: "industries-education___ar"
    }, {
      path: "/industries/finance",
      component: _5bdcaf89,
      name: "industries-finance___en"
    }, {
      path: "/ar/industries/finance",
      component: _5bdcaf89,
      name: "industries-finance___ar"
    }, {
      path: "/industries/food",
      component: _4cc7453f,
      name: "industries-food___en"
    }, {
      path: "/ar/industries/food",
      component: _4cc7453f,
      name: "industries-food___ar"
    }, {
      path: "/industries/furniture",
      component: _9de87c3e,
      name: "industries-furniture___en"
    }, {
      path: "/ar/industries/furniture",
      component: _9de87c3e,
      name: "industries-furniture___ar"
    }, {
      path: "/industries/government",
      component: _416c92dc,
      name: "industries-government___en"
    }, {
      path: "/ar/industries/government",
      component: _416c92dc,
      name: "industries-government___ar"
    }, {
      path: "/industries/healthcare",
      component: _734b2e0e,
      name: "industries-healthcare___en"
    }, {
      path: "/ar/industries/healthcare",
      component: _734b2e0e,
      name: "industries-healthcare___ar"
    }, {
      path: "/industries/manufacturing",
      component: _6df545ad,
      name: "industries-manufacturing___en"
    }, {
      path: "/ar/industries/manufacturing",
      component: _6df545ad,
      name: "industries-manufacturing___ar"
    }, {
      path: "/industries/news",
      component: _ed66fd98,
      name: "industries-news___en"
    }, {
      path: "/ar/industries/news",
      component: _ed66fd98,
      name: "industries-news___ar"
    }, {
      path: "/industries/real-estate",
      component: _38d59bec,
      name: "industries-real-estate___en"
    }, {
      path: "/ar/industries/real-estate",
      component: _38d59bec,
      name: "industries-real-estate___ar"
    }, {
      path: "/internships/apply",
      component: _e7a10d1c,
      name: "internships-apply___en"
    }, {
      path: "/ar/internships/apply",
      component: _e7a10d1c,
      name: "internships-apply___ar"
    }, {
      path: "/opensource/align",
      component: _b34de0c6,
      name: "opensource-align___en"
    }, {
      path: "/ar/opensource/align",
      component: _b34de0c6,
      name: "opensource-align___ar"
    }, {
      path: "/opensource/board",
      component: _4570e204,
      name: "opensource-board___en"
    }, {
      path: "/ar/opensource/board",
      component: _4570e204,
      name: "opensource-board___ar"
    }, {
      path: "/opensource/color-fns",
      component: _daacfc4e,
      name: "opensource-color-fns___en"
    }, {
      path: "/ar/opensource/color-fns",
      component: _daacfc4e,
      name: "opensource-color-fns___ar"
    }, {
      path: "/opensource/hooper",
      component: _618d5126,
      name: "opensource-hooper___en"
    }, {
      path: "/ar/opensource/hooper",
      component: _618d5126,
      name: "opensource-hooper___ar"
    }, {
      path: "/opensource/leaps",
      component: _d2dc58ba,
      name: "opensource-leaps___en"
    }, {
      path: "/ar/opensource/leaps",
      component: _d2dc58ba,
      name: "opensource-leaps___ar"
    }, {
      path: "/opensource/tugger",
      component: _bdb87ab4,
      name: "opensource-tugger___en"
    }, {
      path: "/ar/opensource/tugger",
      component: _bdb87ab4,
      name: "opensource-tugger___ar"
    }, {
      path: "/opensource/verte",
      component: _2ad39b28,
      name: "opensource-verte___en"
    }, {
      path: "/ar/opensource/verte",
      component: _2ad39b28,
      name: "opensource-verte___ar"
    }, {
      path: "/opensource/vuse",
      component: _9cd5aa2e,
      name: "opensource-vuse___en"
    }, {
      path: "/ar/opensource/vuse",
      component: _9cd5aa2e,
      name: "opensource-vuse___ar"
    }, {
      path: "/programs/ambassadors",
      component: _70d5e1e4,
      name: "programs-ambassadors___en"
    }, {
      path: "/ar/programs/ambassadors",
      component: _70d5e1e4,
      name: "programs-ambassadors___ar"
    }, {
      path: "/programs/seeders",
      component: _23c5b0f7,
      name: "programs-seeders___en"
    }, {
      path: "/ar/programs/seeders",
      component: _23c5b0f7,
      name: "programs-seeders___ar"
    }, {
      path: "/services/3d-production",
      component: _ad1acc74,
      name: "services-3d-production___en"
    }, {
      path: "/ar/services/3d-production",
      component: _ad1acc74,
      name: "services-3d-production___ar"
    }, {
      path: "/services/advertising",
      component: _33fd0b96,
      name: "services-advertising___en"
    }, {
      path: "/ar/services/advertising",
      component: _33fd0b96,
      name: "services-advertising___ar"
    }, {
      path: "/services/animation",
      component: _22a84e56,
      name: "services-animation___en"
    }, {
      path: "/ar/services/animation",
      component: _22a84e56,
      name: "services-animation___ar"
    }, {
      path: "/services/app-design",
      component: _9390da2e,
      name: "services-app-design___en"
    }, {
      path: "/ar/services/app-design",
      component: _9390da2e,
      name: "services-app-design___ar"
    }, {
      path: "/services/app-marketing",
      component: _6403016a,
      name: "services-app-marketing___en"
    }, {
      path: "/ar/services/app-marketing",
      component: _6403016a,
      name: "services-app-marketing___ar"
    }, {
      path: "/services/art-direction",
      component: _19506634,
      name: "services-art-direction___en"
    }, {
      path: "/ar/services/art-direction",
      component: _19506634,
      name: "services-art-direction___ar"
    }, {
      path: "/services/artificial-intelligence",
      component: _f8ac8ffa,
      name: "services-artificial-intelligence___en"
    }, {
      path: "/ar/services/artificial-intelligence",
      component: _f8ac8ffa,
      name: "services-artificial-intelligence___ar"
    }, {
      path: "/services/big-data-and-analytics",
      component: _3c23f599,
      name: "services-big-data-and-analytics___en"
    }, {
      path: "/ar/services/big-data-and-analytics",
      component: _3c23f599,
      name: "services-big-data-and-analytics___ar"
    }, {
      path: "/services/brand-activation",
      component: _19e1864a,
      name: "services-brand-activation___en"
    }, {
      path: "/ar/services/brand-activation",
      component: _19e1864a,
      name: "services-brand-activation___ar"
    }, {
      path: "/services/brand-consulting",
      component: _1f77df8a,
      name: "services-brand-consulting___en"
    }, {
      path: "/ar/services/brand-consulting",
      component: _1f77df8a,
      name: "services-brand-consulting___ar"
    }, {
      path: "/services/brand-identity-design",
      component: _70e72db8,
      name: "services-brand-identity-design___en"
    }, {
      path: "/ar/services/brand-identity-design",
      component: _70e72db8,
      name: "services-brand-identity-design___ar"
    }, {
      path: "/services/brand-management",
      component: _2bc1d588,
      name: "services-brand-management___en"
    }, {
      path: "/ar/services/brand-management",
      component: _2bc1d588,
      name: "services-brand-management___ar"
    }, {
      path: "/services/branding",
      component: _ff91bf8c,
      name: "services-branding___en"
    }, {
      path: "/ar/services/branding",
      component: _ff91bf8c,
      name: "services-branding___ar"
    }, {
      path: "/services/business",
      component: _69c5ab02,
      name: "services-business___en"
    }, {
      path: "/ar/services/business",
      component: _69c5ab02,
      name: "services-business___ar"
    }, {
      path: "/services/business-consulting",
      component: _32fa9b34,
      name: "services-business-consulting___en"
    }, {
      path: "/ar/services/business-consulting",
      component: _32fa9b34,
      name: "services-business-consulting___ar"
    }, {
      path: "/services/business-model-innovation",
      component: _185bd972,
      name: "services-business-model-innovation___en"
    }, {
      path: "/ar/services/business-model-innovation",
      component: _185bd972,
      name: "services-business-model-innovation___ar"
    }, {
      path: "/services/calligraphy",
      component: _f293bef2,
      name: "services-calligraphy___en"
    }, {
      path: "/ar/services/calligraphy",
      component: _f293bef2,
      name: "services-calligraphy___ar"
    }, {
      path: "/services/content-creation",
      component: _10e4501c,
      name: "services-content-creation___en"
    }, {
      path: "/ar/services/content-creation",
      component: _10e4501c,
      name: "services-content-creation___ar"
    }, {
      path: "/services/conversion-rate-optimization",
      component: _6e3b4ffc,
      name: "services-conversion-rate-optimization___en"
    }, {
      path: "/ar/services/conversion-rate-optimization",
      component: _6e3b4ffc,
      name: "services-conversion-rate-optimization___ar"
    }, {
      path: "/services/design",
      component: _220a4986,
      name: "services-design___en"
    }, {
      path: "/ar/services/design",
      component: _220a4986,
      name: "services-design___ar"
    }, {
      path: "/services/design-systems",
      component: _0c0b9a14,
      name: "services-design-systems___en"
    }, {
      path: "/ar/services/design-systems",
      component: _0c0b9a14,
      name: "services-design-systems___ar"
    }, {
      path: "/services/development",
      component: _5e5c5ccc,
      name: "services-development___en"
    }, {
      path: "/ar/services/development",
      component: _5e5c5ccc,
      name: "services-development___ar"
    }, {
      path: "/services/devops",
      component: _73a5d87c,
      name: "services-devops___en"
    }, {
      path: "/ar/services/devops",
      component: _73a5d87c,
      name: "services-devops___ar"
    }, {
      path: "/services/editorial-design",
      component: _19b6de43,
      name: "services-editorial-design___en"
    }, {
      path: "/ar/services/editorial-design",
      component: _19b6de43,
      name: "services-editorial-design___ar"
    }, {
      path: "/services/extended-reality",
      component: _7b9d6f7b,
      name: "services-extended-reality___en"
    }, {
      path: "/ar/services/extended-reality",
      component: _7b9d6f7b,
      name: "services-extended-reality___ar"
    }, {
      path: "/services/graphic-design",
      component: _68ce5c42,
      name: "services-graphic-design___en"
    }, {
      path: "/ar/services/graphic-design",
      component: _68ce5c42,
      name: "services-graphic-design___ar"
    }, {
      path: "/services/growth",
      component: _a3846274,
      name: "services-growth___en"
    }, {
      path: "/ar/services/growth",
      component: _a3846274,
      name: "services-growth___ar"
    }, {
      path: "/services/industrial-design",
      component: _61814ce3,
      name: "services-industrial-design___en"
    }, {
      path: "/ar/services/industrial-design",
      component: _61814ce3,
      name: "services-industrial-design___ar"
    }, {
      path: "/services/interaction",
      component: _796b4a23,
      name: "services-interaction___en"
    }, {
      path: "/ar/services/interaction",
      component: _796b4a23,
      name: "services-interaction___ar"
    }, {
      path: "/services/interior-design",
      component: _a84a28e8,
      name: "services-interior-design___en"
    }, {
      path: "/ar/services/interior-design",
      component: _a84a28e8,
      name: "services-interior-design___ar"
    }, {
      path: "/services/internet-of-things",
      component: _2ce188ee,
      name: "services-internet-of-things___en"
    }, {
      path: "/ar/services/internet-of-things",
      component: _2ce188ee,
      name: "services-internet-of-things___ar"
    }, {
      path: "/services/logo-design",
      component: _075d8531,
      name: "services-logo-design___en"
    }, {
      path: "/ar/services/logo-design",
      component: _075d8531,
      name: "services-logo-design___ar"
    }, {
      path: "/services/marketing",
      component: _5b3c6677,
      name: "services-marketing___en"
    }, {
      path: "/ar/services/marketing",
      component: _5b3c6677,
      name: "services-marketing___ar"
    }, {
      path: "/services/motion",
      component: _7477c596,
      name: "services-motion___en"
    }, {
      path: "/ar/services/motion",
      component: _7477c596,
      name: "services-motion___ar"
    }, {
      path: "/services/naming",
      component: _75e075f2,
      name: "services-naming___en"
    }, {
      path: "/ar/services/naming",
      component: _75e075f2,
      name: "services-naming___ar"
    }, {
      path: "/services/organization-design",
      component: _7e9960ae,
      name: "services-organization-design___en"
    }, {
      path: "/ar/services/organization-design",
      component: _7e9960ae,
      name: "services-organization-design___ar"
    }, {
      path: "/services/photography",
      component: _6b450fa4,
      name: "services-photography___en"
    }, {
      path: "/ar/services/photography",
      component: _6b450fa4,
      name: "services-photography___ar"
    }, {
      path: "/services/product-design",
      component: _54c4f04a,
      name: "services-product-design___en"
    }, {
      path: "/ar/services/product-design",
      component: _54c4f04a,
      name: "services-product-design___ar"
    }, {
      path: "/services/progressive-web-apps",
      component: _0d0f330c,
      name: "services-progressive-web-apps___en"
    }, {
      path: "/ar/services/progressive-web-apps",
      component: _0d0f330c,
      name: "services-progressive-web-apps___ar"
    }, {
      path: "/services/rebranding",
      component: _7b270c2d,
      name: "services-rebranding___en"
    }, {
      path: "/ar/services/rebranding",
      component: _7b270c2d,
      name: "services-rebranding___ar"
    }, {
      path: "/services/robotics-and-automation",
      component: _adeb4b08,
      name: "services-robotics-and-automation___en"
    }, {
      path: "/ar/services/robotics-and-automation",
      component: _adeb4b08,
      name: "services-robotics-and-automation___ar"
    }, {
      path: "/services/search-engine-optimization",
      component: _2d66361c,
      name: "services-search-engine-optimization___en"
    }, {
      path: "/ar/services/search-engine-optimization",
      component: _2d66361c,
      name: "services-search-engine-optimization___ar"
    }, {
      path: "/services/security",
      component: _236321ff,
      name: "services-security___en"
    }, {
      path: "/ar/services/security",
      component: _236321ff,
      name: "services-security___ar"
    }, {
      path: "/services/social-media",
      component: _0b75da7a,
      name: "services-social-media___en"
    }, {
      path: "/ar/services/social-media",
      component: _0b75da7a,
      name: "services-social-media___ar"
    }, {
      path: "/services/technology",
      component: _39c207ea,
      name: "services-technology___en"
    }, {
      path: "/ar/services/technology",
      component: _39c207ea,
      name: "services-technology___ar"
    }, {
      path: "/services/technology-consulting",
      component: _04facbb0,
      name: "services-technology-consulting___en"
    }, {
      path: "/ar/services/technology-consulting",
      component: _04facbb0,
      name: "services-technology-consulting___ar"
    }, {
      path: "/services/testing",
      component: _2227a3a1,
      name: "services-testing___en"
    }, {
      path: "/ar/services/testing",
      component: _2227a3a1,
      name: "services-testing___ar"
    }, {
      path: "/services/type-design",
      component: _82e5077c,
      name: "services-type-design___en"
    }, {
      path: "/ar/services/type-design",
      component: _82e5077c,
      name: "services-type-design___ar"
    }, {
      path: "/services/usability",
      component: _055f1bc6,
      name: "services-usability___en"
    }, {
      path: "/ar/services/usability",
      component: _055f1bc6,
      name: "services-usability___ar"
    }, {
      path: "/services/user-experience",
      component: _06bbd73d,
      name: "services-user-experience___en"
    }, {
      path: "/ar/services/user-experience",
      component: _06bbd73d,
      name: "services-user-experience___ar"
    }, {
      path: "/services/user-experience-consulting",
      component: _39912bb6,
      name: "services-user-experience-consulting___en"
    }, {
      path: "/ar/services/user-experience-consulting",
      component: _39912bb6,
      name: "services-user-experience-consulting___ar"
    }, {
      path: "/services/user-interface",
      component: _679a5094,
      name: "services-user-interface___en"
    }, {
      path: "/ar/services/user-interface",
      component: _679a5094,
      name: "services-user-interface___ar"
    }, {
      path: "/services/videography",
      component: _1455c737,
      name: "services-videography___en"
    }, {
      path: "/ar/services/videography",
      component: _1455c737,
      name: "services-videography___ar"
    }, {
      path: "/services/web-design",
      component: _73ee93d6,
      name: "services-web-design___en"
    }, {
      path: "/ar/services/web-design",
      component: _73ee93d6,
      name: "services-web-design___ar"
    }, {
      path: "/solutions/android-apps",
      component: _6dd994a7,
      name: "solutions-android-apps___en"
    }, {
      path: "/ar/solutions/android-apps",
      component: _6dd994a7,
      name: "solutions-android-apps___ar"
    }, {
      path: "/solutions/brand",
      component: _6276ac80,
      name: "solutions-brand___en"
    }, {
      path: "/ar/solutions/brand",
      component: _6276ac80,
      name: "solutions-brand___ar"
    }, {
      path: "/solutions/campaign",
      component: _1fecebb2,
      name: "solutions-campaign___en"
    }, {
      path: "/ar/solutions/campaign",
      component: _1fecebb2,
      name: "solutions-campaign___ar"
    }, {
      path: "/solutions/cms",
      component: _d8b794fc,
      name: "solutions-cms___en"
    }, {
      path: "/ar/solutions/cms",
      component: _d8b794fc,
      name: "solutions-cms___ar"
    }, {
      path: "/solutions/crm",
      component: _c85038d2,
      name: "solutions-crm___en"
    }, {
      path: "/ar/solutions/crm",
      component: _c85038d2,
      name: "solutions-crm___ar"
    }, {
      path: "/solutions/desktop-apps",
      component: _90f064cc,
      name: "solutions-desktop-apps___en"
    }, {
      path: "/ar/solutions/desktop-apps",
      component: _90f064cc,
      name: "solutions-desktop-apps___ar"
    }, {
      path: "/solutions/erp",
      component: _05cea09c,
      name: "solutions-erp___en"
    }, {
      path: "/ar/solutions/erp",
      component: _05cea09c,
      name: "solutions-erp___ar"
    }, {
      path: "/solutions/illustrations",
      component: _463d071c,
      name: "solutions-illustrations___en"
    }, {
      path: "/ar/solutions/illustrations",
      component: _463d071c,
      name: "solutions-illustrations___ar"
    }, {
      path: "/solutions/ios-apps",
      component: _9f1b076e,
      name: "solutions-ios-apps___en"
    }, {
      path: "/ar/solutions/ios-apps",
      component: _9f1b076e,
      name: "solutions-ios-apps___ar"
    }, {
      path: "/solutions/landing-pages",
      component: _70432507,
      name: "solutions-landing-pages___en"
    }, {
      path: "/ar/solutions/landing-pages",
      component: _70432507,
      name: "solutions-landing-pages___ar"
    }, {
      path: "/solutions/magento",
      component: _270fad70,
      name: "solutions-magento___en"
    }, {
      path: "/ar/solutions/magento",
      component: _270fad70,
      name: "solutions-magento___ar"
    }, {
      path: "/solutions/mobile-apps",
      component: _1d0299f6,
      name: "solutions-mobile-apps___en"
    }, {
      path: "/ar/solutions/mobile-apps",
      component: _1d0299f6,
      name: "solutions-mobile-apps___ar"
    }, {
      path: "/solutions/odoo",
      component: _400f3ee8,
      name: "solutions-odoo___en"
    }, {
      path: "/ar/solutions/odoo",
      component: _400f3ee8,
      name: "solutions-odoo___ar"
    }, {
      path: "/solutions/opencart",
      component: _7699b881,
      name: "solutions-opencart___en"
    }, {
      path: "/ar/solutions/opencart",
      component: _7699b881,
      name: "solutions-opencart___ar"
    }, {
      path: "/solutions/packaging",
      component: _347d81fc,
      name: "solutions-packaging___en"
    }, {
      path: "/ar/solutions/packaging",
      component: _347d81fc,
      name: "solutions-packaging___ar"
    }, {
      path: "/solutions/pos",
      component: _46bee90d,
      name: "solutions-pos___en"
    }, {
      path: "/ar/solutions/pos",
      component: _46bee90d,
      name: "solutions-pos___ar"
    }, {
      path: "/solutions/product",
      component: _a31f74b0,
      name: "solutions-product___en"
    }, {
      path: "/ar/solutions/product",
      component: _a31f74b0,
      name: "solutions-product___ar"
    }, {
      path: "/solutions/saas",
      component: _6dc230d2,
      name: "solutions-saas___en"
    }, {
      path: "/ar/solutions/saas",
      component: _6dc230d2,
      name: "solutions-saas___ar"
    }, {
      path: "/solutions/store",
      component: _1385c64c,
      name: "solutions-store___en"
    }, {
      path: "/ar/solutions/store",
      component: _1385c64c,
      name: "solutions-store___ar"
    }, {
      path: "/solutions/strategy",
      component: _7042430a,
      name: "solutions-strategy___en"
    }, {
      path: "/ar/solutions/strategy",
      component: _7042430a,
      name: "solutions-strategy___ar"
    }, {
      path: "/solutions/technology",
      component: _58700c63,
      name: "solutions-technology___en"
    }, {
      path: "/ar/solutions/technology",
      component: _58700c63,
      name: "solutions-technology___ar"
    }, {
      path: "/solutions/typeface",
      component: _3f13a62e,
      name: "solutions-typeface___en"
    }, {
      path: "/ar/solutions/typeface",
      component: _3f13a62e,
      name: "solutions-typeface___ar"
    }, {
      path: "/solutions/video",
      component: _3e524918,
      name: "solutions-video___en"
    }, {
      path: "/ar/solutions/video",
      component: _3e524918,
      name: "solutions-video___ar"
    }, {
      path: "/solutions/web-apps",
      component: _125a08e2,
      name: "solutions-web-apps___en"
    }, {
      path: "/ar/solutions/web-apps",
      component: _125a08e2,
      name: "solutions-web-apps___ar"
    }, {
      path: "/solutions/woocommerce",
      component: _1af711aa,
      name: "solutions-woocommerce___en"
    }, {
      path: "/ar/solutions/woocommerce",
      component: _1af711aa,
      name: "solutions-woocommerce___ar"
    }, {
      path: "/solutions/wordpress",
      component: _e5141a1c,
      name: "solutions-wordpress___en"
    }, {
      path: "/ar/solutions/wordpress",
      component: _e5141a1c,
      name: "solutions-wordpress___ar"
    }, {
      path: "/teams/advertising",
      component: _3cae5f79,
      name: "teams-advertising___en"
    }, {
      path: "/ar/teams/advertising",
      component: _3cae5f79,
      name: "teams-advertising___ar"
    }, {
      path: "/teams/branding",
      component: _3dbc3d14,
      name: "teams-branding___en"
    }, {
      path: "/ar/teams/branding",
      component: _3dbc3d14,
      name: "teams-branding___ar"
    }, {
      path: "/teams/business",
      component: _2c07ebbb,
      name: "teams-business___en"
    }, {
      path: "/ar/teams/business",
      component: _2c07ebbb,
      name: "teams-business___ar"
    }, {
      path: "/teams/development",
      component: _95ed7be0,
      name: "teams-development___en"
    }, {
      path: "/ar/teams/development",
      component: _95ed7be0,
      name: "teams-development___ar"
    }, {
      path: "/teams/interaction",
      component: _5fcfa132,
      name: "teams-interaction___en"
    }, {
      path: "/ar/teams/interaction",
      component: _5fcfa132,
      name: "teams-interaction___ar"
    }, {
      path: "/teams/product-design",
      component: _57a96c17,
      name: "teams-product-design___en"
    }, {
      path: "/ar/teams/product-design",
      component: _57a96c17,
      name: "teams-product-design___ar"
    }, {
      path: "/teams/technology",
      component: _97434372,
      name: "teams-technology___en"
    }, {
      path: "/ar/teams/technology",
      component: _97434372,
      name: "teams-technology___ar"
    }, {
      path: "/teams/type-design",
      component: _153a6186,
      name: "teams-type-design___en"
    }, {
      path: "/ar/teams/type-design",
      component: _153a6186,
      name: "teams-type-design___ar"
    }, {
      path: "/tools/abjad",
      component: _cf09376e,
      name: "tools-abjad___en"
    }, {
      path: "/ar/tools/abjad",
      component: _cf09376e,
      name: "tools-abjad___ar"
    }, {
      path: "/tools/atlas",
      component: _108017c8,
      name: "tools-atlas___en"
    }, {
      path: "/ar/tools/atlas",
      component: _108017c8,
      name: "tools-atlas___ar"
    }, {
      path: "/tools/colors",
      component: _110776e9,
      name: "tools-colors___en"
    }, {
      path: "/ar/tools/colors",
      component: _110776e9,
      name: "tools-colors___ar"
    }, {
      path: "/tools/contrast",
      component: _3e9a31f8,
      name: "tools-contrast___en"
    }, {
      path: "/ar/tools/contrast",
      component: _3e9a31f8,
      name: "tools-contrast___ar"
    }, {
      path: "/tools/doodles",
      component: _79f84312,
      name: "tools-doodles___en"
    }, {
      path: "/ar/tools/doodles",
      component: _79f84312,
      name: "tools-doodles___ar"
    }, {
      path: "/tools/draw",
      component: _cf4a8b8c,
      name: "tools-draw___en"
    }, {
      path: "/ar/tools/draw",
      component: _cf4a8b8c,
      name: "tools-draw___ar"
    }, {
      path: "/tools/egyptos",
      component: _35db4979,
      name: "tools-egyptos___en"
    }, {
      path: "/ar/tools/egyptos",
      component: _35db4979,
      name: "tools-egyptos___ar"
    }, {
      path: "/tools/folklore",
      component: _ad076018,
      name: "tools-folklore___en"
    }, {
      path: "/ar/tools/folklore",
      component: _ad076018,
      name: "tools-folklore___ar"
    }, {
      path: "/tools/grids",
      component: _8b897d32,
      name: "tools-grids___en"
    }, {
      path: "/ar/tools/grids",
      component: _8b897d32,
      name: "tools-grids___ar"
    }, {
      path: "/tools/nostalgia",
      component: _5ff775e8,
      name: "tools-nostalgia___en"
    }, {
      path: "/ar/tools/nostalgia",
      component: _5ff775e8,
      name: "tools-nostalgia___ar"
    }, {
      path: "/tools/quotes",
      component: _6c492e30,
      name: "tools-quotes___en"
    }, {
      path: "/ar/tools/quotes",
      component: _6c492e30,
      name: "tools-quotes___ar"
    }, {
      path: "/tools/ratios",
      component: _a0648584,
      name: "tools-ratios___en"
    }, {
      path: "/ar/tools/ratios",
      component: _a0648584,
      name: "tools-ratios___ar"
    }, {
      path: "/company/branches/cairo",
      component: _2faa7add,
      name: "company-branches-cairo___en"
    }, {
      path: "/ar/company/branches/cairo",
      component: _2faa7add,
      name: "company-branches-cairo___ar"
    }, {
      path: "/company/branches/damietta",
      component: _142618a0,
      name: "company-branches-damietta___en"
    }, {
      path: "/ar/company/branches/damietta",
      component: _142618a0,
      name: "company-branches-damietta___ar"
    }, {
      path: "/company/franchise/request",
      component: _e2e2c38e,
      name: "company-franchise-request___en"
    }, {
      path: "/ar/company/franchise/request",
      component: _e2e2c38e,
      name: "company-franchise-request___ar"
    }, {
      path: "/company/franchise/request-brochure",
      component: _6c825ee8,
      name: "company-franchise-request-brochure___en"
    }, {
      path: "/ar/company/franchise/request-brochure",
      component: _6c825ee8,
      name: "company-franchise-request-brochure___ar"
    }, {
      path: "/company/years/2015",
      component: _6444a90f,
      name: "company-years-2015___en"
    }, {
      path: "/ar/company/years/2015",
      component: _6444a90f,
      name: "company-years-2015___ar"
    }, {
      path: "/company/years/2016",
      component: _6452c090,
      name: "company-years-2016___en"
    }, {
      path: "/ar/company/years/2016",
      component: _6452c090,
      name: "company-years-2016___ar"
    }, {
      path: "/company/years/2017",
      component: _6460d811,
      name: "company-years-2017___en"
    }, {
      path: "/ar/company/years/2017",
      component: _6460d811,
      name: "company-years-2017___ar"
    }, {
      path: "/company/years/2018",
      component: _646eef92,
      name: "company-years-2018___en"
    }, {
      path: "/ar/company/years/2018",
      component: _646eef92,
      name: "company-years-2018___ar"
    }, {
      path: "/impact/initiatives/classroom",
      component: _cc005838,
      name: "impact-initiatives-classroom___en"
    }, {
      path: "/ar/impact/initiatives/classroom",
      component: _cc005838,
      name: "impact-initiatives-classroom___ar"
    }, {
      path: "/impact/initiatives/cool-off",
      component: _3da0826a,
      name: "impact-initiatives-cool-off___en"
    }, {
      path: "/ar/impact/initiatives/cool-off",
      component: _3da0826a,
      name: "impact-initiatives-cool-off___ar"
    }, {
      path: "/impact/initiatives/empower",
      component: _2820802e,
      name: "impact-initiatives-empower___en"
    }, {
      path: "/ar/impact/initiatives/empower",
      component: _2820802e,
      name: "impact-initiatives-empower___ar"
    }, {
      path: "/impact/initiatives/human",
      component: _46d4ae3e,
      name: "impact-initiatives-human___en"
    }, {
      path: "/ar/impact/initiatives/human",
      component: _46d4ae3e,
      name: "impact-initiatives-human___ar"
    }, {
      path: "/impact/initiatives/thrive",
      component: _3edd9b59,
      name: "impact-initiatives-thrive___en"
    }, {
      path: "/ar/impact/initiatives/thrive",
      component: _3edd9b59,
      name: "impact-initiatives-thrive___ar"
    }, {
      path: "/impact/initiatives/together",
      component: _6c5e0039,
      name: "impact-initiatives-together___en"
    }, {
      path: "/ar/impact/initiatives/together",
      component: _6c5e0039,
      name: "impact-initiatives-together___ar"
    }, {
      path: "/impact/initiatives/zero-plastic",
      component: _8e86bb0c,
      name: "impact-initiatives-zero-plastic___en"
    }, {
      path: "/ar/impact/initiatives/zero-plastic",
      component: _8e86bb0c,
      name: "impact-initiatives-zero-plastic___ar"
    }, {
      path: "/tools/abjad/word-counter",
      component: _44d60cdc,
      name: "tools-abjad-word-counter___en"
    }, {
      path: "/ar/tools/abjad/word-counter",
      component: _44d60cdc,
      name: "tools-abjad-word-counter___ar"
    }, {
      path: "/tools/atlas/glossary",
      component: _07ce6398,
      name: "tools-atlas-glossary___en"
    }, {
      path: "/ar/tools/atlas/glossary",
      component: _07ce6398,
      name: "tools-atlas-glossary___ar"
    }, {
      path: "/tools/colors/gradients",
      component: _494c455a,
      name: "tools-colors-gradients___en"
    }, {
      path: "/ar/tools/colors/gradients",
      component: _494c455a,
      name: "tools-colors-gradients___ar"
    }, {
      path: "/tools/colors/names",
      component: _e8800b42,
      name: "tools-colors-names___en"
    }, {
      path: "/ar/tools/colors/names",
      component: _e8800b42,
      name: "tools-colors-names___ar"
    }, {
      path: "/tools/colors/palettes",
      component: _932b31de,
      children: [{
        path: "",
        component: _1050c658,
        name: "tools-colors-palettes___en"
      }]
    }, {
      path: "/ar/tools/colors/palettes",
      component: _932b31de,
      children: [{
        path: "",
        component: _1050c658,
        name: "tools-colors-palettes___ar"
      }]
    }, {
      path: "/tools/colors/ranges",
      component: _7ada81cf,
      name: "tools-colors-ranges___en"
    }, {
      path: "/ar/tools/colors/ranges",
      component: _7ada81cf,
      name: "tools-colors-ranges___ar"
    }, {
      path: "/tools/doodles/landmarks/:slug?",
      component: _3f16041b,
      name: "tools-doodles-landmarks-slug___en"
    }, {
      path: "/ar/tools/doodles/landmarks/:slug?",
      component: _3f16041b,
      name: "tools-doodles-landmarks-slug___ar"
    }, {
      path: "/tools/doodles/logos/:slug?",
      component: _b69a3b70,
      name: "tools-doodles-logos-slug___en"
    }, {
      path: "/ar/tools/doodles/logos/:slug?",
      component: _b69a3b70,
      name: "tools-doodles-logos-slug___ar"
    }, {
      path: "/tools/doodles/patterns/:slug?",
      component: _49894f89,
      name: "tools-doodles-patterns-slug___en"
    }, {
      path: "/ar/tools/doodles/patterns/:slug?",
      component: _49894f89,
      name: "tools-doodles-patterns-slug___ar"
    }, {
      path: "/tools/doodles/posters/:slug?",
      component: _05f09226,
      name: "tools-doodles-posters-slug___en"
    }, {
      path: "/ar/tools/doodles/posters/:slug?",
      component: _05f09226,
      name: "tools-doodles-posters-slug___ar"
    }, {
      path: "/tools/models/:slug?",
      component: _50cd121b,
      name: "tools-models-slug___en"
    }, {
      path: "/ar/tools/models/:slug?",
      component: _50cd121b,
      name: "tools-models-slug___ar"
    }, {
      path: "/academy/:lab",
      component: _4a2f5453,
      children: [{
        path: "",
        component: _70d21096,
        name: "academy-lab___en"
      }, {
        path: ":slug",
        component: _6f1b6f4e,
        name: "academy-lab-slug___en"
      }]
    }, {
      path: "/ar/academy/:lab",
      component: _4a2f5453,
      children: [{
        path: "",
        component: _70d21096,
        name: "academy-lab___ar"
      }, {
        path: ":slug",
        component: _6f1b6f4e,
        name: "academy-lab-slug___ar"
      }]
    }, {
      path: "/insights/:slug",
      component: _8f623cd0,
      name: "insights-slug___en"
    }, {
      path: "/ar/insights/:slug",
      component: _8f623cd0,
      name: "insights-slug___ar"
    }, {
      path: "/moments/:slug?",
      component: _6b768cc8,
      name: "moments-slug___en"
    }, {
      path: "/ar/moments/:slug?",
      component: _6b768cc8,
      name: "moments-slug___ar"
    }, {
      path: "/work/:slug",
      component: _b8fa6b64,
      name: "work-slug___en"
    }, {
      path: "/ar/work/:slug",
      component: _b8fa6b64,
      name: "work-slug___ar"
    }, {
      path: "/backstage/:slug/:concept?",
      component: _c1f3f530,
      name: "backstage-slug-concept___en"
    }, {
      path: "/ar/backstage/:slug/:concept?",
      component: _c1f3f530,
      name: "backstage-slug-concept___ar"
    }, {
      path: "/projects/*",
      component: _5e4bb768,
      name: "projects-all___en"
    }, {
      path: "/ar/projects/*",
      component: _5e4bb768,
      name: "projects-all___ar"
    }, {
      path: "/",
      component: _f334fd00,
      name: "index___en"
    }, {
      path: "/ar/",
      component: _f334fd00,
      name: "index___ar"
    }, {
      path: "/books/(code|design|business|culture|leadership)",
      component: _16561634,
      meta: {"logoText":"books","theme":"books"},
      name: "BookCategoryRoute___en"
    }, {
      path: "/ar/books/(code|design|business|culture|leadership)",
      component: _16561634,
      meta: {"logoText":"books","theme":"books"},
      name: "BookCategoryRoute___ar"
    }, {
      path: "/books/:slug/:chapter",
      component: _7a5a81f2,
      meta: {"logoText":"books","theme":"books"},
      name: "ChapterViewRoute___en"
    }, {
      path: "/ar/books/:slug/:chapter",
      component: _7a5a81f2,
      meta: {"logoText":"books","theme":"books"},
      name: "ChapterViewRoute___ar"
    }, {
      path: "/books/:slug/",
      component: _26efb73e,
      meta: {"logoText":"books","theme":"books"},
      name: "BookViewRoute___en"
    }, {
      path: "/ar/books/:slug/",
      component: _26efb73e,
      meta: {"logoText":"books","theme":"books"},
      name: "BookViewRoute___ar"
    }, {
      path: "/articles/(code|design|business|culture|technology)",
      component: _4291e19b,
      meta: {"logoText":"articles"},
      name: "ArticlesCategoryRoute___en"
    }, {
      path: "/ar/articles/(code|design|business|culture|technology)",
      component: _4291e19b,
      meta: {"logoText":"articles"},
      name: "ArticlesCategoryRoute___ar"
    }, {
      path: "/articles/:slug/",
      component: _fc708970,
      meta: {"logoText":"articles","theme":"articles"},
      name: "ArticleViewRoute___en"
    }, {
      path: "/ar/articles/:slug/",
      component: _fc708970,
      meta: {"logoText":"articles","theme":"articles"},
      name: "ArticleViewRoute___ar"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
