import Vue from 'vue';
import Sockette from 'sockette';

export default function SockettPlugin({ app, store }) {
  if (process.server) return;

  const emitter = new Vue();
  emitter.connect = () => {
    emitter.$ws = new Sockette(`${store.state.env.WSS_URL}?token=${store.getters['auth/token']}`, {
      maxAttempts: 10,
      onmessage: e => {
        const data = JSON.parse(e.data);
        emitter.$emit(data.type, data);
      }
    });
  };

  if (store.getters['auth/token']) {
    emitter.connect();
  }

  Vue.prototype.$ws = emitter;
  app.$ws = emitter;
}
