import icons from '~/assets/data/icons.json';

export default {
  name: 'AppIcon',
  functional: true,
  inheritAttrs: true,
  props: {
    name: {
      type: String,
      required: true,
      validator: val => val in icons
    },
    useI18n: {
      type: Boolean,
      default: false
    }
  },
  render(createElement, { props, data, parent }) {
    let icon;
    // check for an i18n icon
    if (props.useI18n && parent.$i18n.locale !== 'en') {
      icon = icons[`${props.name}_${parent.$i18n.locale}`];
    }

    if (!icon) {
      icon = icons[props.name];
    }

    if (typeof icon === 'string') {
      icon = {
        path: icon,
        viewBox: '0 0 24 24'
      };
    }

    const children = [];
    if (icon.viewBox === '0 0 24 24') {
      children.push(
        createElement('path', {
          attrs: {
            d: 'M0 0h24v24H0z',
            fill: 'none'
          }
        })
      );
    }

    children.push(
      createElement('path', {
        attrs: {
          d: icon.path
        }
      })
    );

    return createElement(
      'svg',
      {
        attrs: {
          version: '1.1',
          xmlns: 'http://www.w3.org/2000/svg',
          class: `icon icon-${props.name}${data.staticClass ? ' ' + data.staticClass : ''}`,
          viewBox: icon.viewBox,
          id: `icon-${props.name}`
        }
      },
      children
    );
  }
};
