<template lang="pug">
.overlay-body.u-mh-md
  .container
    LoginForm

</template>

<script>
import LoginForm from '~/components/forms/LoginForm';

export default {
  name: 'TheLoginOverlay',
  components: {
    LoginForm
  }
};
</script>
