<template lang="pug">
  .navMain
    .navMain-container
      .navMain-col
        ul
          li.h2.u-mb-ts #[AppLink(to="/" :title="$t('home')") {{ $t('home') }}]
          li.h2.u-mb-ts #[AppLink(to="/work" :title="$t('work')") {{ $t('work') }}]
          li.h2.u-mb-ts #[AppLink(to="/services" :title="$t('services')") {{ $t('services') }}]
          li.h2.u-mb-ts #[AppLink(to="/company" :title="$t('about')") {{ $t('about') }}]
          //- li.h2.u-mb-xs #[AppLink(to="/brands" :title="$t('brands')") {{ $t('brands') }}]
          li.h2.u-mb-0 #[AppLink(to="/careers" :title="$t('careers')") {{ $t('careers') }}]
      .navMain-col
        ul
          li #[AppLink(to="/brand" :title="$t('brand')") #[h4.u-medium.u-mb-0 {{$t('brand')}} ]]
          li.u-mh-ts #[AppLink(to="/culture" :title="$t('culture')") #[h4.u-medium.u-mb-0 {{$t('culture')}} ]]
          //- li.u-mh-ts #[AppLink(to="/impact" :title="$t('impact')") #[h4.u-medium.u-mb-0 {{$t('impact')}} ]]
          li.u-mh-ts #[AppLink(to="/events" :title="$t('events')") #[h4.u-medium.u-mb-0 {{$t('events')}} ]]
          li.u-mh-ts #[AppLink(to="/learn" :title="$t('learn')") #[h4.u-medium.u-mb-0 {{$t('learn')}} ]]
          //- li.u-mh-ts #[AppLink(to="/programs" :title="$t('programs')") #[h4.u-medium.u-mb-0 {{$t('programs')}} ]]
          li.u-mh-ts #[AppLink(to="/press" :title="$t('press')") #[h4.u-medium.u-mb-0 {{$t('press')}} ]]
          li.u-mh-ts #[AppLink(to="/company/branches" :title="$t('branches')") #[h4.u-medium.u-mb-0 {{$t('branches')}} ]]
          li.u-mh-ts #[AppLink(to="/blog" :title="$t('blog')") #[h4.u-medium.u-mb-0 {{$t('blog')}} ]]

      .navMain-col
        ul
          li.u-mb-xm #[AppLink(to="/academy" :title="$t('academy')") #[h4.u-mb-ts {{ $t('academy') }}]] #[p {{$t('academyDesc')}} ]
          li.u-mb-xm #[AppLink(to="/labs" :title="$t('labs')") #[h4.u-mb-ts {{ $t('labs') }}] ] #[p {{ $t('labsDesc') }} ]
          li #[AppLink(to="/resources" :title="$t('resources')") #[h4.u-mb-ts {{ $t('resources') }}]] #[p {{ $t('resourcesDesc') }} ]
          //- li.u-mh-xs #[AppLink(to="/books" :title="$t('books')") #[h4.u-mb-ts {{$t('books')}} ]] #[p {{$t('booksDesc')}} ]
      .navMain-col
        h2.u-mb-xs {{ $t('help') }}
        ul.u-mb-xs
          li.u-mb-ts #[AppLink(to="/care" :title="$t('care')") {{ $t('care') }}]
          //- li.u-mb-ts #[AppLink(to="/cc" :title="$t('customerCare')") {{ $t('customerCare') }}]
          li.u-mb-ts #[AppLink(to="/ordering" :title="$t('ordering')") {{ $t('ordering') }}]
          li.u-mb-ts #[AppLink(to="/feedback" :title="$t('feedback')") {{ $t('feedback') }}]
          li #[AppLink(to="/contact" :title="$t('contact')") {{ $t('contact') }}]
        h5.u-mb-ts {{ $t('email') }}
        a(href="mailto:Hello@baianat.com", title="Hello@baianat.com") Hello@baianat.com
        h5.u-mb-ts.u-mt-xs {{ $t('phone') }}
        a(href="tel:00201000111888", title="002 01000111888" style="direction:ltr; display: inline-block;") #[span 002] 01000111888

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavigationMenu',
  transition: 'overlay',
  computed: mapGetters({
    env: 'env'
  })
};
</script>

<style lang="stylus">
.navMain
  min-height: 100vh
  display: flex
  align-items: center
  padding: 4vw 0 0

  +mq($until: 'tablet')
    .h5
      font-size: unitRes(24, 80)

  .heading, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
    line-height: 1.1 !important

  h4.u-medium
    font-size: unitRes(28, 52)

  .navMain-container
    width: 100%
    display: flex
    justify-content: space-between
    margin: unitRes(40, 80) 0

  ul
    list-style-type: none

  p
    max-width: 400px

    +mq($until: 'ultrawide')
      max-width: 300px

    +mq($until: 1600px)
      max-width: 280px

    +mq($until: 'widescreen')
      max-width: 220px

    +mq($until: 'desktop')
      max-width: 200px

    +mq($until: 'mobile')
      max-width: 200px

  +mq($until: 'tablet')
    align-items: flex-start
    padding-top: 80px
    height: auto

    .navMain-container
      flex-wrap: wrap

    .navMain-col
      flex: 0 0 50%
      width: 50%
      margin-bottom: 80px

  &-col
    +mq($until: 1360px)
      transform: scale(0.9)

      p
        font-size: 18px

    +mq($until: 'desktop')
      transform: none

      p
        font-size: 14px

    +mq($until: 'mobile')
      flex: 0 0 40% !important
      width: 40% !important

    +mq($until: 360px)
      flex: 0 0 100% !important
      width: 100% !important
      margin-bottom: 40px !important
</style>

<i18n>
{
  "en": {
    "home":"Home",
    "about":"Company",
    "services":"Services",
    "work":"Work",
    "brands":"Brands",
    "careers":"Careers",
    "brand":"Brand",
    "base":"Base",
    "culture":"Culture",
    "events":"Events",
    "moments":"Moments",
    "doodles":"Doodles",
    "academy":"Academy",
    "press":"Press",
    "legal":"Legal",
    "labs":"Labs",
    "labsDesc":"Get a closer look into what’s cooking inside Baianat.",
    "hubs":"Hubs",
    "hubsDesc":"Resources, open-source, tools, books, and more..",
    "community":"Community",
    "communityDesc":"On ground touch points and gathering.",
    "books":"Books",
    "booksDesc":"Our libraries in business, design, code and technology",
    "academy":"Academy",
    "academyDesc":"Learning paths in design, business, development and technology.",
    "blog":"Blog",
    "blogDesc":"Game changing tips and insights for visionaries.",
    "resources":"Resources",
    "resourcesDesc":"From Icons to UI mockups, explore and download whatever you need.",
    "help":"Help?",
    "customerCare":"Customer care",
    "ordering":"Ordering",
    "wave":"Wave",
    "disk":"Disk",
    "contact":"Contact",
    "email":"Email",
    "phone":"Phone",
    "feedback":"Feedback",
    "support":"Support",
    "care":"Care",
    "highlights":"Highlights",
    "innovation":"Innovation",
    "csr":"CSR",
    "abilities":"Abilities",
    "impact":"Impact",
    "academy":"Academy",
    "learn":"Learn",
    "programs":"Programs",
    "explore":"Explore",
    "articles":"Articles",
    "branches":"Branches"
  },
  "ar": {
    "home":"الرئيسية",
    "about":"الشركة",
    "services":"الخدمات",
    "work":"أعمالنا",
    "brands":"علاماتنا",
    "careers":"الوظائف",
    "brand":"العلامة التجارية",
    "base":"نظام التصميم",
    "culture":"الثقافة",
    "events":"الفعاليات",
    "moments":"اللحظات",
    "doodles":"الخربشات",
    "academy":"الأكاديمية",
    "press":"الصحافة",
    "legal":"القوانين",
    "labs":"المختبرات",
    "labsDesc":"منطقة المصممين والمطورين والاستراتيجيين.",
    "hubs":"المراكز",
    "hubsDesc":"مصادر مفتوحة وتصميمات مجانية وأدوات وكتب والمزيد.",
    "community":"المجتمع",
    "communityDesc":"الملتقيات و البرامج التي تجمعنا بكم على أرض الواقع.",
    "books":"الكتب",
    "booksDesc":"مكتبتنا في التصميم والبرمجة والتكنولوجيا والأعمال التجارية.",
    "academy":"الأكاديمية",
    "academyDesc":"مسارات التعلم في التصميم والأعمال التجارية والبرمجة والتكنولوجيا.",
    "blog":"المدونة",
    "blogDesc":"نصائح ورؤى تُغير اللعبة بأكملها من أجل الحالمين بمستقبل أفضل.",
    "resources":"المصادر",
    "resourcesDesc":"عناصر بصرية وأيقونات وقوالب تم تصميمها خصيصًا من أجل المصممين.",
    "help":"مساعدة؟",
    "customerCare":"خدمة العملاء",
    "ordering":"طلب الخدمة",
    "wave":"ويف",
    "disk":"ديسك",
    "contact":"اتصل بنا",
    "email":"البريد الإلكتروني",
    "phone":"الهاتف",
    "feedback":"شكاوي واقتراحات",
    "support":"الدعم",
    "care":"الدعم",
    "highlights":"مقتطفات",
    "innovation":"الابتكار",
    "csr":"المسئولية المجتمعية",
    "abilities":"القدرات",
    "impact":"التأثير",
    "academy":"الأكاديمية",
    "learn":"التعلم",
    "programs":"البرامج",
    "explore":"اكتشف",
    "articles":"المقالات",
    "branches":"الفروع"

  }
}
</i18n>
