<template lang="pug">
div
  .header-after
  header.header#header(@keyup.esc="close()" ref="header")
    .header-logo
      Logo

    ul.header-menu
      li
        a(href="#0", :title="$t('menu')" @click.prevent="$store.commit('SET_NAV_OVERLAY', true)")
          AppIcon(name='menu')
          span(v-t="'menu'")

      //- li.has-badge(v-if="isLoggedIn")
      //-   a(href="#", :title="$t('notifications')" @click.prevent="$store.commit('SET_NOTIFICATION_OVERLAY', true)")
      //-     AppIcon(name='bell')
      //-     span.header-menu-badge(v-if="unreadNotifications") {{ unreadNotifications }}]
      li.hide-mobile
        a(href="/search" :title="$t('search')" @click.prevent="$store.commit('SET_SEARCH_OVERLAY', true)")
          AppIcon(name='search')
          span(v-t="'search'")
      li
        a(v-if="$i18n.locale === 'en'" :href="switchLocaleURL", active-class='none')
          AppIcon(name='lang')
          span {{ $t('lang') }}
        a(v-else :href="switchLocaleURL" active-class='none')
          AppIcon(name='lang')
          span {{ $t('lang') }}
      li.is-user(v-if="isLoggedIn")
        AppLink(to="/cc" title="Account")  #[AppIcon(name='user')] #[span  {{ $t('hi') }}, {{ user.firstName }}]
      li.is-user(v-if="!isLoggedIn")
        a(href="/login" :title="$t('login')" @click.prevent="$store.commit('SET_LOGIN_OVERLAY', true)")
          AppIcon(name='user')
          span(v-t="'login'")

  transition(name="overlay")
    .overlay.overlay-notifications(v-if="$store.state.showNotifications")
      a(href="#", :title="$t('close')" @click.prevent="$store.commit('SET_NOTIFICATION_OVERLAY', false)" class="overlay-close") #[AppIcon(name='close')]
      TheNotificationsOverlay(@close="$store.commit('SET_NOTIFICATION_OVERLAY', false)")

  transition(name="overlay")
    .overlay.overlay-notifications(v-if="$store.state.showLogin")
      a(href="#", :title="$t('close')" @click.prevent="$store.commit('SET_LOGIN_OVERLAY', false)" class="overlay-close") #[AppIcon(name='close')]
      TheLoginOverlay(@close="$store.commit('SET_LOGIN_OVERLAY', false)")


  transition(name="overlay")
    .overlay.overlay-notifications(v-if="$store.state.showSearch")
      a(href="#", :title="$t('close')" @click.prevent="$store.commit('SET_SEARCH_OVERLAY', false)" class="overlay-close") #[AppIcon(name='close')]
      TheSearchOverlay(@close="$store.commit('SET_SEARCH_OVERLAY', false)")

  transition(name="overlay")
    .overlay.overlay-nav(v-if="$store.state.showNav")
      a(href="#", :title="$t('close')" @click.prevent="$store.commit('SET_NAV_OVERLAY', false)" class="overlay-close") #[AppIcon(name='close')]
      TheNavOverlay

</template>

<script>
import { mapGetters } from 'vuex';
import Logo from './Logo';
import TheNavOverlay from './TheNavOverlay';
import TheLoginOverlay from './TheLoginOverlay';
import TheSearchOverlay from './TheSearchOverlay';

export default {
  name: 'TheHeader',
  components: {
    TheNotificationsOverlay: () => import('./TheNotificationsOverlay.vue'),
    Logo,
    TheNavOverlay,
    TheLoginOverlay,
    TheSearchOverlay
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isLoggedIn: 'auth/isLoggedIn',
      unreadNotifications: 'notifications/unreadCount'
    }),
    switchLocaleURL() {
      let localeRoot = null;
      const locale = this.$i18n.locale === 'en' ? 'ar' : 'en';
      if (this.$store.state.alternateLinks[locale]) {
        return this.$store.state.alternateLinks[locale];
      }

      this.$route.matched.some(r => {
        const rootLocale = r.components.default.options.i18nLocaleRoot;
        if (rootLocale) {
          localeRoot = rootLocale;
        }
        return rootLocale;
      });

      if (localeRoot) {
        return locale === 'en' ? `${localeRoot}` : `/${locale}${localeRoot}`;
      }

      return this.switchLocalePath(locale);
    }
  },
  watch: {
    $route() {
      this.$refs.header.style.transform = 'translate3d(0,0,0)';
      this.$store.commit('SET_NAV_OVERLAY', false);
      this.$store.commit('SET_LOGIN_OVERLAY', false);
      this.$store.commit('SET_SEARCH_OVERLAY', false);
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.$onScroll);
  },
  mounted() {
    document.body.classList.add('is-loaded');
    let prevScrollpos = window.pageYOffset;
    if (prevScrollpos >= 100) {
      this.$refs.header.classList.add('is-scroll');
    }

    this.$onScroll = () => {
      if (!this.$refs.header) return;
      let currentScrollPos = window.pageYOffset;

      if (currentScrollPos >= 100) {
        if (prevScrollpos >= currentScrollPos) {
          this.$refs.header.style.transform = 'translate3d(0,0,0)';
        } else {
          this.$refs.header.style.transform = 'translate3d(0,-100%,0)';
        }
        prevScrollpos = currentScrollPos;
        this.$refs.header.classList.add('is-scroll');
      } else {
        this.$refs.header.classList.remove('is-scroll');
      }
    };

    window.addEventListener(
      'scroll',
      () => {
        requestAnimationFrame(this.$onScroll);
      },
      { passive: true }
    );
  },
  methods: {
    loadNotifications() {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(() => {
          this.$store.dispatch('notifications/fetch');
        });

        return;
      }

      this.$store.dispatch('notifications/fetch');
    }
  }
};
</script>

<style lang="stylus">
body
  .app.is-whiteHeader, .app.is-whiteheader
    .header
      color: $clWhite

  .app.is-fixedHeader, .app.is-fixedheader
    .header-after
      display: none

  .app.is-whiteHeader, .app.is-whiteheader
    &.app.is-fixedHeader, &.app.is-fixedheader
      .header.is-scroll
        color: $clBlack

  .app.is-whiteHeader, .app.is-whiteheader
    &.app.is-dark
      .header.is-scroll
        color: $clWhite

  &.is-loaded
    .header
      position: fixed

.header-after
  height: unitRes(140, 160)

.header
  position: absolute
  display: flex
  justify-content: space-between
  align-items: center
  width: 100vw
  padding: 0 $outerMargin 0

  +mq($until: 'tablet')
    padding: 0 $outerMargin-sm 0

  height: unitRes(80, 180)
  font-size: $size-large
  z-index: 4
  top: 0
  right: 0
  left: 0
  transition-timing-function: ease-out
  transition-duration: 0.25s
  transition-property: background-color, padding, transform, -webkit-transform

  &.is-scroll
    background: $clWhite
    padding: unitRes(25, 50) $outerMargin

    +mq($until: 'tablet')
      padding: unitRes(25, 50) $outerMargin-sm

    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px 0px

.header-menu
  list-style-type: none
  margin: 0
  display: flex
  justify-content: center
  align-items: center
  font-size: unitRes(14, 24)

  li
    margin: 0 unitRes(8, 40)
    position: relative

    span
      +mq($until: 'tablet')
        display: none

    &.hide-mobile
      +mq($until: 'mobile')
        display: none

    &:last-child
      margin-right: 0

      span
        +mq($until: 'mobile')
          display: none

  a, .icon
    display: flex
    align-items: center
    margin: 0
    position: relative

  .icon
    font-size: unitRes(14, 24)
    margin-right: 5px

  &-badge
    position: absolute
    top: 0
    left: 0
    font-size: 10px
    background: $clDanger
    color: #fff
    border-radius: 20em
    padding: 3px
    min-width: 19px
    text-align: center

    +mq($until: 'desktop')
      top: -1px
      left: -2px
      font-size: 10px
      min-width: 14px
      padding: 1px

.overlay-enter-active, .overlay-leave-active
  transition: all 160ms

.overlay-enter, .overlay-leave-active
  transition: all 160ms
  opacity: 0
  transform: translate3d(0, -200px, 0) !important

.overlay-body
  position: relative
  z-index: 3

.overlay
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: 9999
  overflow-x: auto
  background: $clWhite
  width: 100vw

  &.overlay-nav
    background: $clBlack

    .overlay-close
      fill: $clWhite
      color: $clWhite

      .icon
        fill: $clWhite
        color: $clWhite

.overlay-close
  position: absolute
  right: 8vw
  top: 4vw
  z-index: 4
  font-size: $size-large
  font-size: unitRes(20, 36)

  +mq($until: 'desktop')
    top: 20px
    right: 20px

html[lang^='ar']
  .header-menu
    .icon
      margin-left: 5px
      margin-right: 0px
</style>

<i18n>
{
  "en": {
    "hi":"Hi",
    "login":"Login",
    "menu":"Menu",
    "notifications":"Notifications",
    "close":"Close",
    "order":"Ordering",
    "search":"Search",
    "explore": "Explore",
    "lang": "العربية"
  },
  "ar": {
    "hi":"اهلا",
    "login":"تسجيل دخول",
    "menu":"القائمة",
    "notifications":"التنبيهات",
    "close":"اغلاق",
    "order":"طلب خدمة",
    "search":"بحث",
    "explore": "اكتشف",
    "lang": "English"
  }
}
</i18n>
