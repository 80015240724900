<template lang="pug">
.overlay-body
  .container
    NavigationMenu
</template>

<style lang="stylus">
.overlay-nav
  color: $clWhite !important
  background: $clBlack

  +mq($until: 'desktop')
    .container
      padding: 0 20px
</style>

<script>
import NavigationMenu from '../NavigationMenu';

export default {
  name: 'TheNavOverlay',
  components: {
    NavigationMenu
  }
};
</script>
