let fetch = process.server ? global.fetch : window.fetch;

// Polyfills the fetch API
if (!fetch && process.server) {
  fetch = require('node-fetch');
}

if (process.browser && !fetch) {
  fetch = require('unfetch');
}

export const fetcher = fetch;
