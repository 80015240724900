<template lang="pug">
.cardSearch
  .cardSearch-header
    a(:href="itemUrl" :title="title" )
      AppImg.cardSearch-thumb(:srcset="img" :alt="title" ratio="1:1")

  .cardSearch-body
    h5.cardSearch-title.u-mb-ts
      AppLink(:to="itemUrl" :title="title" )
        | {{ title }}
    AppLink(:to="typeLink" :title="type") {{ type }}

</template>

<script>
export default {
  name: 'AppCardSearch',
  components: {},
  props: {
    title: {
      type: String,
      required: true
    },
    img: {
      type: String,
      default: '{CDN_URL}/search.jpg'
    },
    url: {
      type: String,
      default: null
    },
    slug: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    publishedIn: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    typeLink() {
      const prefix = `${this.locale === 'ar' ? '/ar' : ''}`;
      if (this.type === 'Work') {
        return `${prefix}/work`;
      }

      if (this.type === 'Open-source') {
        return `${prefix}/opensource`;
      }

      if (this.type === 'Resource') {
        return `${prefix}/resources`;
      }

      if (this.type === 'Book') {
        return `${prefix}/books`;
      }

      if (this.type === 'Career') {
        return `${prefix}/careers`;
      }

      if (this.type === 'Article' && this.publishedIn === 'PRESS') {
        return `${prefix}/press`;
      }

      if (this.type === 'Article' && this.publishedIn === 'CODE') {
        return `${prefix}/academy/code`;
      }

      if (this.type === 'Article' && this.publishedIn === 'DESIGN') {
        return `${prefix}/academy/design`;
      }

      if (this.type === 'Article' && this.publishedIn === 'BIZ') {
        return `${prefix}/academy/business`;
      }

      if (this.type === 'Article' && this.publishedIn === 'TECH') {
        return `${prefix}/academy/technology`;
      }

      if (this.type === 'Article') {
        return `${prefix}/blog`;
      }

      if (this.type === 'Service') {
        return `${prefix}/services`;
      }

      return '#';
    },
    itemUrl() {
      const prefix = `${this.locale === 'ar' ? '/ar' : ''}`;
      if (this.url) {
        return `${prefix}${this.url}`;
      }

      if (this.type === 'Work') {
        return `${prefix}/work/${this.slug}`;
      }

      if (this.type === 'Open-source') {
        return `${prefix}/opensource/${this.slug}`;
      }

      if (this.type === 'Resource') {
        return `${prefix}/resources/${this.slug}`;
      }

      if (this.type === 'Book') {
        return `${prefix}/books/${this.slug}`;
      }

      if (this.type === 'Career') {
        return `${prefix}/careers/${this.slug}`;
      }

      if (this.type === 'Article' && this.publishedIn === 'PRESS') {
        return `${prefix}/press/${this.slug}`;
      }

      if (this.type === 'Article' && this.publishedIn === 'CODE') {
        return `${prefix}/academy/code/${this.slug}`;
      }

      if (this.type === 'Article' && this.publishedIn === 'DESIGN') {
        return `${prefix}/academy/design/${this.slug}`;
      }

      if (this.type === 'Article' && this.publishedIn === 'BIZ') {
        return `${prefix}/academy/business/${this.slug}`;
      }

      if (this.type === 'Article' && this.publishedIn === 'TECH') {
        return `${prefix}/academy/technology/${this.slug}`;
      }

      if (this.type === 'Article') {
        return `${prefix}/blog/${this.slug}`;
      }

      return '#';
    }
  }
};
</script>

<style lang="stylus">
.cardSearch
  display: flex
  align-items: center

.cardSearch-header
  margin-right: unitRes(20, 80)
  width: unitRes(80, 160)
  flex: 0 0 unitRes(80, 160)

.cardSearch-title
  margin: 0

.cardSearch-tags
  margin-top: unitRes(10, 20)

  a:not(:last-child)
    &:after
      content: ','
      margin-right: 10px

.cardSearch-thumb
  width: 100%
  height: auto
  display: block
</style>
