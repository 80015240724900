<template lang="pug">
  .grid.is-ultrawide-5.is-widescreen-6.is-desktop-7.is-tablet-8.is-center
    .column(data-cy="signin-methods")
      AppTitle.u-mb-sm(:title="$t('welcome') " tag="h3")
      //- p.u-text-lg.u-mb-sm {{ $t('accountPerks') }}
      ValidationObserver(v-slot="{ validate }" ref="form")
        form(@submit.prevent="validate().then(waitForCode ? confirmSMS : onSubmit)")

          TextInput(
            v-if="!waitForCode"
            :name="$t('v-email')"
            vid="email"
            :autofocus="true"
            type="text"
            :label="$t('email')"
            v-model="email"
            :rules="phone ? 'email' : 'required|email'"
          )

          //- h4.login-or( v-if="!waitForCode"): span {{$t('or')}}
          //- PhoneInput(
          //-   v-if="!waitForCode"
          //-   :name="$t('v-phone')"
          //-   :autofocus="true"
          //-   vid="phone"
          //-   type="text"
          //-   :label="$t('phone')"
          //-   v-model="phone"
          //-   :rules="email ? '' : 'required'"
          //- )

          //- TextInput(
          //-   v-if="waitForCode"
          //-   name="code"
          //-   :autofocus="true"
          //-   type="text"
          //-   :label="$t('smsCode')"
          //-   v-model="code"
          //-   rules="required|numeric|length:6"
          //- )
          .u-mb-ts
            recaptcha(@error="onError" @success="onSuccess" @expired="onExpired")

          button.button-link.is-medium(v-load="isSubmitting") {{ $t('forms.signin') }}

      //- p.u-mt-sm(v-if="!waitForCode") {{ $t('hasCode') }} #[a(href="#" @click.prevent="waitForCode = true" :title="$t('useCode')" class="u-super") {{ $t('useCode') }}]
      //- p.u-mt-sm(v-else)  {{ $t('smsAgain') }} #[a(href="#" @click.prevent="waitForCode = false" :title="$t('sendSms')" class="u-super") {{ $t('sendSms') }}]
      p.u-mt-xs  {{ $t('forms.no') }} #[AppLink(to="/register" :title="$t('forms.signup')" class="u-super") {{ $t('forms.signup') }}]

</template>

<script>
import { ValidationObserver } from 'vee-validate/dist/vee-validate.minimal';
import { load } from '~/directives';
import TextInput from '~/components/inputs/TextInput';
import PhoneInput from '~/components/inputs/PhoneInput';
import { RequestEmailLoginToken, RequestSmsLoginToken, LoginWithSms } from '~/graphql/Auth.graphql';

const Cookies = process.client ? require('js-cookie') : undefined;

export default {
  name: 'LoginForm',
  directives: {
    load
  },
  components: {
    TextInput,
    PhoneInput,
    ValidationObserver
  },
  data: () => ({
    isSubmitting: false,
    email: '',
    phone: '',
    input: '',
    waitForCode: false,
    code: '',
    recaptchaValid: false
  }),
  async mounted() {
    const prefix = this.$i18n.locale === 'en' ? '' : '/ar';
    window.history.replaceState(null, 'Menu', `${prefix}/login`);
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  },
  beforeDestroy() {
    window.history.replaceState(null, '', this.$route.path);
  },
  watch: {
    waitForCode() {
      requestAnimationFrame(() => {
        this.$refs.form.reset();
      });
    }
  },
  methods: {
    async usePhone() {
      const { data } = await this.$api.mutate({
        mutation: RequestSmsLoginToken,
        variables: {
          input: {
            phone: this.phone,
            app: 'BAIANAT_WEB'
          }
        }
      });

      const payload = data.requestSmsLoginToken;
      if (!payload || !payload.success) {
        return false;
      }

      this.$success(this.$t('successPhone'));
      this.waitForCode = true;

      return true;
    },
    async useEmail() {
      const { data } = await this.$api.mutate({
        mutation: RequestEmailLoginToken,
        variables: {
          input: {
            email: this.email,
            callbackUrl: `${this.$store.state.env.APP_URL}/auth/email/callback`
          }
        }
      });

      const payload = data.requestEmailLoginToken;
      if (payload && payload.success) {
        this.$success(this.$t('successEmail'));
      }

      return payload.success;
    },
    async onSubmit() {
      let triedEmail = false;
      const isPhoneValid = this.phone && this.$refs.form.refs.phone.isValid;
      try {
        if (!this.recaptchaValid) {
          this.$error(this.$t('forms.recaptchaRequired'));
          return;
        }
        this.isSubmitting = true;
        if (this.email && this.$refs.form.refs.email.isValid) {
          triedEmail = true;
          if (await this.useEmail()) {
            return;
          }
        }

        if (isPhoneValid) {
          await this.usePhone();
        }
      } catch (err) {
        console.log(err, triedEmail, isPhoneValid);
        if (triedEmail && isPhoneValid) {
          try {
            await this.usePhone();
          } catch (other) {
            console.error(err, other);
            this.$error('Incorrect credentials');
            return;
          }
        }
      } finally {
        this.isSubmitting = false;
        await this.$recaptcha.reset();
        this.recaptchaValid = false;
      }
    },
    async confirmSMS() {
      try {
        this.isSubmitting = true;
        const { data } = await this.$api.mutate({
          mutation: LoginWithSms,
          variables: {
            input: {
              phone: this.phone,
              token: this.code
            }
          }
        });

        this.$store.commit('auth/SET_USER', data.loginWithSms.user);
        this.$store.commit('auth/SET_TOKEN', data.loginWithSms.token);
        Cookies.set('auth', data.loginWithSms.token, {
          expires: 7,
          path: '/'
        });
        this.$store.commit('auth/SET_PERMISSIONS', data.loginWithSms.user.permissions);
        this.$store.commit('auth/SET_UPDATED', true);
        this.$store.dispatch('notifications/fetch');
        this.$ws.connect();
        this.$router.localePush('/cc');
      } catch (err) {
        console.error(err);
        this.$error(err.message);
      } finally {
        this.isSubmitting = false;
      }
    },
    onError() {
      this.recaptchaValid = false;
    },
    onSuccess() {
      this.recaptchaValid = true;
    },
    onExpired() {
      this.recaptchaValid = false;
    }
  }
};
</script>

<style lang="stylus">
.login-or
  width: 100%
  display: block
  text-align: center
  position: relative
  z-index: 2
  margin: 0

  span
    background: $clWhite

  &:after
    content: ''
    height: 4px
    background: $clBlack
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    right: 0
    z-index: -1
</style>

<i18n>
{
  "en": {
    "welcome": "Login",
    "accountPerks": "One account gets you into everything Baianat offers.",
    "email": "Your email address",
    "phone": "Your Phone Number",
    "usePhone": "Want to use your phone number to sign in?",
    "useEmail": "Want to use your email address to sign in?",
    "click": "Click here",
    "successPhone": "You should be getting an SMS message shortly with your login code.",
    "successEmail": "You should be getting an email shortly with your login link.",
    "smsCode": "Login Code",
    "hasCode": "Got a code?",
    "useCode": "Verify",
    "smsAgain": "Didn't get the SMS?",
    "sendSms": "Send again",
    "v-email":"Email",
    "v-phone":"Phone",
    "or":"OR"
  },
  "ar": {
    "welcome": "تسجيل الدخول",
    "accountPerks": "حساب واحد يمنحك كل العروض الخاصة ببيانات.",
    "email": "بريدك الاليكتروني",
    "phone": "رقم هاتفك",
    "usePhone": "هل تود استخدام هاتفك لتسجيل الدخول؟",
    "useEmail": "هل تود استخدام بريدك الاليكتروني لتسجيل الدخول؟",
    "click": "اضغط هنا",
    "successPhone": "ستستلم كود الدخول الخاص بك على رسالة قصيرة خلال لحظات.",
    "successEmail": "سيتم ارسال بريد اليكتروني برابط التفعيل اليك خلال لحظات.",
    "smsCode": "كود الدخول",
    "hasCode": "لديك كود بالفعل؟",
    "useCode": "تحقق",
    "smsAgain": "لم تصلك الرسالة؟",
    "sendSms": "اعادة المحاولة",
    "v-email":"البريد الالكتروني",
    "v-phone":"رقم الهاتف",
    "or":"أو"
  }
}
</i18n>
