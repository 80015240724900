export default async ({ store, redirect, route }) => {
  if (!store.getters['auth/isLoggedIn']) {
    return redirect('/');
  }

  const abilities = route.meta.reduce((abilities, meta) => {
    if (meta.can) {
      abilities.push(meta.can);
    }

    return abilities;
  }, []);

  const redirectTo = route.meta.reduce((path, meta) => meta.cannotRedirect || path, '/');
  await store.dispatch('auth/updatePermissions');
  const userPermissions = store.getters['auth/permissions'];
  const canDoAll = abilities.every(ability => {
    return userPermissions.includes(ability);
  });

  if (!canDoAll) {
    redirect(redirectTo);
  }
};
