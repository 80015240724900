import Vue from 'vue';

function ucfirst(value) {
  if (!value) return '';

  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

Vue.filter('ucfirst', ucfirst);

Vue.filter('ucwords', value => {
  if (!value) return '';

  return value
    .split(/\s|_/)
    .map(ucfirst)
    .join(' ');
});

Vue.filter('limit', (value, limit = 10) => {
  return `${value.substring(0, limit)}...`;
});

Vue.filter('join', value => {
  if (!value) {
    return value;
  }

  return value.join(', ');
});
