export const state = () => ({
  counter: 0,
  messages: []
});

export const mutations = {
  CREATE(state, payload) {
    const message = Object.assign(
      {},
      {
        id: state.counter,
        dismiss: true,
        global: false,
        type: 'info',
        timeout: 4000
      },
      payload
    );

    delete message.dispatcher;
    state.counter++;
    state.messages.push(message);
    if (message.timeout) {
      message.timeout = setTimeout(() => {
        payload.dispatcher('dismiss', message.id);
      }, message.timeout);
    }
  },
  DISMISS(state, id) {
    const idx = state.messages.findIndex(m => m.id === id);
    if (idx === -1) {
      return;
    }

    const message = state.messages[idx];
    if (typeof message.onDismiss === 'function') {
      message.onDismiss();
    }

    state.messages.splice(idx, 1);
  },
  CANCEL_TIMEOUT(state, id) {
    const item = state.messages.find(m => m.id === id);
    if (!item || !item.timeout) {
      return;
    }

    clearTimeout(item.timeout);
  }
};

export const getters = {
  messages: state => state.messages.filter(m => !m.global),
  globalAlert: state => {
    const messages = state.messages.filter(m => m.global);

    return messages[messages.length - 1];
  }
};

export const actions = {
  create({ commit, state, dispatch }, options) {
    if (state.messages.length >= 6) {
      dispatch('dismiss', state.messages[0]);
    }

    options.dispatcher = dispatch;
    commit('CREATE', options);
  },
  dismiss({ commit }, id) {
    commit('DISMISS', id);
  },
  cancelTimeout({ commit }, id) {
    commit('CANCEL_TIMEOUT', id);
  },
  success({ dispatch }, message) {
    return dispatch('create', { message, type: 'success' });
  },
  error({ dispatch }, message) {
    return dispatch('create', { message, type: 'error', timeout: false });
  },
  info({ dispatch }, message) {
    return dispatch('create', { message, type: 'info' });
  },
  warn({ dispatch }, message) {
    return dispatch('create', { message, type: 'warning' });
  }
};
