<template lang="pug">
.appLogo(:class="{'is-back': canBack }")
  a(@click.prevent="backTo" :title="title")
    .appLogo-icon
      AppIcon(v-if="$store.state.logo.icon !== '__NONE__'" :name="$store.state.logo.icon" :useI18n="true").appLogo-icon-main
      AppIcon(v-if="path > 1" name="arrowR2")
    .appLogo-typo(v-if="$store.state.logo.text !== '__NONE__'")
      template(v-if="iconExist")
        AppIcon(:name="$store.state.logo.text" :useI18n="true")
      template(v-else)
        span {{$t($store.state.logo.text)}}

</template>

<script>
import icons from '~/assets/data/icons.json';

export default {
  name: 'Logo',
  data() {
    return {
      path: this.$route.path.length,
      pathName: this.$route.path,
      icons: icons
    };
  },
  computed: {
    title() {
      const matchedRoute = this.$route.matched[0];
      if (!matchedRoute || !matchedRoute.instances.default || !matchedRoute.instances.default.$metaInfo) {
        return this.$t('baianat');
      }

      return matchedRoute.instances.default.$metaInfo.title || this.$t('baianat');
    },
    canBack() {
      return !/^\/?(ar\/?)?$/i.test(this.$route.path);
    },
    iconExist() {
      if (this.$store.state.logo.text in icons) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    $route() {
      this.path = this.$route.path.length;
      this.pathName = this.$route.path;
    }
  },
  methods: {
    stripTrailingSlash(str) {
      if (str.length == 1) {
        return str;
      }
      return str.endsWith('/') ? str.slice(0, -1) : str;
    },
    backTo() {
      let currentSlug = this.$route.path;
      let lastSlug = this.stripTrailingSlash(currentSlug)
        .split('/')
        .pop();
      let parentSlug = this.$route.path.replace(lastSlug, '');

      let resolved = this.$router.resolve(this.stripTrailingSlash(parentSlug));
      if (lastSlug === 'ar') {
        if (window.pageYOffset >= 100) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      } else {
        if (resolved.route.name != null) {
          this.$router.push(this.stripTrailingSlash(parentSlug));
          if (currentSlug === '/' && window.pageYOffset >= 100) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        } else {
          let grandParent = this.stripTrailingSlash(parentSlug)
            .split('/')
            .pop();
          let grandParentSlug = this.stripTrailingSlash(parentSlug.replace(grandParent, ''));
          this.$router.push(this.stripTrailingSlash(grandParentSlug));
        }
      }
    }
  }
};
</script>

<style lang="stylus">
.appLogo
  position: relative
  overflow: hidden

  >a
    display: flex
    align-items: center
    transition: 0

  &-typo
    position: relative
    max-width: 100%
    transition: max-width 200ms
    overflow: hidden
    font-weight: $fontWeight-bold

    >span
      display: block

  &-icon
    position: relative
    overflow: hidden

  &.is-hidden
    .appLogo-typo
      max-width: 0%

  .icon
    display: block

  .icon-arrowR2
    height: unitRes(30, 50)
    width: unitRes(30, 50)
    position: absolute
    top: 0
    left: 0
    opacity: 0
    transform: translateX(300px) rotate(180deg)
    transition: 250ms ease-in-out
    transition-property: opacity transform

  &.is-back
    &:hover
      .icon-logoIcon, .appLogo-icon-main
        transform: translateX(-200px)

      .icon-arrowR2
        opacity: 1
        transform: rotate(180deg)

  .appLogo-icon-main
    transition: transform 250ms ease-in-out
    margin-right: unitRes(10, 20)

  .icon-logo
    width: unitRes(120, 240)
    height: unitRes(30, 57)

  .icon-logoIcon
    width: unitRes(35, 58)
    height: unitRes(30, 50)

  .icon-logoText
    width: unitRes(78, 156)
    height: unitRes(15, 30)

  .icon-logoBlog
    width: unitRes(34, 68)
    height: unitRes(15, 30)

  .icon-logoAccounts
    width: unitRes(89, 179)
    height: unitRes(15, 30)

  .icon-logoBooks
    width: unitRes(56, 112)
    height: unitRes(15, 30)

  .icon-logoHighlights
    width: unitRes(83, 166)
    height: unitRes(15, 30)

  .icon-logoBase
    width: unitRes(45.5, 91)
    height: unitRes(15, 30)

  .icon-logoBusiness
    width: unitRes(85, 170)
    height: unitRes(15, 30)

  .icon-logoLegal
    width: unitRes(42, 84)
    height: unitRes(15, 30)

  .icon-logoVentures
    width: unitRes(89, 177)
    height: unitRes(15, 30)

  .icon-logoWorks
    width: unitRes(52, 105)
    height: unitRes(15, 30)

  .icon-logoAtlas
    width: unitRes(53, 105)
    height: unitRes(15, 30)

  .icon-logoEvents
    width: unitRes(67, 133)
    height: unitRes(15, 30)

  .icon-logoFonts
    width: unitRes(55, 110)
    height: unitRes(15, 30)

  .icon-logoPress
    width: unitRes(55, 109)
    height: unitRes(15, 30)

  .icon-logoYears
    width: unitRes(55, 110)
    height: unitRes(15, 30)

  .icon-logoVerteTypo
    width: unitRes(55, 105)
    height: unitRes(15, 30)

  .icon-logoVerteIcon
    width: unitRes(28, 58)
    height: unitRes(30, 50)

  .icon-logoLeapsTypo
    width: unitRes(42, 82)
    height: unitRes(15, 30)
    margin-top: 5px

  .icon-logoLeapsIcon
    width: unitRes(35, 44)
    height: unitRes(30, 50)

  .icon-logoHooperTypo
    width: unitRes(100, 170)
    height: unitRes(30, 50)

  .icon-logoSeedersTypo
    width: unitRes(100, 170)
    height: unitRes(30, 50)

  .icon-logoAcademyTypo
    width: unitRes(130, 190)
    height: unitRes(30, 50)

  .icon-logoAlignTypo
    width: unitRes(65, 110)
    height: unitRes(30, 50)

  .icon-logoVuseTypo
    width: unitRes(42, 82)
    height: unitRes(15, 30)

  .icon-logoDiskIcon
    width: unitRes(24, 40)
    height: unitRes(30, 50)

  .icon-logoDiskTypo
    width: unitRes(42, 82)
    height: unitRes(15, 30)

  .icon-logoTuggerIcon
    width: unitRes(38, 60)
    height: unitRes(30, 50)

  .icon-logoTuggerTypo
    width: unitRes(57, 110)
    height: unitRes(15, 30)

  .icon-logoVeeIcon
    width: unitRes(37, 60)
    height: unitRes(30, 50)

  .icon-logoVeeTypo
    width: unitRes(58, 118)
    height: unitRes(20, 40)

  .icon-logoBoardIcon
    width: unitRes(25, 40)
    height: unitRes(30, 50)

  .icon-logoBoardTypo
    width: unitRes(58, 118)
    height: unitRes(20, 40)

  .icon-logoBaseIcon
    width: unitRes(31, 47)
    height: unitRes(30, 50)

  .icon-logoBaseTypo
    width: unitRes(46, 90)
    height: unitRes(15, 30)

  .icon-logoWaveIcon
    width: unitRes(31, 47)
    height: unitRes(30, 50)

  .icon-logoWaveTypo
    width: unitRes(58, 118)
    height: unitRes(15, 30)

  .icon-logoLabs
    width: unitRes(41, 67)
    height: unitRes(15, 24)

  .icon-logoBusiness
    width: unitRes(85, 136)
    height: unitRes(15, 24)

  .icon-logoCode
    width: unitRes(44, 70)
    height: unitRes(15, 24)

  .icon-logoDesign
    width: unitRes(50, 83)
    height: unitRes(15, 24)
    margin-top: 4px

  .icon-logoTechnology
    width: unitRes(90, 140)
    height: unitRes(15, 24)
    margin-top: 4px

  .icon-logoPalettaIcon
    width: unitRes(24, 40)
    height: unitRes(30, 50)

  .icon-logoPalettaTypo
    width: unitRes(70, 140)
    height: unitRes(15, 30)

  .icon-logoPosteraTypo
    width: unitRes(120, 200)
    height: unitRes(30, 44)

  .icon-logoZoneIcon
    width: unitRes(30, 50)
    height: unitRes(30, 50)

  .icon-logoZoneTypo
    width: unitRes(46, 90)
    height: unitRes(15, 30)
    margin-top: 10px

  .icon-logoPatternestTypo
    width: unitRes(120, 200)
    height: unitRes(30, 45)

  .icon-logoPediaTypo
    width: unitRes(120, 200)
    height: unitRes(30, 45)

  .icon-logoRatioTypo
    width: unitRes(120, 200)
    height: unitRes(30, 45)

  .icon-logoModelerTypo
    width: unitRes(120, 200)
    height: unitRes(30, 45)

  .icon-logoQuotesIcon
    width: unitRes(28, 47)
    height: unitRes(30, 50)

  .icon-logoQuotesTypo
    width: unitRes(46, 90)
    height: unitRes(15, 30)

  .icon-logoCheckIcon
    width: unitRes(26, 44)
    height: unitRes(30, 50)

  .icon-logoCheckTypo
    width: unitRes(85, 165)
    height: unitRes(15, 30)

  .icon-logoGridderIcon
    width: unitRes(30, 50)
    height: unitRes(30, 50)

  .icon-logoGridderTypo
    width: unitRes(75, 150)
    height: unitRes(15, 30)
</style>

<i18n>
{
  "en": {
    "baianat": "Baianat",
    "blog":"Blog",
    "ventures":"Ventures",
    "books":"Books",
    "base":"Base",
    "atlas":"Atlas",
    "labs":"Labs",
    "Business":"Business",
    "Design":"Design",
    "Code":"Code",
    "development":"Development",
    "Technology":"Technology",
    "events":"Events",
    "fonts":"Fonts",
    "typefaces":"Typefaces",
    "moments":"Moments",
    "doodles":"Doodles",
    "press":"Press",
    "legal":"Legal",
    "contact":"Contact",
    "culture":"Culture",
    "work":"Work",
    "wiki":"Wiki",
    "hubs":"Hubs",
    "tools":"Tools",
    "ideas":"Ideas",
    "kits":"Kits",
    "colors":"Colors",
    "careers": "Careers",
    "products":"products",
    "support":"Support",
    "production":"Production",
    "help": "Help",
    "summary": "Summary",
    "models": "Models",
    "highlights":"Highlights",
    "backstage":"Backstage",
    "palettes":"Palettes",
    "wordCounter":"Word Counter",
    "landmarks": "Landmarks",
    "gate":"Gate",
    "projects":"Projects",
    "capabilities":"Capabilities",
    "brands":"Brands",
    "articles":"Articles",
    "ratios":"Ratios",
    "system":"System",
    "work":"Work",
    "care":"Care",
    "knowledgebase":"Knowledge base"
  },
  "ar": {
    "baianat": "بيانات",
    "blog":"المدونة",
    "ventures":"مشاريعنا",
    "books":"الكتب",
    "base":"نظام التصميم",
    "atlas":"أطلس",
    "labs":"المختبرات",
    "Business":"مختبرات البيزنس",
    "Design":"مختبرات التصميم",
    "Code":"مختبرات البرمجة",
    "development":"مختبرات البرمجة",
    "Technology":"مختبرات التكنولوجيا",
    "events":"الفعاليات",
    "fonts":"الخطوط",
    "typefaces":"الخطوط",
    "moments":"اللحظات",
    "doodles":"الخربشات",
    "press":"الصحافة",
    "legal":"القوانين",
    "contact":"التواصل",
    "culture":"الثقافة",
    "work":"أعمالنا",
    "wiki":"الموسوعة",
    "hubs":"المراكز",
    "tools":"الأدوات",
    "ideas":"أفكار",
    "kits":"التجميعات",
    "colors":"الألوان",
    "careers": "الوظائف",
    "products":"المنتجات",
    "support":"الدعم",
    "production":"الانتاج",
    "help": "المساعدة",
    "summary":"الملخص",
    "models": "نماذج",
    "highlights":"مقتطفات",
    "backstage":"الكواليس",
    "palettes":"بالتة",
    "wordCounter":"عداد الكلمات",
    "landmarks": "المعالم",
    "gate":"البوابة",
    "projects":"المشاريع",
    "capabilities":"القدرات",
    "brands":"مشاريعنا",
    "articles":"المقالات",
    "ratios":"النسب",
    "system":"النظام",
    "work":"أعمالنا",
    "care":"الدعم",
    "knowledgebase":"قاعدة المعرفة"
  }
}
</i18n>
