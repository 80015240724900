import { formatDistance, format } from 'date-fns';

export function formatDiff(value) {
  if (!value) return value;

  return `${formatDistance(new Date(Number(value)), Date.now())} ago`;
}

export function formatPretty(value) {
  if (!value) return value;

  return format(new Date(Number(value)), 'LLL, d yyyy');
}
