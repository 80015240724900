import { pick } from '~/utils/common';

export const state = () => ({
  theme: 'light',
  showNotifications: false,
  showNav: false,
  showLogin: false,
  showSearch: false,
  geo: null,
  env: {
    API_URL: null,
    FACEBOOK_CLIENT_ID: null,
    DOMAIN_NAME: 'baianat.com',
    APP_URL: null,
    CDN_URL: null,
    WSS_URL: null
  },
  logo: {
    text: 'logoText',
    icon: 'logoIcon'
  },
  alternateLinks: {
    en: '',
    ar: ''
  },
  swUpdate: false,
  swStatus: ''
});

export const mutations = {
  SET_LOGO(state, logo) {
    state.logo = logo;
  },
  SET_IS_UPDATE(state, value) {
    state.swUpdate = value;
  },
  SET_THEME(state, theme) {
    state.theme = theme;
  },
  SET_ALTERNATE_LOCALE(state, links) {
    state.alternateLinks = Object.assign({}, links);
  },
  SET_NOTIFICATION_OVERLAY(state, value) {
    state.showNotifications = value;
    document.body.classList[value ? 'add' : 'remove']('is-scroll-disabled');
  },
  SET_NAV_OVERLAY(state, value) {
    state.showNav = value;
    document.body.classList[value ? 'add' : 'remove']('is-scroll-disabled');
  },
  SET_ENV_VARIABLES(state, payload) {
    state.env = Object.assign({}, state.env, payload);
  },
  SET_LOGIN_OVERLAY(state, value) {
    state.showLogin = value;
    document.body.classList[value ? 'add' : 'remove']('is-scroll-disabled');
  },
  SET_SEARCH_OVERLAY(state, value) {
    document.body.classList[value ? 'add' : 'remove']('is-scroll-disabled');
    state.showSearch = value;
  },
  SET_GEO_DATA(state, value) {
    state.geo = value;
  }
};

export const getters = {
  theme: state => state.theme,
  env: state => state.env
};

const cookieparser = process.server ? require('cookieparser') : undefined;

function resolveIp(req) {
  try {
    if (req.apiGateway.event) {
      return req.apiGateway.event.requestContext.identity.sourceIp;
    }

    return req.headers['x-forwarded-for'] || req.ip;
  } catch (_) {
    return null;
  }
}

export const actions = {
  fillEnv({ commit, state }) {
    const vars = pick(process.env, Object.keys(state.env));
    commit('SET_ENV_VARIABLES', vars);
  },
  async locateByIp({ commit }, ip) {
    try {
      const { data } = await fetch(`${process.env.GEO_SERVICE_URL}`, {
        method: 'POST',
        body: JSON.stringify({
          query: `
            query LocationForIp ($ip: String!) {
              locationForIp (ip: $ip) {
                country
              }
            }
          `,
          variables: {
            ip
          }
        }),
        headers: {
          'content-type': 'application/json',
          Authorization: `bearer ${process.env.GEO_SERVICE_KEY}`
        }
      }).then(res => res.json());

      if (!data) {
        return;
      }

      commit('SET_GEO_DATA', data.locationForIp);
    } catch (err) {
      console.log(err);
    }
  },
  async nuxtServerInit({ commit, dispatch }, { req }) {
    dispatch('fillEnv');
    const ip = resolveIp(req);
    if (ip) {
      await dispatch('locateByIp', ip);
    }

    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie);
      try {
        if (parsed.unlocked) {
          commit('auth/SET_LOCK_ACCESS', true);
        }

        if (parsed.auth) {
          commit('auth/SET_TOKEN', parsed.auth);
          await dispatch('auth/fetch');
        }
      } catch (err) {
        // No valid cookie found
        console.log(err.message);
      }
    }
  }
};
