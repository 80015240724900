<template lang="pug">
div
  footer.footer
    .footer-col
      ul.Socials
        li #[a(href="https://www.facebook.com/baianat" target="blank" rel="noopener" title="Baianat Facebook") #[AppIcon(name='facebook')]]
        li #[a(href="https://twitter.com/baianat" target="blank" rel="noopener" title="Baianat Twitter") #[AppIcon(name='twitter')]]
        li #[a(href="https://www.behance.net/baianat" target="blank" rel="noopener" title="Baianat Behance") #[AppIcon(name='behance')]]
        li #[a(href="https://dribbble.com/baianat" target="blank" rel="noopener" title="Baianat Dribbble") #[AppIcon(name='dribbble')]]
        li #[a(href="https://www.pinterest.com/baianat/" target="blank" rel="no-opener" title="Baianat Pinterest") #[AppIcon(name='pinterest')]]
        li #[a(href="https://github.com/baianat" target="blank" rel="noopener" title="Baianat Github") #[AppIcon(name='github')]]
        //- li #[a(href="https://vimeo.com/baianat" target="blank" rel="noopener" title="Baianat Viemo") #[AppIcon(name='vimeo')]]
        //- li #[a(href="https://www.linkedin.com/company/baianat" target="blank" rel="noopener" title="Baianat Instagram") #[AppIcon(name='instagram')]]
        //- li #[a(href="https://www.linkedin.com/company/baianat" target="blank" rel="noopener" title="Baianat LinkedIn") #[AppIcon(name='linkedin')]]
      p.footer-rights {{ $t('rights') }} {{currentYear}}
    .footer-col
      ul.footer-nav.u-text-lg
        li #[AppLink(to="/company" :title="$t('about')") {{$t('about')}} ]
        li #[AppLink(to="/explore" :title="$t('explore')") {{$t('explore')}} ]
        li #[AppLink(to="/legal" :title="$t('legal')") {{$t('legal')}} ]
        li #[AppLink(to="/contact" :title="$t('contact')") {{$t('contact')}} ]
        //- li
        //-   a(v-if="$i18n.locale === 'en'" :href="switchLocaleURL", active-class='none')
        //-     svg(xmlns='http://www.w3.org/2000/svg', viewbox='0 0 512 512' class="icon icon-egy")
        //-       circle(cx='256', cy='256', r='256', fill='#f0f0f0')
        //-       path(d='M256 0C145.929 0 52.094 69.472 15.923 166.957h480.155C459.906 69.472 366.071 0 256 0z', fill='#d80027')
        //-       path(d='M256 512c110.071 0 203.906-69.472 240.077-166.957H15.923C52.094 442.528 145.929 512 256 512z', fill='#000')
        //-       path(d='M345.043 228.174H278.26c0-12.294-9.967-22.261-22.261-22.261s-22.261 9.967-22.261 22.261h-66.783c0 12.295 10.709 22.261 23.003 22.261h-.742c0 12.295 9.966 22.261 22.261 22.261 0 12.295 9.966 22.261 22.261 22.261h44.522c12.295 0 22.261-9.966 22.261-22.261 12.295 0 22.261-9.966 22.261-22.261h-.741c12.294 0 23.002-9.966 23.002-22.261z', fill='#ff9811')
        //-     | {{ $t('lang') }}
        //-   a(v-else :href="switchLocaleURL" active-class='none')
        //-     svg(xmlns='http://www.w3.org/2000/svg', viewbox='0 0 512 512' class="icon icon-usa")
        //-       circle(cx='256', cy='256', r='256', fill='#f0f0f0')
        //-       g(fill='#d80027')
        //-         path(d='M244.87 256H512c0-23.106-3.08-45.49-8.819-66.783H244.87V256zM244.87 122.435h229.556a257.35 257.35 0 0 0-59.07-66.783H244.87v66.783zM256 512c60.249 0 115.626-20.824 159.356-55.652H96.644C140.374 491.176 195.751 512 256 512zM37.574 389.565h436.852a254.474 254.474 0 0 0 28.755-66.783H8.819a254.474 254.474 0 0 0 28.755 66.783z')
        //-       path(d='M118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037a257.407 257.407 0 0 0-49.652 55.337h7.475l-13.813 10.035a255.58 255.58 0 0 0-6.194 10.938l6.596 20.301-12.306-8.941a253.567 253.567 0 0 0-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444A258.468 258.468 0 0 0 0 256h256V0c-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422l-21.699-15.765L85.104 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zM220.328 230.4l-21.699-15.765L176.93 230.4l8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765 8.289 25.509zm-8.289-100.083l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm0-74.574l8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765z', fill='#0052b4')
        //-     | {{ $t('lang') }}


  transition(name="overlay")
    .offlineMessage( v-if="$nuxt.isOffline")
      p {{ $t('connection.title') }} {{ count }} {{ $t('connection.seconds') }} #[button.button {{ $t('connection.reconnect') }}]
</template>

<style lang="stylus">
.footer
  border-top: 6px solid $clBlack
  padding: unitRes(60, 120) 0
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  z-index: 2
  position: relative

  +mq($until: 'desktop')
    display: block

  .icon
    margin-right: 10px

.footer-nav
  display: flex
  align-items: center
  list-style-type: none
  margin: 0 unitRes(-10, -40)
  flex-wrap: wrap

  li
    margin: 0 unitRes(10, 40)

  a
    display: flex
    align-items: center

  +mq($until: 'desktop')
    margin-top: unitRes(20, 80)

  +mq($until: 'mobile')
    li
      margin-bottom: unitRes(10, 20)

.Socials
  list-style-type: none
  display: flex
  flex-wrap: wrap
  font-size: unitRes(20, 30)
  margin: 0 0 unitRes(10, 20)

  li
    margin-right: unitRes(10, 20)

.icon-usa, .icon-egy
  width: unitRes(20, 60)
  height: unitRes(20, 60)

.offlineMessage
  position: fixed
  bottom: 0
  left: 0
  z-index: 999
  background: $clBlack
  padding: unitRes(8, 24)
  color: $clWhite
  background: linear-gradient(270deg, #eb008b, #1a3aff, #f42828, #ff9600)
  background-size: 500% 500%
  animation: offline 2s linear infinite
  width: 100%
  text-align: center
  font-size: $size-small

  .button
    background: $clBlack
    border: $clBlack
    color: $clWhite
    height: auto
    padding: unitRes(4, 8) unitRes(8, 16)
    margin: 0 unitRes(8, 16)
    font-size: $size-small

    &:hover
      background: $clPrimary

@keyframes offline
  0%
    background-position: 0% 50%

  50%
    background-position: 100% 50%

  100%
    background-position: 0% 50%
</style>

<script>
import Down from 'down.js';

export default {
  name: 'TheFooter',
  data: () => ({
    isOnline: true,
    count: 8,
    max: 8
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    switchLocaleURL() {
      let localeRoot = null;
      const locale = this.$i18n.locale === 'en' ? 'ar' : 'en';
      if (this.$store.state.alternateLinks[locale]) {
        return this.$store.state.alternateLinks[locale];
      }

      this.$route.matched.some(r => {
        const rootLocale = r.components.default.options.i18nLocaleRoot;
        if (rootLocale) {
          localeRoot = rootLocale;
        }
        return rootLocale;
      });

      if (localeRoot) {
        return locale === 'en' ? `${localeRoot}` : `/${locale}${localeRoot}`;
      }

      return this.switchLocalePath(locale);
    }
  },
  mounted() {
    this.$down = new Down();
    this.isOnline = !this.$down.isDown;
    this.$down.on('online', () => {
      this.isOnline = true;
      if (this.$offlineInterval) {
        clearInterval(this.$offlineInterval);
      }
      this.count = this.max = 8;
    });
    this.$down.on('offline', () => {
      this.isOnline = false;
      if (this.$offlineInterval) {
        clearInterval(this.$offlineInterval);
      }

      this.$offlineInterval = setInterval(() => {
        this.count--;
        if (this.count === 0) {
          this.max += 4;
          this.count = this.max;
        }
      }, 1000);
    });
  }
};
</script>

<i18n>
{
  "en": {
    "about":"Company",
    "legal":"Legal",
    "contact":"Contact",
    "lang": "العربية",
    "explore":"Explore",
    "connection": {
    "title": "Connection lost. Reconnecting in",
    "seconds": "seconds",
    "reconnect": "Reconnect"
    },
    "rights":"All copyrights reserved to Baianat"
  },
  "ar": {
    "about":"الشركة",
    "legal":"القوانين",
    "contact":"اتصل بنا",
    "lang": "English",
    "explore":"اكتشف",
    "connection": {
    "title": "فقد الاتصال. اعادة الاتصال خلال",
    "seconds": "ثواني",
    "reconnect": "اعادة الاتصال"
    },
    "rights":"جميع الحقوق محفوظة لبيانات "
  }
}
</i18n>
