export default {
  name: 'AppTitle',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'h1'
    },
    title: {
      type: String,
      required: true
    }
  },
  render(h, { props, data }) {
    const lines = props.title.split('|');
    const child = [];
    lines.forEach(line => {
      child.push(line, h('br'));
    });
    child.pop();

    return h(props.tag, data, child);
  }
};
