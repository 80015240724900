<template lang="pug" functional>
.cardBox(:class="data.staticClass || data.class || ''" v-bind="data.attrs")
  slot
</template>

<script>
export default {
  name: 'AppCardBox'
};
</script>

<style lang="stylus">
.cardBox
  border: unitRes(4, 8) solid $clBlack
  padding: unitRes(20, 80)
  height: 100%
  position: relative

  &:hover
    border-color: $clPrimary

.cardBox-url
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0

.cardBox-auto
  height: auto
</style>
