<template lang="pug" functional>
section.section(:class="data.staticClass || data.class || ''" v-bind="data.attrs")
  .grid.is-middle(v-if="props.wide")
    .column.is-desktop-4
      .section-img
        AppLink.section-url(v-if="props.url" :to="props.url")
        AppImg(:srcset="props.img" :alt="props.imgAlt" :ratio="props.ratio")
    .column.is-desktop-8
      .section-content
        slot
  .grid.is-desktop-6.is-middle(v-else)
    .column
      .section-img
        AppLink.section-url(:to="props.url" v-if="props.url")
        AppImg(:srcset="props.img" :alt="props.imgAlt" :ratio="props.ratio")
    .column
      .section-content
        slot
</template>

<script>
export default {
  name: 'AppSection',
  props: {
    img: {
      type: [String, Array],
      required: true
    },
    imgAlt: {
      type: String,
      required: true
    },
    wide: {
      type: Boolean,
      default: false
    },
    ratio: {
      type: String,
      default: ''
    },
    url: {
      type: [String, Object],
      default: '',
      required: false
    }
  }
};
</script>

<style lang="stylus">
.section
  position: relative

  &.is-reversed
    .grid
      flex-direction: row-reverse

    .section-content
      padding-left: 0
      padding-right: unitRes(20, 120)

  &-url
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 2

.section-content
  padding-left: unitRes(20, 120)

  h6
    font-weight: $fontWeight-bold

  +mq($until: 'desktop')
    padding: unitRes(40, 80) 0 0 !important

.section
  &-img
    width: 100%
    height: auto
    position: relative

    img, picture
      width: 100%
      height: auto
      display: block

  .AppImg
    transition: transform 0.5s ease-in-out

    >picture, >img
      transition: transform 0.5s ease-in-out

  &:hover
    .AppImg
      transform: scale(0.97)

      &-main
        transform: scale(1.15)
</style>
