<template lang="pug" functional>
.card(:class="data.staticClass || data.class || ''" v-bind="data.attrs" itemscope @click="listeners.click && listeners.click()")
  .card-header(v-if="props.img")
    AppLink(:to="props.url" :title="props.title" itemprop="url")
      AppImg.card-thumb(:srcset="props.img" :boxed="props.boxed" :alt="props.title" :ratio="props.ratio" :autoRatio="props.autoRatio")
    span.card-soon.u-text-lg(v-if="props.soon") {{ parent.$t('soon') }}
  .card-body
    .card-meta.u-text-xs.u-mb-ts(v-if="props.meta") {{ props.meta }}
    .card-tags(v-if="props.tags && props.tags.length")
      a(href="#" v-for="tag in props.tags" :key="tag" @click.prevent="listeners.onTagClick && listeners.onTagClick()") {{ tag }}
    h3.card-title(v-if="props.title" itemprop="name")
      AppLink(:to="props.url" :title="props.title" itemprop="url")
        | {{ props.title }}
    p.card-date.u-mt-ts(v-if="props.date") {{ props.date | formatPretty }}
    p.card-excerpt.u-mt-ts(v-if="props.excerpt") {{ props.excerpt }}
    .u-mt-xs(v-if="props.button")
      AppLink(:to="props.url" :title="props.button" class="button-link") {{ props.button }}

</template>

<script>
import { formatPretty } from '~/utils/date';

export default {
  name: 'AppCard',
  filters: {
    formatPretty
  },
  props: {
    title: {
      type: String,
      default: null
    },
    img: {
      type: [String, Array],
      required: true
    },
    url: {
      type: [String, Object],
      required: true
    },
    date: {
      type: String,
      default: null
    },
    ratio: {
      type: String,
      default: ''
    },
    autoRatio: {
      type: Boolean,
      default: false
    },
    button: {
      type: String,
      default: null
    },
    excerpt: {
      type: String,
      default: null
    },
    meta: {
      type: String,
      default: null
    },
    boxed: {
      type: String,
      default: ''
    },
    soon: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    },
    tagUrl: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="stylus">
.card
  position: relative

  &-body
    padding: unitRes(20, 40)

  &-title
    margin: 0
    font-size: unitRes(24, 56)
    font-weight: $fontWeight-medium

  &-tags
    margin-bottom: unitRes(10, 20)
    text-transform: capitalize

    a:not(:last-child)
      &:after
        content: ','
        margin-right: 10px

  &-header
    position: relative

  &-soon
    position: absolute
    bottom: 0
    left: 0
    background: $clBlack
    color: $clYellow
    padding: 10px 15px
    margin: unitRes(20, 40)
    transform: translate(-15px, 15px)

  &-thumb
    width: 100%
    height: auto
    display: block
    overflow: hidden
    transition: transform 0.4s ease-in-out

    >picture, >img
      transition: transform 0.4s ease-in-out

  &:hover
    .card
      &-thumb
        transform: scale(0.97)

        .AppImg-main
          transform: scale(1.15)

  &.is-work
    text-align: center

  &.is-moment
    .card
      &-title
        font-size: unitRes(24, 40)

  &.is-large
    .card
      &-body
        padding: unitRes(20, 60)

      &-title
        font-size: unitRes(24, 56)

  &.is-small
    .card
      &-title
        font-size: unitRes(16, 32)

  &.is-over
    .card
      &-body
        position: absolute
        bottom: 0
        left: 0
        right: 0
        color: $clWhite
        padding: unitRes(20, 40)

      &-excerpt
        max-width: 460px

      &-title
        a:hover
          color: $clWhite
</style>

<i18n>
{
  "en": {
    "soon":"Coming Soon!"

  },
  "ar": {
    "soon":"قريباً!"
    }
}
</i18n>
