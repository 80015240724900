// Content-APP helpers that provide redirection context or theme changes

export default ({ app, store, redirect }) => {
  app.verifyContentLocale = content => {
    const currentLocale = app.i18n.locale;
    const route = app.context.route;
    if (content.locales.indexOf(currentLocale) === -1) {
      const locale = currentLocale === 'en' ? 'ar' : 'en';
      const parts = route.fullPath.split('/');
      if (locale === 'en') {
        parts.splice(1, 1);
      } else {
        parts.splice(1, 0, locale);
      }

      redirect(parts.join('/'));
    }

    if (content.locales.length > 1) {
      const basePath = currentLocale === 'en' ? route.fullPath : route.fullPath.replace(`${currentLocale}/`, '');
      store.commit(
        'SET_ALTERNATE_LOCALE',
        content.locales.reduce((acc, locale) => {
          const prefix = locale === 'en' ? '' : `/${locale}`;
          acc[locale] = `${prefix}${basePath}`;

          return acc;
        }, {})
      );
    }
  };

  app.applyContentTheme = content => {
    const theme = content.styles && (content.styles.map(s => s.class).join(' ') || '');

    if (theme) {
      store.commit('SET_THEME', theme || 'light');
    }
  };
};
