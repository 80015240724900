import Router from 'vue-router';

function localizePath(path, locale) {
  let localizedPath = path;
  if (locale !== 'en') {
    localizedPath = `/${locale}${path}`;
  }

  return localizedPath;
}

Router.prototype.localePush = function(path) {
  const locale = this.app.$i18n.locale;

  this.push(localizePath(path, locale));
};

Router.prototype.localeReplace = function(path) {
  const locale = this.app.$i18n.locale;

  this.replace(localizePath(path, locale));
};
