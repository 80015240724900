<template lang="pug">
.AppImg(:class="{ 'is-done': placeholderHidden , [`is-ratio-${ratio.replace(/:/g,'-')}`]: ratio, 'is-boxed': boxed }" :style="`background:${boxed}`" ref="mainThumbWrapper")
  transition(name="lazy" @after-enter="placeholderHidden = true")
    picture.u-img.AppImg-main(v-if="loaded && !failed" :class="$attrs.class")
      source(v-for="source in sources" :key="source.srcset" :media="source.media" :srcset="source.srcset" :type="source.type")
      img(:src="fallbackSrc" :srcset="fallbackSrc" :alt="alt"  ref="mainThumb")

  img(:src="placeholderSrc" :alt="alt" class="AppImg-placeholder")
</template>

<script>
let OBSERVER;

const sizesToScreens = {
  ultra: '1500',
  desktop: '990',
  tablet: '600',
  mobile: '200'
};

const placeholderSrc = (width, height) => {
  return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
};

let SUPPORTS_WEBP = undefined;

export default {
  name: 'AppImg',
  inheritAttrs: false,
  props: {
    srcset: {
      type: [Array, String],
      required: true
    },
    alt: {
      type: String,
      default: null
    },
    ratio: {
      type: String,
      default: ''
    },
    boxed: {
      type: String,
      default: ''
    },
    autoRatio: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loaded: false,
    failed: false,
    mounted: false,
    placeholderHidden: false
  }),
  computed: {
    placeholderSrc() {
      let ratio = this.ratio || '5:4';

      const [width, height] = ratio.split(':').map(Number);

      return placeholderSrc(width, height);
    },
    fallbackSrc() {
      if (typeof this.srcset === 'string') {
        return this.replaceCDN(this.srcset);
      }
      // the fallback should be a widely supported format.
      const jpg = this.srcset.find(image => image.type === 'image/jpg');
      if (jpg) {
        return this.replaceCDN(jpg.srcset);
      }

      // maybe its a png?
      const png = this.srcset.find(image => image.type === 'image/png');
      if (png) {
        return this.replaceCDN(png.srcset);
      }

      // return transparent placeholder if no jpgs found.

      return this.placeholderSrc;
    },
    isWebP() {
      if (Array.isArray(this.srcset)) {
        return !!this.srcset.find(src => src.type.includes('webp'));
      }

      return this.srcset.includes('.webp');
    },
    sources() {
      if (typeof this.srcset === 'string') {
        return [{ srcset: this.replaceCDN(this.srcset), type: 'image/jpg' }];
      }

      return this.srcset.map(source => {
        let media = source.media;
        if (source.isDesktop) {
          media = `(min-width: ${sizesToScreens.desktop}px)`;
        }

        if (source.isMobile) {
          media = `(min-width: ${sizesToScreens.mobile}px)`;
        }

        if (source.isUltra) {
          media = `(min-width: ${sizesToScreens.ultra}px)`;
        }

        if (source.isTablet) {
          media = `(min-width: ${sizesToScreens.tablet}px)`;
        }

        return {
          ...source,
          srcset: this.replaceCDN(source.srcset),
          media
        };
      });
    }
  },
  mounted() {
    this.mounted = true;
    this.$el._loadImage = () => {
      requestAnimationFrame(() => {
        this.loadImage();
      });
    };
    if (!('IntersectionObserver' in window)) {
      this.loadImage();
      return;
    }

    if (!OBSERVER) {
      // listen for the event to load the image.
      OBSERVER = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio <= 0) return;

            OBSERVER.unobserve(entry.target);
            entry.target._loadImage();
          });
        },
        { root: null, rootMargin: '0px 0px 200px 0px' }
      );

      // start observing this image.
    }
    OBSERVER.observe(this.$el);
  },
  methods: {
    replaceCDN(str) {
      return str.replace(/{CDN_URL}/g, this.$store.state.env.CDN_URL);
    },
    checkWebpSupport() {
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAQAAAAfQ//73v/+BiOh/AAA=';
      });
    },
    async loadImage() {
      if (SUPPORTS_WEBP === undefined) {
        // eslint-disable-next-line
        SUPPORTS_WEBP = await this.checkWebpSupport();
      }

      // use webp when sources has webp and when its supported.
      const useWebp = SUPPORTS_WEBP && this.isWebP;

      const img = new Image();
      if (Array.isArray(this.sources)) {
        img.srcset = this.sources.find(source => {
          const matchesType = useWebp ? source.type.includes('webp') : /jpg|png|gif/.test(source.type);
          const matchesMedia = source.media ? window.matchMedia(source.media).matches : true;

          return matchesMedia && matchesType;
        }).srcset;
      } else {
        img.srcset = this.replaceCDN(this.srcset);
      }

      img.onload = () => {
        this.loaded = true;
        this.applyPadding(img);
      };
      img.onerror = () => {
        this.loaded = true;
        this.failed = true;
      };
    },
    applyPadding({ naturalWidth, naturalHeight }) {
      if (this.autoRatio) {
        this.$refs.mainThumbWrapper.style.paddingTop = (naturalHeight / naturalWidth) * 100 + '%';
      }
    }
  }
};
</script>

<style lang="stylus">
// $ratios ?= '1-1' '2-1' '3:1' '3:2' '4:5' '5:2' '5:3' '5:4' '16:9'
.AppImg
  position: relative
  overflow: hidden

  &[class*='is-ratio']
    padding-top: aspectRatio(1, 1)

    .AppImg
      &-main
        object-fit: cover
        object-position: center
        width: 100%
        height: 100%

        img
          object-fit: cover
          object-position: center
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%

      &-placeholder
        position: absolute
        right: 0
        top: 0
        bottom: 0
        left: 0

  &.is-ratio-2-1
    padding-top: aspectRatio(2, 1)

  &.is-ratio-3-1
    padding-top: aspectRatio(3, 1)

  &.is-ratio-3-2
    padding-top: aspectRatio(3, 2)

  &.is-ratio-3-4
    padding-top: aspectRatio(3, 4)

  &.is-ratio-4-5
    padding-top: aspectRatio(4, 5)

  &.is-ratio-5-2
    padding-top: aspectRatio(5, 2)

  &.is-ratio-5-3
    padding-top: aspectRatio(5, 3)

  &.is-ratio-5-4
    padding-top: aspectRatio(5, 4)

  &.is-ratio-7-5
    padding-top: aspectRatio(7, 5)

  &.is-ratio-16-9
    padding-top: aspectRatio(16, 9)

  &-main
    position: absolute
    right: 0
    top: 0
    bottom: 0
    left: 0
    z-index: 0

  &-placeholder
    background-color: #f6f7f8
    display: block
    margin: 0
    width: 100%
    height: 100%
    transition: 500ms ease-in-out
    transform-origin: right
    animation-duration: 1s
    animation-fill-mode: forwards
    animation-iteration-count: infinite
    animation-name: placeHolderShimmer
    animation-timing-function: linear
    background: #f6f7f8
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)
    background-size: 1000px 1000px
    position: relative

  &.is-done
    .AppImg
      // &-main
      // position: static
      &-placeholder
        transform: scaleX(0)

  &.is-boxed
    background: $clPrimary

    .AppImg
      &-main
        border: unitRes(20, 80) solid transparent
        max-width: 75%
        height: auto
        margin: 0 auto
        overflow: hidden

        +mq($until: 'tablet')
          max-width: 100%

    &:not([class*='is-ratio'])
      .AppImg
        &-main
          position: static

        &-placeholder
          position: absolute
          right: 0
          top: 0
          bottom: 0
          left: 0

.lazy-enter-active, .lazy-leave-active
  transition: opacity 0.5s

.lazy-enter, .lazy-leave-active
  opacity: 0

@keyframes placeHolderShimmer
  0%
    background-position: -468px 0

  100%
    background-position: 468px 0

.lazy-enter-active, .lazy-leave-active
  transition: opacity 0.5s

.lazy-enter, .lazy-leave-active
  opacity: 0
</style>
