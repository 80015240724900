<template lang="pug">
ValidationProvider(:rules="rules" :name="name || label" ref="provider" v-slot="{ errors }" tag="div" :vid="vid").form

  label.form-label.u-mt-ts(v-if="label") {{ label }}
  label.form-label.u-mt-ts(v-else) {{ $t('phoneNumber') }}
  .input-group.phoneInput
    Multiselect(
      v-model="country"
      @select="$refs.input.focus()"
      :options="countries"
      :showLabels	="false"
      :custom-label="c => `${c.name} (+${c.dialCode})`"
      track-by="iso2",
      :placeholder="$t('placeholder')"
    )
      template(#singleLabel="{ option }")
        span(:class="`flag-icon flag-icon-${option.iso2.toLowerCase()}`")
        span(style="margin-left: 5px; margin-right: 5px;") (+{{ option.dialCode }})
      template(#option="{ option }")
        .phone-flag
          span(:class="`flag-icon flag-icon-${option.iso2.toLowerCase()}`")
          span(style="margin-left: 5px; margin-right: 5px; font-size:18px") {{ option.name }}
    input.input(@keydown="onInput" @input="phone.number = $event.target.value" :value="phone.number" :class="{ 'has-error': !!errors[0] }" ref="input" type="tel")
  transition(name="validation")
    p(v-if="errors[0]").form-error {{ errors[0] }}
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.minimal';
import countries from '~/assets/data/countries';

export default {
  name: 'PhoneInput',
  components: {
    Multiselect,
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    countries,
    country: '',
    intereactedWith: {
      country: false,
      phoneInput: false
    },
    phone: {
      number: '',
      dialCode: '',
      country: ''
    }
  }),
  watch: {
    value(val) {
      if (val !== this.stringifyValue(this.phone)) {
        this.phone = this.parseValue(val);
      }
    },
    phone: {
      deep: true,
      handler(val) {
        if (val.number) {
          this.intereactedWith.phoneInput = true;
        }
        if (!this.intereactedWith.phoneInput || !this.intereactedWith.country) {
          return;
        }

        const value = this.stringifyValue(val);
        this.$refs.provider.validate(value);
        this.$emit('input', value);
      }
    },
    country(val) {
      this.intereactedWith.country = true;
      this.phone.dialCode = (val && val.dialCode) || '';
    }
  },
  created() {
    if (this.$store.state.geo) {
      this.country = this.countries.find(c => this.$store.state.geo.country === c.iso2);
    }

    if (!this.value) {
      return;
    }

    this.phone = this.parseValue(this.value);
    this.country = this.countries.find(c => this.phone.country === c.iso2);
  },
  methods: {
    onInput(e) {
      const code = e.which || e.keyCode;
      if (
        (code >= 48 && code <= 57) ||
        (code >= 37 && code <= 40) ||
        (code >= 96 && code <= 105) ||
        code === 8 ||
        code === 46 ||
        (code === 65 && (e.ctrlKey || e.metaKey))
      ) {
        return;
      }

      e.preventDefault();
    },
    parseValue(value) {
      const country = this.countries.find(c => value.startsWith(`+${c.dialCode}`));
      if (!country) {
        return { number: '', dialCode: '', country: '' };
      }

      return {
        number: value.replace(`+${country.dialCode}`, ''),
        country: country.iso2,
        dialCode: country.dialCode
      };
    },
    stringifyValue(value) {
      if (!value.dialCode || !value.number) {
        return '';
      }

      return `+${value.dialCode}${value.number}`;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "phoneNumber": "Phone Number",
    "placeholder":"Select country"
  },
  "ar": {
    "phoneNumber": "رقم الهاتف",
    "placeholder":"اختر البلد"
  }
}
</i18n>

<style lang="stylus" scoped>
.phoneInput
  display: flex

  .multiselect
    max-width: 270px
    margin-bottom: 0

  .input
    border-left: 0

html[lang^='ar']
  .phoneInput
    .multiselect
      order: 2

.phone-flag
  display: flex
  align-items: center
</style>
