var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"grid is-widescreen-8 is-desktop-10 is-center"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"mainSearch u-mh-md"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"mainSearch-input",attrs:{"type":"search","placeholder":_vm.$t('placeholder'),"autofocus":""},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}})])])]),_c('div',{staticClass:"grid is-widescreen-6 is-desktop-8 is-center"},[_c('div',{staticClass:"column"},[(_vm.results.articles.totalCount)?_c('div',{staticClass:"mainSearch-results"},_vm._l((_vm.results.articles.edges),function(ref){
var node = ref.node;
return _c('SearchResultItemCard',{key:node.id,staticClass:"u-mb-sm",attrs:{"locale":_vm.results.locale,"img":node.thumb,"title":node.title,"slug":node.slug,"url":node.url,"type":"Article","publishedIn":node.publishedIn}})}),1):_vm._e(),(_vm.results.books.totalCount)?_c('div',{staticClass:"mainSearch-results"},_vm._l((_vm.results.books.edges),function(ref){
var node = ref.node;
return _c('SearchResultItemCard',{key:node.id,staticClass:"u-mb-sm",attrs:{"locale":_vm.results.locale,"img":node.thumb,"title":node.title,"slug":node.slug,"url":node.url,"type":"Book","publishedIn":node.publishedIn}})}),1):_vm._e(),(_vm.results.works.totalCount)?_c('div',{staticClass:"mainSearch-results"},_vm._l((_vm.results.works.edges),function(ref){
var node = ref.node;
return _c('SearchResultItemCard',{key:node.id,staticClass:"u-mb-sm",attrs:{"locale":_vm.results.locale,"img":node.thumb,"title":node.title,"slug":node.slug,"url":node.url,"type":"Work"}})}),1):_vm._e(),(_vm.results.resources.totalCount)?_c('div',{staticClass:"mainSearch-results"},_vm._l((_vm.results.resources.edges),function(ref){
var node = ref.node;
return _c('SearchResultItemCard',{key:node.id,staticClass:"u-mb-sm",attrs:{"locale":_vm.results.locale,"img":node.thumb,"title":node.title,"slug":node.slug,"url":node.url,"type":node.type === 'OPEN_SOURCE' ? 'Open-source' : 'Resource'}})}),1):_vm._e(),(_vm.results.careers.totalCount)?_c('div',{staticClass:"mainSearch-results"},_vm._l((_vm.results.careers.edges),function(ref){
var node = ref.node;
return _c('SearchResultItemCard',{key:node.id,staticClass:"u-mb-sm",attrs:{"locale":_vm.results.locale,"img":node.thumb,"title":node.title,"slug":node.slug,"url":node.url,"type":"Career"}})}),1):_vm._e(),(_vm.servicesQuery && _vm.filteredServices.length)?_c('div',{staticClass:"mainSearch-results"},_vm._l((_vm.filteredServices),function(service){return _c('SearchResultItemCard',{key:service.title,staticClass:"u-mb-sm",attrs:{"locale":_vm.results.locale,"img":service.thumb,"title":_vm.$t(("serviceList." + (service.title))),"type":"Service","url":("/services/" + (service.slug))}})}),1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }