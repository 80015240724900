import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate/dist/vee-validate.minimal';
import { IsEmailTaken } from '~/graphql/Auth.graphql';
// only import the rules we use
import { required, email, min, length, confirmed, numeric } from 'babel-loader!vee-validate/dist/rules.esm';

function installRules() {
  Validator.extend('required', required);
  Validator.extend('email', email);
  Validator.extend('min', min);
  Validator.extend('length', length);
  Validator.extend('numeric', numeric);
  Validator.extend('confirmed', confirmed);

  Validator.extend('unique_email', {
    getMessage() {
      return 'email is already taken.';
    },
    async validate(value, [exception] = []) {
      const { data } = await Vue.$api.query({
        query: IsEmailTaken,
        variables: { email: value, exception }
      });

      return {
        valid: !data.isEmailTaken,
        data: { email: value }
      };
    }
  });
}

let installed = false;
let localized = { en: false, ar: false };

export default ({ app }) => {
  if (!installed) {
    Vue.use(VeeValidate, {
      inject: false,
      i18n: app.i18n
    });
    installRules();
    installed = true;
  }

  const locale = app.i18n.locale;

  // skip if already localized.
  if (localized[locale]) return;

  // dynamically load a vee-validate locale
  return import(`vee-validate/dist/locale/${locale}`).then(localeObj => {
    VeeValidate.Validator.localize({ [locale]: localeObj });
    localized[locale] = true;
  });
};
