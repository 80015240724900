import { IsInMaintenanceMode } from '~/graphql/Shame.gql';

export default async ({ app, redirect, store, route }) => {
  const { data } = await app.$api.query(
    {
      query: IsInMaintenanceMode
    },
    { cacheFirst: false }
  );

  const isLocked = data.isInMaintenanceMode;
  if (!isLocked && route.path.indexOf('/lock') !== -1) {
    return redirect('/');
  }

  if (isLocked && !store.getters['auth/unlocked']) {
    return redirect('/lock');
  }
};
