const Cookie = process.client ? require('js-cookie') : undefined;

export default {
  name: 'CookiesConsent',
  functional: true,
  render: (h, { props, listeners }) => {
    if (process.server) {
      return h();
    }

    const accepted = Cookie.get('cookieConsent');
    if (accepted || props.accepted) {
      return h();
    }

    const link = h(
      'a',
      {
        class: 'button-link is-tiny',
        attrs: {
          href: '#'
        },
        on: {
          click: e => {
            e.preventDefault();
            Cookie.set('cookieConsent', 'true', { expires: 365 });
            listeners.onAccept();
          }
        }
      },
      'Accept'
    );

    return h(
      'div',
      {
        class: 'CookiesMessage'
      },
      [h('p', ['We use cookies to provide you with a simpler experience on our website.  ', link])]
    );
  }
};
