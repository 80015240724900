import * as AuthGraph from '~/graphql/Auth.graphql';

const Cookies = process.client ? require('js-cookie') : undefined;

let userPromise = null;

const defaultUser = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  alternateEmail: null,
  phoneNumber: null,
  isTwoFactorEnabled: false,
  profile: {
    avatar: null,
    address: null,
    country: null,
    city: null,
    state: null,
    website: null
  },
  preferences: {
    language: null,
    allowCommunication: false
  }
};

export const state = () => ({
  user: Object.assign({}, defaultUser),
  permissions: [],
  isUpdated: false,
  isLoggedIn: false,
  token: null,
  unlocked: false
});

export const getters = {
  isLoggedIn: state => state.isLoggedIn,
  isLocked: state => state.isLocked,
  unlocked: state => state.unlocked,
  permissions: state => state.permissions,
  user: state => state.user,
  token: state => state.token,
  canAccessDashboard: state => state.isloggedIn && state.permissions.includes('ACCESS_ADMIN')
};

export const mutations = {
  SET_USER(state, payload) {
    state.user = Object.assign({}, defaultUser, payload || {});
    state.isLoggedIn = !!payload;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_UPDATED(state, status) {
    state.isUpdated = status;
  },
  UPDATE_USER(state, payload) {
    state.user = Object.assign({}, state.user, payload || {});
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_LOCK_ACCESS(state, value) {
    state.unlocked = value;
  }
};

export const actions = {
  async fetchOnce({ dispatch }, authToken = null) {
    if (!userPromise) {
      userPromise = dispatch('fetch', authToken);
    }

    return userPromise;
  },
  async fetch({ commit, dispatch, state }, authToken = null) {
    if (state.user.id && state.isUpdated) {
      return state.user;
    }

    if (authToken) {
      commit('SET_TOKEN', authToken);
    }

    if (!state.token) {
      return null;
    }

    try {
      const { data } = await this.app.$api.query({
        query: AuthGraph.Identity,
        variables: {
          token: state.token
        }
      });

      if (!data.user) {
        throw new Error('User not found!');
      }

      commit('SET_USER', data.user);
      commit('SET_PERMISSIONS', data.user.permissions || []);
      commit('SET_TOKEN', state.token);
      commit('SET_UPDATED', true);

      return state.user;
    } catch (err) {
      userPromise = null;
      if (!process.server) {
        dispatch('logout');
      }
      return null;
    }
  },
  async fetchOverview({ commit }) {
    const { data } = await this.app.$api.query({
      query: AuthGraph.GetUserAccountOverview
    });

    commit('UPDATE_USER', data.user);
  },
  async fetchAccounts({ commit }) {
    const { data } = await this.app.$api.query({
      query: AuthGraph.GetAccounts
    });

    commit('UPDATE_USER', data.user);
  },
  async register(_, input) {
    try {
      await this.app.$api.mutate({
        mutation: AuthGraph.Register,
        variables: { input }
      });
    } catch (err) {
      userPromise = null;
      console.log(err);
    }
  },
  async logout({ commit, dispatch }) {
    userPromise = null;
    commit('SET_USER', null);
    commit('SET_PERMISSIONS', []);
    commit('SET_TOKEN', null);
    Cookies.remove('auth', { expires: 7, path: '/' });
    dispatch('notifications/clear', null, { root: true });
  },
  async updatePermissions({ commit }) {
    const { data } = await this.app.$api.query({
      query: AuthGraph.GetPermissions
    });

    if (!data.user || !data.user.permissions) {
      commit('SET_PERMISSIONS', []);
      return;
    }

    commit('SET_PERMISSIONS', data.user.permissions);
  }
};
