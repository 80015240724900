<template lang="pug">
  .container
    .grid.is-widescreen-8.is-desktop-10.is-center
      .column
        .mainSearch.u-mh-md
          input(type="search" v-model="query" :placeholder="$t('placeholder')" class="mainSearch-input" autofocus)
    .grid.is-widescreen-6.is-desktop-8.is-center
      .column
        .mainSearch-results(v-if="results.articles.totalCount")
          SearchResultItemCard(
            v-for="{ node } in results.articles.edges"
            :key="node.id"
            :locale="results.locale"
            :img="node.thumb"
            :title="node.title"
            :slug="node.slug"
            :url="node.url"
            type="Article"
            :publishedIn="node.publishedIn"
            class="u-mb-sm"
          )
        .mainSearch-results(v-if="results.books.totalCount")
          SearchResultItemCard(
            v-for="{ node } in results.books.edges"
            :key="node.id"
            :locale="results.locale"
            :img="node.thumb"
            :title="node.title"
            :slug="node.slug"
            :url="node.url"
            type="Book"
            :publishedIn="node.publishedIn"
            class="u-mb-sm"
          )
        .mainSearch-results(v-if="results.works.totalCount")
          SearchResultItemCard(
            v-for="{ node } in results.works.edges"
            :key="node.id"
            :locale="results.locale"
            :img="node.thumb"
            :title="node.title"
            :slug="node.slug"
            :url="node.url"
            type="Work"
            class="u-mb-sm"
          )
        .mainSearch-results(v-if="results.resources.totalCount")
          SearchResultItemCard(
            v-for="{ node } in results.resources.edges"
            :key="node.id"
            :locale="results.locale"
            :img="node.thumb"
            :title="node.title"
            :slug="node.slug"
            :url="node.url"
            :type="node.type === 'OPEN_SOURCE' ? 'Open-source' : 'Resource'"
            class="u-mb-sm"
          )
        .mainSearch-results(v-if="results.careers.totalCount")
          SearchResultItemCard(
            v-for="{ node } in results.careers.edges"
            :key="node.id"
            :locale="results.locale"
            :img="node.thumb"
            :title="node.title"
            :slug="node.slug"
            :url="node.url"
            type="Career"
            class="u-mb-sm"
          )
        .mainSearch-results(v-if="servicesQuery && filteredServices.length")
          SearchResultItemCard(
            v-for="service in filteredServices"
            :key="service.title"
            :locale="results.locale"
            :img="service.thumb"
            :title="$t(`serviceList.${service.title}`)"
            type="Service"
            :url="`/services/${service.slug}`"
            class="u-mb-sm"
          )
</template>

<script>
import { debounce } from 'throttle-debounce';
import Fuse from 'fuse.js';
import { load } from '~/directives';
import services from '~/assets/data/services.json';
import SearchResultItemCard from '~/components/SearchResultItemCard';
import { Search } from '~/graphql/Search.gql';

export default {
  name: 'Search',
  components: {
    SearchResultItemCard
  },
  directives: {
    load
  },
  data: () => ({
    query: '',
    useRouter: false,
    fuse: null,
    pendingQuery: '',
    servicesQuery: '',
    isPending: false,
    services,
    results: {
      locale: 'en',
      articles: {
        pageInfo: { hasNext: true, totalCount: 0 },
        items: []
      },
      careers: {
        pageInfo: { hasNext: true, totalCount: 0 },
        items: []
      },
      books: {
        pageInfo: { hasNext: true, totalCount: 0 },
        items: []
      },
      resources: {
        pageInfo: { hasNext: true, totalCount: 0 },
        items: []
      },
      works: {
        pageInfo: { hasNext: true, totalCount: 0 },
        items: []
      }
    }
  }),
  computed: {
    filteredServices() {
      if (!this.servicesQuery || this.fuse) return this.services;

      return this.fuse.search(this.servicesQuery);
    }
  },
  watch: {
    query(value) {
      if (value && value.length >= 3) {
        this.pendingQuery = value;
        const locale = /[A-Z]+/i.test(value) ? 'en' : 'ar';
        this.$fetch(value, locale);
      }
    }
  },
  created() {
    this.$fetch = debounce(500, this.fetchResults);
    this.query = this.$route.query.q || '';
  },
  mounted() {
    this.loadFuse();
    if (this.$route.path.includes('/search')) {
      this.useRouter = false;
    } else {
      const prefix = this.$i18n.locale === 'en' ? '' : '/ar';
      window.history.replaceState(null, 'Menu', `${prefix}/search`);
    }

    if (this.query) {
      const locale = /[A-Z]+/i.test(this.query) ? 'en' : 'ar';
      this.$fetch(this.query, locale);
    }
  },
  beforeDestroy() {
    if (!this.useRouter) {
      window.history.replaceState(null, '', this.$route.path);
    }
  },
  methods: {
    async loadFuse() {
      if (this.fuse) return this.fuse;

      this.fuse = new Fuse(this.services, {
        keys: ['title', 'tags']
      });

      return this.fuse;
    },
    async fetchResults(query, locale) {
      try {
        this.isPending = true;
        if (this.useRouter) {
          this.$router.replace({ query: { q: query } });
        } else {
          const prefix = this.$i18n.locale === 'en' ? '' : '/ar';
          window.history.replaceState(null, '', `${prefix}/search?q=${query}`);
        }

        const { data } = await this.$api.query({
          query: Search,
          variables: {
            query,
            locale,
            first: 10
          }
        });

        // Preserves the freshness of the results.
        // since the order of fetching results isn't guarnteed
        // and speed of the input interval is varying.
        if (this.pendingQuery === query) {
          this.results = Object.freeze(data.search);
          this.servicesQuery = query;
          this.isPending = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchMore() {}
  }
};
</script>

<style lang="stylus">
.mainSearch
  display: flex
  justify-content: space-between
  align-items: center

  &-results
    &-title
      position: relative

      &:not(:first-of-type)
        &:before
          content: ''
          height: unitRes(4, 8)
          background: $clBlack
          width: calc(140%)
          display: block
          margin: unitRes(40, 80) 0 unitRes(40, 80) -20%

.mainSearch-input
  border: none
  box-shadow: none
  width: calc(100% - 130px)
  display: block
  padding: 0
  margin: 0
  font-size: unitRes(30, 100)
  text-transform: capitalize

  +placeholder()
    color: $clBlack
    opacity: 1

  +mq($until: 'tablet')
    width: calc(100% - 80px)

.overlay-close2
  font-size: $size-large
  font-size: unitRes(20, 36)
</style>

<i18n>
{
  "en": {
    "pageTitle": "Search",
    "pageDesc": "Search everything on Baianat website. Type the keywords and find what you are looking for easily with a click.",
    "placeholder":"Search here",
    "articles": "Articles",
    "books": "Books",
    "work": "Our Work",
    "resources": "Resources and Open-Source",
    "careers": "Careers",
    "noResults":"No results found for",
    "tryAnother":"please try with another word.",
    "services":"Services",
    "serviceList": {
      "ads": "Advertising",
      "devops": "DevOps",
      "dev": "Development",
      "tech": "Technology",
      "productDesign": "Product Design",
      "ux": "Interaction",
      "typo": "Type Design",
      "brand": "Branding",
      "business": "Business"
    }
  },
  "ar": {
    "pageTitle": "البحث",
    "pageDesc": "يوفر لك موقع بيانات إمكانية البحث من خلال كتابتك لكلمات البحث سوف تستطيع أن تجد كل ما تبحث عنه فوراً وبكل سهولة بضغطة زر",
    "placeholder":"ابحث هنا",
    "noResults":"لا توجد نتائج عن ",
    "tryAnother":"حاول بكلمة اخري.",
    "services":"الخدمات",
    "articles": "مقالات",
    "books": "كتب",
    "work": "أعمالنا",
    "resources": "تصميمات مجانية وبرمجيات مفتوحة المصدر",
    "careers": "وظائف",
    "serviceList": {
      "ads": "الإعلان",
      "devops": "ديف اوبس",
      "dev": "برمجة",
      "tech": "تكنولوجيا",
      "productDesign": "تصميم المنتجات",
      "ux": "التصميمات التفاعلية",
      "typo": "تصميم الخطوط",
      "brand": "العلامة التجارية",
      "business": "الأعمال التجارية"
    }
  }
}
</i18n>
