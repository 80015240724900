<template lang="pug">
.errorPage
  h1.u-mb-sm {{$t('errorTitle')}}
  .errorPage-content
    h4.u-mb-sm.errorPage-title
      | {{ $t('mantra') }}
      AppLink(to="/explore" :title="$t('explore')" class="button-link ") {{ $t('explore') }}

    h4.h2.u-mb-sm {{ $t('or') }}
    .inputGroup.has-itemAfter.is-black.u-mb-sm
      input.input(type="search" :placeholder="$t('searchPlaceholder')" v-model="query" @keydown.enter="beginSearch")
      button.button(@click="beginSearch") {{ $t('search') }}

</template>

<script>
export default {
  head() {
    const $t = this.$t.bind(this);

    return {
      ...this.$nuxt.$nuxtI18nSeo(),
      title: $t('pageTitle'),
      bodyAttrs: {
        error: 'true'
      }
    };
  },
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    query: ''
  }),
  computed: {
    title() {
      if (this.error.statusCode == 404) {
        return this.$t('oopsTitle');
      }

      return this.$t('oopsFull');
    },
    description() {
      // return translated error if it exists.
      if (this.$te(this.error.message)) {
        return this.$t(this.error.message);
      }

      if (this.error.statusCode == 404) {
        return this.$t('errorDesc');
      }

      return this.error.message;
    }
  },
  beforeRouteLeave(_, __, next) {
    this.$store.commit('SET_ALTERNATE_LOCALE', { en: '', ar: '' });
    next();
  },
  methods: {
    beginSearch() {
      if (!this.query || this.query.length < 3) {
        return;
      }

      this.$router.push({ path: '/search', query: { q: this.query } });
    }
  }
};
</script>

<style lang="stylus">
@import '~assets/css/app.styl'

body[error^='true']
  .footer
    display: none

  .header-after
    display: none

  .app-body
    margin-left: -12vw
    margin-right: -12vw

div.errorPage
  padding: unitRes(150, 300) 8vw 0

.errorPage
  &-content
    max-width: 800px

  .button-link
    font-size: unitRes(24, 56)
    white-space: nowrap
</style>

<i18n>
{
  "en": {
    "errorTitle":"404",
    "mantra":"You seem lost or maybe the page no longer exists but you can ",
    "or":"Or",
    "explore":"Explore Baianat",
    "searchPlaceholder":"Search here...",
    "backHome":"Back to Home",
    "oopsTitle":"Oops! You <br> hit a 404",
    "oopsFull":"Oops! Something Broke",
    "errorDesc":"Maybe the link was deleted or there was a typo in the link.",
    "pageTitle":"OOps!",
    "ARTICLE_NOT_FOUND": "Article not found",
    "BOOK_NOT_FOUND": "Book not found",
    "CHAPTER_NOT_FOUND": "Chapter not found",
    "WORK_NOT_FOUND": "This Work was not found",
    "CAREER_NOT_FOUND": "Career listing was not found",
    "search":"Search"
  },
  "ar": {
    "errorTitle":"404",
    "mantra":"يبدو أنك تائه أو ربما الصفحة لم تعد موجودة، لكن يمكنك  ",
    "or":"أو ",
    "explore":"اكتشاف بيانات",
    "searchPlaceholder":"ابحث هنا...",
    "backHome":"العودة للرئيسية",
    "oopsTitle":"اوه! لقد وصلت <br> إلى 404",
    "oopsFull":"أوبس! لقد حدث خطأ ما ",
    "errorDesc":"ربما تم حذف الرابط أو كان هناك خطأ كتابي به. ",
    "pageTitle":"خطأ",
    "ARTICLE_NOT_FOUND": "لم يتم العثور على هذه المقالة",
    "BOOK_NOT_FOUND": "لم يتم العثور على هذا الكتاب",
    "CHAPTER_NOT_FOUND": "لم يتم العثور على هذا الفصل",
    "WORK_NOT_FOUND": "هذا العمل غير موجود",
    "CAREER_NOT_FOUND": "لم يتم العثور على وظيفة مطابقة",
    "search":"بحث"
  }
}
</i18n>
