import GetNotifications from '~/graphql/Notification.gql';

const createEmptyNotifications = () => ({
  pageInfo: { hasNext: false, currentPage: 1 },
  items: []
});

export const state = () => ({
  notifications: createEmptyNotifications()
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.notifications = payload;
  }
};

export const getters = {
  notifications: state => state.notifications
  // unreadCount: state => state.notifications.edges.filter(item => item.node.isRead).length
};

export const actions = {
  async fetch({ commit, rootState }) {
    if (!rootState.auth.isLoggedIn) {
      return;
    }

    const { data } = await this.app.$api.query({
      query: GetNotifications,
      variables: { first: 10 }
    });

    commit('SET_ITEMS', data.user.notifications);
  },
  clear({ commit }) {
    commit('SET_ITEMS', createEmptyNotifications());
  }
};
