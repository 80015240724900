import Vue from 'vue';

export default function AlertsPlugin({ app, store }) {
  const alert = (type, msg) => {
    store.dispatch(`alert/${type}`, msg);
  };

  app.$success = msg => alert('success', msg);
  Vue.prototype.$success = msg => alert('success', msg);
  app.$error = msg => alert('error', msg);
  Vue.prototype.$error = msg => alert('error', msg);
}
