export async function handleAppUpdates(store) {
  if (process.env.NODE_ENV !== 'production') return;

  const wb = await window.$workbox;
  if (!wb) return;

  wb.addEventListener('activated', event => {
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (event.isUpdate) {
      store.commit('SET_IS_UPDATE', true);
      store.commit('SW_STATUS', 'activated');
    }
  });

  wb.addEventListener('waiting', () => {
    store.commit('SET_IS_UPDATE', true);
    store.commit('SW_STATUS', 'waiting');
  });
}
