<template lang="pug">
.app(:class="themeClasses")
  TheHeader

  .app-body
    nuxt(keep-alive :keep-alive-props="{ include: ['MainMenu', 'SearchPage'] }")
  TheFooter
  TransitionGroup.alerts-group.is-bottom.is-right(name="alerts" tag="div")
    Alert(v-for="alert in alerts" :key="alert.id" :id="alert.id" :message="alert.message" :type="alert.type")

  //- Chat(v-if="$store.getters['auth/isLoggedIn'] && defer(9)")
  Alert(v-if="globalAlert" :id="globalAlert.id" :global="true" :message="globalAlert.message" :type="globalAlert.type")
  no-ssr
    CookiesConsent(@onAccept="hideCookie = true" :accepted="hideCookie")
    UpdateMessage(v-if="$store.state.swUpdate" @accept="onUpdateAccepted")
  RtlWrapper(v-if="$i18n.locale === 'ar'")

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TheHeader from '~/components/TheHeader';
import TheFooter from '~/components/TheFooter';
import RtlWrapper from '~/components/RtlWrapper';
import Alert from '~/components/Alert';
import CookiesConsent from '~/components/CookiesConsent';
import UpdateMessage from '~/components/UpdateMessage';
import { handleAppUpdates } from '~/utils/workbox';
import Defer from '~/mixins/defer';
// import Chat from '~/components/Chat';

export default {
  head() {
    const seo = this.$nuxt.$nuxtI18nSeo();
    const altLinks = this.$store.state.alternateLinks;
    const siteName = { hid: 'og:site_name', property: 'og:site_name', content: this.$t('siteName') };
    if (seo.meta) {
      seo.meta.push(siteName);
    } else {
      seo.meta = [siteName];
    }
    seo.link = seo.link.map(link => {
      const locale = link.hreflang.slice(0, 2);

      if (altLinks[locale]) {
        return {
          ...link,
          href: altLinks[locale]
        };
      }

      return link;
    });
    (seo.__dangerouslyDisableSanitizers = ['script']),
      (seo.script = [{ innerHTML: JSON.stringify(this.searchStructuredData), type: 'application/ld+json' }]);
    return seo;
  },
  mixins: [Defer(10)],
  components: {
    TheHeader,
    TheFooter,
    RtlWrapper,
    Alert,
    CookiesConsent,
    // Chat,
    UpdateMessage
  },
  data: () => ({
    hideCookie: false,
    searchStructuredData: {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: 'Baianat',
      url: 'https://www.baianat.com/',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.baianat.com/search?q={search_term_string}',
        'query-input': 'required name=search_term_string'
      },
      logo: 'https://www.baianat.com/icon.png',
      telephone: '002 01000111888',
      sameAs: ['https://www.facebook.com/baianat', 'https://twitter.com/baianat', 'https://github.com/baianat']
    }
  }),
  computed: {
    ...mapGetters({
      alerts: 'alert/messages',
      theme: 'theme',
      globalAlert: 'alert/globalAlert'
    }),
    themeClasses() {
      return this.theme
        .toLowerCase()
        .split(' ')
        .map(theme => `is-${theme.replace('is-', '')}`);
    }
  },
  mounted() {
    handleAppUpdates(this.$store);
  },
  methods: {
    ...mapActions({
      alert: 'alert/create'
    }),
    async onUpdateAccepted() {
      const workbox = window.$workbox && (await window.$workbox);
      if (workbox && this.$store.state.swStatus === 'waiting') {
        // Assuming the user accepted the update, set up a listener
        // that will reload the page as soon as the previously waiting
        // service worker has taken control.

        workbox.addEventListener('controlling', () => {
          window.location.reload();
        });

        // Send a message telling the service worker to skip waiting.
        // This will trigger the `controlling` event handler above.
        // Note: for this to work, you have to add a message
        // listener in your service worker. See below.
        workbox.messageSW({ type: 'SKIP_WAITING' });
        return;
      }

      window.location.reload();
    }
  },
  beforeRouteLeave(_, __, next) {
    this.$store.commit('SET_ALTERNATE_LOCALE', { en: '', ar: '' });
    next();
  }
};
</script>

<style lang="stylus" src="~/assets/css/app.styl"></style>

<style lang="stylus">
.alerts-group
  display: flex
  flex-direction: column
  position: fixed
  max-width: 30vw
  margin: 10px 20px
  z-index: 99999

  +mq($until: 'desktop')
    max-width: 60vw

  +mq($until: 'mobile')
    max-width: 100%

  &.is-top
    top: 0

  &.is-right
    right: 0

  &.is-bottom
    bottom: 0

  &.is-left
    left: 0

  .alert
    margin-bottom: 10px

// .alerts-enter-active, .list-leave-active
// transition: all 1s

// .alerts-enter, .alerts-leave-to
// opacity: 0
// transform: translateX(-200vw)

// .alerts-move
// transition: transform 1s
.alerts-enter-active, .alerts-leave-active
  transition: opacity 0.4s, transform 0.4s

.alerts-enter, .alerts-leave-active
  opacity: 0
  transform: translateX(300px)

.CookiesMessage
  background: $clBlack
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 89
  display: flex
  justify-content: center
  padding: unitRes(10, 20) 10px
  font-size: unitRes(12, 18)

  >p
    color: $clWhite

  a.button-link
    color: $clWhite

.UpdateMessage
  background: $clBlack
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 89
  display: flex
  justify-content: center
  padding: unitRes(10, 20) 10px
  font-size: unitRes(12, 18)

  >p
    color: $clWhite

  a.button-link
    color: $clWhite
</style>

<i18n>
{
  "en": {
    "reload": "Reload",
    "updateAvailable": "A new version of Baianat is available click on the link to update  "
  },
  "ar": {
    "reload": "تحديث",
    "updateAvailable": "نسخة جديدة من بيانات اصبحت متوفرة, اضغط على الرابط لتفعيل احدث نسخة"
  }
}
</i18n>
